import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useState} from "react";
import RentSteps from "./RentSteps";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "98%",
    sm: "400px",
    md: "500px",
    lg: "500px",
    xl: "500px",
  },
  bgcolor: "#2e2e2e",
  border: "5px solid #faa61a",
  borderRadius: "10px",
  outline: "none !important",
  p: 3,
  boxSizing:'border-box'
};

const CompleteProfilePopup = ({ open, handleClose }) => {

  const [completeProfileModal,setCompleteProfileModal] = useState<boolean>(false);
  const handleCloseCompleteProfileModal = () => setCompleteProfileModal(false);
  const handleOpenCompleteProfileModal = () => {
    handleClose();
    setCompleteProfileModal(true);
  };

  return (
    <>
    <Modal
      open={open}
      onClose={()=>{}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: {
              xs: "5px",
              sm: "-20px",
              md: "-20px",
              lg: "-20px",
              xl: "-20px",
            },
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <Typography
          sx={{
            color: "#faa61a",
            fontSize: {
              xs: "18px",
              sm: "20px",
              md: "22px",
              lg: "24px",
              xl: "26px",
            },
            fontWeight: "700",
            fontFamily: "Montserrat !important",
            textAlign:'center',
            mb:4
          }}
        >
          Complete Profile
        </Typography>

        <Typography
          sx={{
            color: "#fff",
            fontFamily: "Montserrat !important",
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "20px",
              lg: "22px",
              xl: "24px",
            },
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          For GoEquipMe Full Experience you must complete your profile
        </Typography>

        <Box
          sx={{
            width: "100%",
            mt: 4,
            mb: 1,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              color: "#fff",
              fontFamily: "Montserrat !important",
              fontWeight: "700",
              padding: "8px 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              backgroundColor: "rgb(20, 146, 71)",
              "&:hover": {
                backgroundColor: "rgb(20, 146, 71)",
                color: "#fff",
              },
            }}
            onClick={handleOpenCompleteProfileModal}
          >
            Complete Profile
          </Button>
          <Button
            sx={{
              color: "#faa61a",
              fontFamily: "Montserrat !important",
              fontWeight: "700",
              padding: "8px 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "2px solid #faa61a",
              borderRadius: "5px",
            }}
            onClick={handleClose}
          >
            Do It Later
          </Button>
        </Box>
      </Box>
    </Modal>
    <RentSteps open={completeProfileModal} handleClose={handleCloseCompleteProfileModal}/>
    </>
  );
};

export default CompleteProfilePopup;
