import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TableFooter,
  TextField,
  Backdrop,
  CircularProgress,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import moment from "moment";
import { useState, useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

import shareIcon from "../../../assets/images/buildacrew/Icon_Share_Active.png";
import arrowIcon from "../../../assets/images/buildacrew/Icon_Subs_Indent-Arrow.png";
import grayArrowIcon from "../../../assets/images/buildacrew/grayarrow.png";
import "./styles.scss";

import { IStoreValue } from "utils/types";
import { useDispatch, useSelector } from "react-redux";
import { EquipmentAPIs } from "service/api-service";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";

import AddReferralModal from "./AddReferralModal";
import CrewPaymentModal from "./CrewPaymentModal";
import ShareReferralModal from "./ShareReferralModal";
import PdfViewerModal from "./PdfViewerModal";
import CrewPaymentDetailsModal from "./CrewPaymentDetailsModal";
import { useParams } from "react-router-dom";
import CurrencyFormat from "components/MyofficeTrailer/EquipmentOrders/UsCurrencyFormat";
import RentSteps from "components/user-signup/signupPopup/RentSteps";
const type = process.env.REACT_APP_PLATFORM;

let url = process.env.REACT_APP_STAGING_URL; //Staging
let backend_url = "https://www.goequipmeapis.com:8080"; // Staging

if (type == "PreLive") {
  backend_url = "https://goequipme-prelive.com:8080"; // PreLive
}

if (type == "Live") {
  backend_url = "https://goequipmebackendlive.com:8080"; // Live
}

const BuildACrew = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { userId: paramsUserId } = params;
  console.log("params user id", paramsUserId);
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isGeneralSelected, setIsGeneralSelected] = useState(true);
  const [isSubSelected, setIsSubSelected] = useState(true);
  const [isCrewBoss, setIsCrewBoss] = useState(false);
  const [data, setData] = useState([]);
  const [storedArray, setStoredArray] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [allObject, setAllObject] = useState<any>({});
  const [totalMyEarning, setTotalMyEarning] = useState(0);
  const [totalYDT, setTotalYDT] = useState(0);
  const [searchVal, setSearchVal] = useState("");

  const [crewPaymentModelOpen, setCrewPaymentModelOpen] = useState(false);
  const [shareReferralModalOpen, setShareReferralModalOpen] = useState(false);
  const [pdfViewerModalOpen, setPdfViewerModalOpen] = useState(false);
  const [crewPayDetailsOpen, setCrewPayDetailsOpen] = useState(false);
  const [crewUserId, setCrewUserId] = useState("");
  const [crewUserType, setCrewUserType] = useState("");
  const [crewTotalPayout, setCrewTotalPayout] = useState("");
  const [userUnauthorized, setUserUnauthorized] = useState(false);
  const [profileCompleteModal, setProfileCompeleteModal] =
    useState<boolean>(false);
  const handleProfileCompeleteModalClose = () =>
    setProfileCompeleteModal(false);
  // state = {
  //     isGeneralSelect: true,
  //     isSubSelect: true,
  //     isLoading: false,
  //     data: [],
  //     storedArray: [],
  //     totalMyEarning: 0,
  //     totalYDT: 0,
  //     searchitem: "",
  //     allObject: [],
  //     user_id: "",
  //     ModalReport: false,
  //     reffCode: "",
  //   };

  const [expandedG, setExpandedG] = useState(false);

  const handleExpandG = () => {
    setExpandedG(!expandedG);
  };

  const [referralModalOpen, setReferralModalOpen] = useState(false);

  const openModal = () => {
    setReferralModalOpen(true);
  };

  const closeModal = () => {
    setReferralModalOpen(false);
  };

  const shareData = {
    title: "Share referral code",
    // text: `I'm thrilled to share an incredible opportunity with you! I've recently joined an amazing GoEquipMe App. Here's how you can get started: Use my referral code: ${AuthUserID} Feel free to reach out if you have any questions or need assistance with the signup process`,
    text: `GoEquipMe is a community where you can FIND equipment, LIST equipment, and BUILD your crew! Sign up for free on the app or website using my referral code ${AuthUserID} and join My Crew today!`,
    // url: 'https://example.com',
  };

  const handleCrewPayamentModalOpen = () => {
    if (parseFloat(AuthProfileData?.["is_profile_completed"]) === 0) {
      setProfileCompeleteModal(true);
    } else {
      setCrewPaymentModelOpen(true);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        // throw new Error('Web Share API is not supported in this browser.');
        console.log("Web Share API is not supported in this browser");
        // setShareReferralModalOpen(true);
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("Please enable web share in your browser."));
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
      // setShareReferralModalOpen(true);
      // dispatch(setalertMessagetype("error"));
      // dispatch(setalertMessage("Please enable web share in your browser."));
      // Fallback to another share method or show an error message to the user
    }
  };

  const calculateTotalPendingEarning = (dataArray, isSubSelect) => {
    let totalPendingEarning = 0;
    dataArray.forEach((item) => {
      // Add pending earning from the main object
      totalPendingEarning += Number(item.earning);
      if (isSubSelect && item.subs && item.subs.length > 0) {
        item.subs.forEach((sub) => {
          totalPendingEarning += Number(sub.earning);
        });
      }
    });
    return totalPendingEarning;
  };

  const calculateTotalPendingEarningMy = (dataArray, isSubSelect) => {
    let totalPendingEarning = 0;
    dataArray.forEach((item) => {
      // Add pending earning from the main object
      totalPendingEarning += Number(item.NextPayout);
      if (isSubSelect && item.subs && item.subs.length > 0) {
        item.subs.forEach((sub) => {
          totalPendingEarning += Number(sub.NextPayout);
        });
      }
    });
    return totalPendingEarning;
  };
  // Function to collect all subs arrays into one array
  const collectAllSubs = (dataArray) => {
    let allSubs = [];

    // Iterate through the main data array
    dataArray.forEach((item) => {
      // Check if there are subs, then push them into allSubs array
      if (item.subs && item.subs.length > 0) {
        allSubs.push(...item.subs);
      }
    });

    return allSubs;
  };

  const removeSubs = (data) => {
    return data.map((item) => {
      const { subs, ...newItem } = item;
      return newItem;
    });
  };

  // const search = (searchTerm) => {
  //     // Perform filtering on the main array
  //     let filteredData = storedArray.filter((item) => {
  //         // Check if any property matches the searchTerm
  //         for (let key in item) {
  //             // console.log("item[${key}]: ", key);
  //             if (
  //                 item[key] &&
  //                 typeof item[key] === "string" &&
  //                 item[key].toLowerCase().includes(searchTerm.toLowerCase())
  //             ) {
  //                 return true;
  //             }
  //         }
  //         // Perform filtering on the subs array
  //         if (item?.subs) {
  //             console.log("in subs if");

  //             // item.subs = item?.subs?.filter((subItem) => {
  //             item?.subs?.filter((subItem) => {
  //                 for (let key in subItem) {
  //                     if (
  //                         subItem[key] &&
  //                         typeof subItem[key] === "string" &&
  //                         subItem[key].toLowerCase().includes(searchTerm.toLowerCase())
  //                     ) {
  //                         // return true;
  //                         return item;
  //                     }
  //                 }
  //                 return false;
  //             });
  //         }
  //         return false;
  //     });
  //     // console.log("filteredData in search: ", filteredData);

  //     setFilterData(filteredData);
  // };

  // const search = (searchTerm) => {
  //     // Perform filtering on the main array
  //     let filteredData = storedArray.filter((item) => {
  //         let hasMatch = false;
  //         // Check if any property of the main item matches the searchTerm
  //         for (let key in item) {
  //             if (
  //                 item[key] &&
  //                 typeof item[key] === "string" &&
  //                 item[key].toLowerCase().includes(searchTerm.toLowerCase())
  //             ) {
  //                 hasMatch = true;
  //                 break;
  //             }
  //         }
  //         // If the main item matches, or if any sub-item matches, include it in the filtered data
  //         if (hasMatch || (item?.subs && item.subs.some(subItem =>
  //             Object.values(subItem).some(value =>
  //                 typeof value === "string" && value.toLowerCase().includes(searchTerm.toLowerCase())
  //             )
  //         ))) {
  //             return true;
  //         }
  //         return false;
  //     });
  //     setFilterData(filteredData);
  // };

  const search = (searchTerm) => {
    // Perform filtering on the main array
    let filteredData = storedArray.filter((item) => {
      let hasMatch = false;
      // Check if any property of the main item matches the searchTerm
      for (let key in item) {
        if (
          item[key] &&
          typeof item[key] === "string" &&
          item[key].toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          hasMatch = true;
          break;
        }
      }
      // If the main item matches, or if any sub-item matches, include it in the filtered data
      if (
        hasMatch ||
        (item?.subs &&
          item.subs.some((subItem) =>
            Object.values(subItem).some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchTerm.toLowerCase())
            )
          ))
      ) {
        return true;
      }
      return false;
    });
    setFilterData(filteredData);
  };

  // to fetch service list from api
  const fetchCrewListingDemo = async () => {
    try {
      setLoading(true);
      let data = {
        user_id: AuthUserID.toString(),
        // user_id: "41",
        // page: page,
        // limit: limit
      };
      // Call the API to delete the equipment
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const crewList = await EquipmentAPIs.crewListing(data, headers);
      console.log("crewList Demo: ", crewList);

      // Check if the deletion was successful
      if (crewList["type"] == "RXSUCCESS") {
        // console.log("data: ", crewList.data);
        if (crewList.data) {
          setAllObject(crewList.data);
          setIsCrewBoss(crewList.data?.userInfo?.is_crewboss ? true : false);
          let _data = crewList.data?.data;
          setData(_data);
          console.log("_data", _data);
          console.log(
            "isCrewBoss",
            crewList.data?.userInfo?.is_crewboss ? true : false
          );

          let filteredData;
          if (isGeneralSelected) {
            if (isGeneralSelected) {
              filteredData = _data;
            } else {
              filteredData = _data;
              // filteredData = removeSubs(_data);
            }
          } else {
            filteredData = collectAllSubs(_data);
          }

          setFilterData(filteredData);
          setStoredArray(filteredData);

          let YDTTotal = calculateTotalPendingEarning(
            filteredData,
            isSubSelected
          );
          // console.log("YDTTotal: ", YDTTotal);
          setTotalYDT(YDTTotal);

          // old
          // let MyTotal = calculateTotalPendingEarningMy(filteredData);
          let MyTotal = calculateTotalPendingEarningMy(
            filteredData,
            isSubSelected
          );

          // console.log("MyTotal: ", MyTotal);
          setTotalMyEarning(MyTotal);
        }

        setLoading(false);
      } else {
        // Display an error message if deletion fails
        console.log("error while fetching crew listing api else.");
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("error while fetching crew listing."));
        setLoading(false);
      }
    } catch (err) {
      // Handle errors that occur during the deletion process
      console.log("error in crew listing api", err);
      dispatch(setalertMessagetype("error"));
      dispatch(setalertMessage("error while fetching crew listing."));
      setLoading(false);
    }
  };

  const fetchCrewListing = async () => {
    try {
      setLoading(true);
      let data = {
        user_id: AuthUserID.toString(),
        // user_id: "41",
        // page: page,
        // limit: limit
      };
      // Call the API to delete the equipment
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const crewList = await EquipmentAPIs.crewListing(data, headers);
      console.log("crewList REAL: ", crewList);

      // Check if the deletion was successful
      if (crewList["type"] == "RXSUCCESS") {
        setLoading(false);
      } else {
        // Display an error message if deletion fails
        console.log("error while fetching crew listing api else.");
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("error while fetching crew listing."));
        setLoading(false);
      }
    } catch (err) {
      // Handle errors that occur during the deletion process
      console.log("error in crew listing api", err);
      dispatch(setalertMessagetype("error"));
      dispatch(setalertMessage("error while fetching crew listing."));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (AuthUserID && paramsUserId && AuthUserID !== Number(paramsUserId)) {
      setUserUnauthorized(true);
    }
  }, [AuthUserID]);

  useEffect(() => {
    if (
      AuthProfileData &&
      AuthProfileData != undefined &&
      AuthProfileData != null &&
      AuthToken
    ) {
      fetchCrewListingDemo();
      // fetchCrewListing();

      // console.log("auth profileData: " , AuthProfileData);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [AuthProfileData]);

  useEffect(() => {
    // fetchCrewListingDemo();
    // console.log("isGeneralSelected changed: ", isGeneralSelected);
    // console.log("isSubSelected changed: ", isSubSelected);
    setLoading(true);
    // console.log("loading true");

    let filteredData;
    if (isGeneralSelected) {
      if (isGeneralSelected) {
        filteredData = data;
      } else {
        filteredData = data;
        // filteredData = removeSubs(_data);
      }
    } else {
      filteredData = collectAllSubs(data);
    }

    setFilterData(filteredData);
    setStoredArray(filteredData);

    let YDTTotal = calculateTotalPendingEarning(filteredData, isSubSelected);
    // console.log("YDTTotal in use effect: ", YDTTotal);
    setTotalYDT(YDTTotal);

    // old
    // let MyTotal = calculateTotalPendingEarningMy(filteredData);
    let MyTotal = calculateTotalPendingEarningMy(filteredData, isSubSelected);
    // console.log("MyTotal in use effect: ", MyTotal);
    setTotalMyEarning(MyTotal);
    setLoading(false);
    // console.log("loading false");
  }, [isGeneralSelected, isSubSelected]);

  useEffect(() => {
    if (searchVal.trim()) {
      // console.log("in search if");

      search(searchVal.trim());
    } else {
      // console.log("in search else");
      setFilterData(storedArray);
      // fetchCrewListingDemo();
    }
  }, [searchVal]);

  // useEffect(() => {
  //     console.log("stord array changed");
  // }, [storedArray]);

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">
          <FaMinus />
        </div>
        <div className="collapsIconWrapper">
          <FaPlus />
        </div>
      </Box>
    );
  };

  const TableRow = ({ item }) => {
    const [expanded, setExpanded] = useState(false);
    const handleExpand = () => {
      setExpanded(!expanded);
    };

    useEffect(() => {
      // console.log("isGeneralSelected changed in table row: ", isGeneralSelected);
      // console.log("isSubSelected changed in table row: ", isSubSelected);

      if (isSubSelected && isGeneralSelected) setExpanded(true);
      if (!isSubSelected && isGeneralSelected) setExpanded(false);
    }, [isGeneralSelected, isSubSelected]);

    return (
      <>
        {/* <Box className='msg-cell' sx={{ textAlign: 'center', fontSize: '24px' }}>
                Share Your Referral Code to Start Building Get Started Building Your Crew and Growing Your Wallet! <Link className='learnmore'>Learn More</Link>
            </Box> */}

        <Box sx={{ borderTop: "none", borderBottom: "none", display: "flex" }}>
          <Box
            className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
            sx={{ width: "40%" }}
          >
            {/* {item.first_name + " " + item.last_name} */}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.3rem",
              }}
            >
              {!isGeneralSelected && isSubSelected && (
                <img
                  src={isCrewBoss ? arrowIcon : arrowIcon}
                  alt=""
                  className="arrow-icon"
                />
              )}
              {/* Hernando Diaz */}
              {item.first_name + " " + item.last_name}
            </span>
            {isGeneralSelected && (
              <>
                <span className="bc-cell-span">({item.subs?.length})</span>
                <IconButton onClick={handleExpand} sx={{ padding: "0" }}>
                  {expanded ? (
                    <FaMinus className="expand-icons" />
                  ) : (
                    <FaPlus className="expand-icons" />
                  )}
                </IconButton>
              </>
            )}

            {/* {isGeneralSelected &&
                            <IconButton onClick={handleExpand} sx={{ padding: '0' }}>
                                {expanded ? (
                                    <FaMinus className="expand-icons" />
                                ) : (
                                    <FaPlus className="expand-icons" />
                                )}
                            </IconButton>
                        } */}
          </Box>
          <Box
            className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
            sx={{
              width: "20%",
              display: {
                xs: "none !important",
                sm: "none !important",
                md: "none !important",
                lg: "block !important",
                xl: "block !important",
              },
            }}
          >
            {/* Crew Boss */}
            {item.is_crewboss == 1 ? "Crew Boss" : "Crew"}
          </Box>
          <Box
            className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
            sx={{
              width: {
                xs: "30%",
                sm: "30%",
                md: "30%",
                lg: "20%",
                xl: "20%",
              },
              // commented by pp on 12 apr 2024 to make next payout color white
              // ...(isCrewBoss && { color: "#faa61a !important", cursor: "pointer", }),
            }}
            // click event commented by pp on 12 apr 2024 to make next payout unclickable
            // onClick={() => {
            //     if (!isCrewBoss) return;
            //     setCrewUserId(item.id.toString());
            //     setCrewTotalPayout(item.NextPayout);
            //     setCrewUserType("NEXT");
            //     setCrewPayDetailsOpen(true);
            // }}
          >
            {/* $51.00 */}
            {/* ${parseFloat(item.NextPayout).toFixed(2)} */}
            {/* {isCrewBoss
                            ? <>${parseFloat(item.NextPayout).toFixed(2)}</>
                            : "-"
                        } */}
            {isCrewBoss ? <CurrencyFormat amount={item.NextPayout} /> : "-"}
          </Box>
          <Box
            className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
            sx={{
              width: {
                xs: "30%",
                sm: "30%",
                md: "30%",
                lg: "20%",
                xl: "20%",
              },
              ...(isCrewBoss && {
                color: "#faa61a !important",
                cursor: "pointer",
              }),
            }}
            onClick={() => {
              if (!isCrewBoss) return;
              setCrewUserId(item.id.toString());
              setCrewTotalPayout(item.earning);
              setCrewUserType("YTD");
              setCrewPayDetailsOpen(true);
            }}
          >
            {/* $510.00 */}
            {/* ${parseFloat(item.earning).toFixed(2)} */}
            {/* {isCrewBoss
                            ? <>${parseFloat(item.earning).toFixed(2)}</>
                            : "-"
                        } */}
            {isCrewBoss ? <CurrencyFormat amount={item.earning} /> : "-"}
          </Box>
        </Box>

        {expanded &&
          item.subs?.map((sub, index) => (
            <Box key={index} sx={{ padding: 0, width: "100% !important" }}>
              <Box
                sx={{
                  borderTop: "none",
                  borderBottom: "none",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Box
                  className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
                  sx={{ width: "40%" }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                  >
                    {isCrewBoss ? (
                      <img src={arrowIcon} alt="" className="arrow-icon" />
                    ) : (
                      <img src={arrowIcon} alt="" className="arrow-icon" />
                    )}
                    {/* Hernando Diaz */}
                    {sub.first_name + " " + sub.last_name}
                  </span>
                </Box>
                <Box
                  className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
                  sx={{
                    width: "20%",
                    display: {
                      xs: "none !important",
                      sm: "none !important",
                      md: "none !important",
                      lg: "block !important",
                      xl: "block !important",
                    },
                  }}
                >
                  {/* Crew Boss */}
                  {sub.is_crewboss ? "Crew Boss" : "Crew"}
                </Box>
                <Box
                  className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
                  sx={{
                    width: {
                      xs: "30%",
                      sm: "30%",
                      md: "30%",
                      lg: "20%",
                      xl: "20%",
                    },
                    // commented by pp on 12 apr 2024 to make next payout color white
                    // ...(isCrewBoss && { color: "#faa61a !important", cursor: "pointer", }),
                  }}
                  // click event commented by pp on 12 apr 2024 to make next payout unclickable
                  // onClick={() => {
                  //     if (!isCrewBoss) return;
                  //     setCrewUserId(sub.id.toString());
                  //     setCrewTotalPayout(sub.NextPayout);
                  //     setCrewUserType("NEXT");
                  //     setCrewPayDetailsOpen(true);
                  // }}
                >
                  {/* ${parseFloat(sub.NextPayout).toFixed(2)} */}
                  {/* {isCrewBoss
                                        ? <>${parseFloat(sub.NextPayout).toFixed(2)}</>
                                        : "-"
                                    } */}
                  {isCrewBoss ? (
                    <CurrencyFormat amount={sub.NextPayout} />
                  ) : (
                    "-"
                  )}
                </Box>
                <Box
                  className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
                  sx={{
                    width: {
                      xs: "30%",
                      sm: "30%",
                      md: "30%",
                      lg: "20%",
                      xl: "20%",
                    },
                    ...(isCrewBoss && {
                      color: "#faa61a !important",
                      cursor: "pointer",
                    }),
                  }}
                  onClick={() => {
                    if (!isCrewBoss) return;
                    setCrewUserId(sub.id.toString());
                    setCrewTotalPayout(sub.earning);
                    setCrewUserType("YTD");
                    setCrewPayDetailsOpen(true);
                  }}
                >
                  {/* ${parseFloat(sub.earning).toFixed(2)} */}
                  {/* {isCrewBoss
                                        ? <>${parseFloat(sub.earning).toFixed(2)}</>
                                        : "-"
                                    } */}
                  {isCrewBoss ? <CurrencyFormat amount={sub.earning} /> : "-"}
                </Box>
              </Box>
            </Box>
          ))}

        {/* {expanded && (
                    <Box sx={{ padding: 0, width: "100% !important" }}>
                        <Box
                            sx={{
                                borderTop: "none",
                                borderBottom: "none",
                                display: "flex",
                                width: "100%",
                            }}
                        >
                            <Box className="bc-cell" sx={{ width: "40%" }}>
                                <span
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.3rem",
                                    }}
                                >
                                    <img src={arrowIcon} alt="" className="arrow-icon" />
                                    Hernando Diaz
                                </span>
                            </Box>
                            <Box
                                className="bc-cell"
                                sx={{
                                    width: "20%",
                                    display: {
                                        xs: "none",
                                        sm: "none",
                                        md: "none",
                                        lg: "block",
                                        xl: "block",
                                    },
                                }}
                            >
                                Crew Boss
                            </Box>
                            <Box
                                className="bc-cell"
                                sx={{
                                    width: {
                                        xs: "30%",
                                        sm: "30%",
                                        md: "30%",
                                        lg: "20%",
                                        xl: "20%",
                                    },
                                }}
                            >
                                $100.00
                            </Box>
                            <Box
                                className="bc-cell"
                                sx={{
                                    width: {
                                        xs: "30%",
                                        sm: "30%",
                                        md: "30%",
                                        lg: "20%",
                                        xl: "20%",
                                    },
                                }}
                            >
                                $5.00
                            </Box>
                        </Box>
                    </Box>
                )} */}
      </>
    );
  };

  return (
    <Box className="bc">
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: "0rem",
            }}
          >
            <Typography
              sx={{
                color: "#fff !important",
                fontSize: {
                  xs: "18px",
                  sm: "22px",
                  md: "24px",
                  lg: "26px",
                  xl: "30px",
                },
                fontFamily: "GothamBold !important",
              }}
            >
              My Status
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-end",
                gap: {
                  xs: "5px",
                  sm: "5px",
                  md: "7px",
                  lg: "10px",
                  xl: "10px",
                },
                marginTop: {
                  xs: "-5px",
                  sm: "-5px",
                  md: "-5px",
                  lg: "-10px",
                  xl: "-10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "16px",
                    lg: "20px",
                    xl: "22px",
                  },
                  fontFamily: "Arial !important",
                  fontWeight: "500",
                  color: "#fff !important",
                }}
              >
                {allObject?.userInfo?.is_crewboss ? "Crew Boss" : "Crew"}
              </Typography>
              {allObject?.userInfo?.is_crewboss ? (
                <Link
                  sx={{
                    color: "#faa61a !important",
                    textDecorationColor: "#faa61a !important",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "16px",
                      lg: "20px",
                      xl: "22px",
                    },
                    fontFamily: "Arial !important",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={handleCrewPayamentModalOpen}
                >
                  {/* {allObject?.userInfo?.is_crewboss
                                        ? `Renew by : ${moment(allObject?.userInfo?.renewal_date).format("MM/DD/YYYY")}`
                                        : "Make Me a Crew Boss!"
                                    } */}
                  {`Renew By : ${moment(
                    allObject?.userInfo?.renewal_date
                  ).format("MM/DD/YYYY")}`}
                </Link>
              ) : (
                <Link
                  sx={{
                    color: "#faa61a !important",
                    textDecorationColor: "#faa61a !important",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "20px",
                      xl: "22px",
                    },
                    fontFamily: "Arial !important",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={handleCrewPayamentModalOpen}
                >
                  Make Me a Crew Boss!
                </Link>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: "0rem",
            }}
          >
            <Typography
              sx={{
                color: "#fff !important",
                fontSize: {
                  xs: "18px",
                  sm: "22px",
                  md: "24px",
                  lg: "26px",
                  xl: "30px",
                },
                fontFamily: "GothamBold !important",
              }}
            >
              My Crew Boss
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-end",
                gap: {
                  xs: "5px",
                  sm: "5px",
                  md: "7px",
                  lg: "10px",
                  xl: "10px",
                },
                marginTop: {
                  xs: "-5px",
                  sm: "-5px",
                  md: "-5px",
                  lg: "-10px",
                  xl: "-10px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "16px",
                    lg: "20px",
                    xl: "22px",
                  },
                  fontFamily: "Arial !important",
                  fontWeight: "500",
                  color: "#fff !important",
                }}
              >
                {allObject?.userInfo?.my_crew == null ||
                allObject?.userInfo?.my_crew == ""
                  ? "None"
                  : allObject?.userInfo?.my_crew}
              </Typography>
              {(allObject?.userInfo?.my_crew == null ||
                allObject?.userInfo?.my_crew == "") && (
                <Link
                  sx={{
                    color: "#faa61a !important",
                    textDecorationColor: "#faa61a !important",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "16px",
                      lg: "20px",
                      xl: "22px",
                    },
                    fontFamily: "Arial !important",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={() => setReferralModalOpen(true)}
                >
                  Add Referral Code
                </Link>
              )}
            </Box>
          </Box>
          <Box>
            <Accordion disableGutters className="toolbox-accordion">
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  backgroundColor: "transparent !important",
                  color: "#faa61a",
                }}
              >
                <Typography
                  sx={{
                    color: "#faa61a !important",
                    fontSize: "clamp(16px, 3vw ,24px)",
                    fontFamily: "Montserrat !important",
                    fontWeight: "700",
                  }}
                >
                  Tool Box
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <Link
                                    href="/terms_and_conditions"
                                    target="_blank"
                                    className=""
                                    sx={{
                                        color: "#faa61a !important",
                                        textDecorationColor: "#faa61a !important",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Typography>Terms and Conditions</Typography>
                                </Link> */}
                <Link
                  target="_blank"
                  className=""
                  href={`${backend_url}/crew-manual`}
                  // onClick={() => setPdfViewerModalOpen(true)}
                  sx={{
                    mt: 1,
                    color: "#faa61a !important",
                    textDecorationColor: "#faa61a !important",
                    cursor: "pointer",
                    fontFamily: "Montserrat !important",
                    fontWeight: "600",
                  }}
                >
                  Crew Manual
                </Link>
                {/* <Link
                                    target="_blank"
                                    className=""
                                    sx={{
                                        color: "#faa61a !important",
                                        textDecorationColor: "#faa61a !important",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Typography>Examples</Typography>
                                </Link> */}
                {/* <Link
                                    target="_blank"
                                    className=""
                                    sx={{
                                        color: "#faa61a !important",
                                        textDecorationColor: "#faa61a !important",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Typography>Compensation Plan</Typography>
                                </Link> */}
                {/* <Link
                                    target="_blank"
                                    className=""
                                    sx={{
                                        color: "#faa61a !important",
                                        textDecorationColor: "#faa61a !important",
                                        cursor: "pointer",
                                    }}
                                >
                                    <Typography>Earnings Calculator</Typography>
                                </Link> */}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box
          sx={{
            border: "2px solid #faa61a",
            borderRadius: "8px",
            padding: {
              xs: "0.5rem",
              sm: "0.5rem",
              md: "0.5rem",
              lg: "1rem",
              xl: "1rem",
            },
            width: "fit-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: {
              xs: "5px",
              sm: "5px",
              md: "10px",
              lg: "10px",
              xl: "10px",
            },
            cursor: "pointer",
          }}
          onClick={handleShare}
        >
          <Typography
            sx={{
              color: "#fff !important",
              textAlign: "center",
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "16px",
                lg: "18px",
                xl: "24px",
              },
              cursor: "pointer",
              fontWeight: "700",
              fontFamily: "Montserrat !important",
            }}
            // onClick={handleShare}
          >
            Share Referral Code
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img src={shareIcon} alt="" className="share-icon" />
            <Typography
              sx={{
                color: "#faa61a",
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "16px",
                  lg: "18px",
                  xl: "24px",
                },
              }}
            >
              {AuthUserID}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="my-crew">
        <form action="">
          <Typography
            variant="h3"
            sx={{
              color: "#fff",
              fontSize: {
                xs: "1.5rem",
                sm: "1.5rem",
                md: "2rem",
                lg: "2.5rem",
                xl: "3rem",
              },
            }}
          >
            My Crew
          </Typography>
          <Box className="checbox-grp">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGeneralSelected}
                  onChange={() => {
                    if (isGeneralSelected && !isSubSelected) return;
                    setIsGeneralSelected(!isGeneralSelected);
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: "#fff",
                    "&.Mui-checked": {
                      color: "#fff",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "20px",
                      lg: "24px",
                      xl: "26px",
                      fontFamily: "Montserrat !important",
                      fontWeight: "600",
                    },
                  }}
                >
                  Generals{" "}
                  <span style={{ color: "#faa61a" }}>
                    (
                    {allObject?.userInfo?.generals
                      ? allObject?.userInfo?.generals
                      : 0}
                    )
                  </span>
                </Typography>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isSubSelected}
                  onChange={() => {
                    if (isSubSelected && !isGeneralSelected) return;
                    setIsSubSelected(!isSubSelected);
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: "#fff",
                    "&.Mui-checked": {
                      color: "#fff",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "20px",
                      lg: "24px",
                      xl: "26px",
                      fontFamily: "Montserrat !important",
                      fontWeight: "600",
                    },
                  }}
                >
                  Subs{" "}
                  <span style={{ color: "#faa61a" }}>
                    ({allObject?.userInfo?.subs ? allObject?.userInfo?.subs : 0}
                    )
                  </span>
                </Typography>
              }
            />

            {/* <Box className="form-group">

                            <input type="checkbox" id="generals"
                                checked={isGeneralSelected}
                                onChange={() => {
                                    if (isGeneralSelected && !isSubSelected) return;
                                    setIsGeneralSelected(!isGeneralSelected)
                                }}
                            />
                            <label htmlFor="generals" className="label">
                                Generals <span>({allObject?.userInfo?.generals ? allObject?.userInfo?.generals : 0})</span>
                            </label>
                        </Box>
                        <Box className="form-group">
                            <input type="checkbox" id="subs"
                                checked={isSubSelected}
                                onChange={() => {
                                    if (isSubSelected && !isGeneralSelected) return;
                                    setIsSubSelected(!isSubSelected)
                                }}
                            />
                            <label htmlFor="subs">
                                Subs <span>({allObject?.userInfo?.subs ? allObject?.userInfo?.subs : 0})</span>
                            </label>
                        </Box> */}
          </Box>

          <TextField
            className="search-my-crew-input"
            placeholder="Search My Crew"
            id="margin-dense"
            margin="dense"
            InputLabelProps={{
              shrink: false,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "2px solid #faa61a",
                background: "white",
                fontFamily: "Gotham !important",
                borderRadius: "7px !important",
                "& fieldset": {
                  borderColor: "#faa61a",
                  borderWidth: "2px",
                },

                "&:hover fieldset": {
                  borderColor: "#faa61a",
                },

                "&.Mui-focused": {
                  "& fieldset": {
                    borderColor: "#faa61a",
                  },
                },
              },
              "& .MuiInputBase-input": {
                color: "#2e2e2e",
                padding: "8px 14px",
              },
            }}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </form>
      </Box>

      <Box
        sx={{
          margin: {
            xs: "1rem 0rem 1rem 0rem",
            sm: "1rem 0rem 1rem 0rem",
            md: "1.5rem 0rem 1rem 0rem",
            lg: "2rem 0rem 1rem 0rem",
            xl: "2rem 0rem 1rem 0rem",
          },
        }}
      >
        <Box
          className="bc-thead-row"
          sx={{ borderBottom: "4px solid #faa61a", display: "flex" }}
        >
          <Box className="bc-thead" sx={{ width: "40%" }}>
            Name
          </Box>
          <Box
            className="bc-thead"
            sx={{
              width: "20%",
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
                xl: "block",
              },
            }}
          >
            Status
          </Box>
          <Box
            className="bc-thead"
            sx={{
              width: { xs: "30%", sm: "30%", md: "30%", lg: "20%", xl: "20%" },
            }}
          >
            My Next Payout
          </Box>
          <Box
            className="bc-thead"
            sx={{
              width: { xs: "30%", sm: "30%", md: "30%", lg: "20%", xl: "20%" },
            }}
          >
            My YTD Earnings
          </Box>
        </Box>
        <Box
          sx={{
            minHeight: "100px",
            maxHeight: "200px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          {data.length === 0 && isCrewBoss && (
            <Box className="msg-cell" sx={{ textAlign: "center" }}>
              <Typography
                className=""
                sx={{
                  fontFamily: "Montserrat !important",
                  fontWeight: "700",
                  textAlign: "center",
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                    md: "18px",
                    lg: "20px",
                    xl: "24px",
                  },
                  color: "#fff",
                }}
              >
                Share Your Referral Code to Start Building Your Crew and Growing
                Your Wallet!
              </Typography>
            </Box>
          )}

          {data.length === 0 && !isCrewBoss && (
            <Box className="msg-cell" sx={{ textAlign: "center" }}>
              <Typography
                className=""
                sx={{
                  fontFamily: "Montserrat !important",
                  fontWeight: "700",
                  textAlign: "center",
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                    md: "18px",
                    lg: "20px",
                    xl: "24px",
                  },
                  color: "#fff",
                }}
              >
                Get Started Building Your Crew and Growing Your Wallet!
                &nbsp;&nbsp;
                <Link
                  className="learnmore"
                  href={`${backend_url}/crew-manual`}
                  // onClick={() => setPdfViewerModalOpen(true)}
                  target="_blank"
                >
                  Learn More
                </Link>
              </Typography>
            </Box>
          )}

          {!isCrewBoss && data.length > 0 && (
            <Box
              sx={{
                zIndex: "1000",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "0px 5px 20px 0px rgba(0,0,0,0.4)",
                position: "absolute",
                right: "10%",
                top: "5%",
                backgroundColor: "#47463E",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography variant="h5" sx={{ color: "#fff !important" }}>
                Unlock Earnings
              </Typography>

              <Link
                sx={{
                  fontSize: "clamp(14px, 3vw, 20px)",
                  color: "#faa61a !important",
                  textDecorationColor: "#faa61a !important",
                  cursor: "pointer",
                }}
                onClick={handleCrewPayamentModalOpen}
              >
                Make me a Crew Boss
              </Link>
            </Box>
          )}

          {filterData.map((item, index) => (
            <TableRow key={index} item={item} />
          ))}
        </Box>

        <Box sx={{ borderTop: "4px solid #faa61a", borderBottom: "none" }}>
          <Box
            sx={{ borderTop: "none", borderBottom: "none", display: "flex" }}
          >
            <Box
              className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
              sx={{
                width: {
                  xs: "40%",
                  sm: "40%",
                  md: "40%",
                  lg: "60%",
                  xl: "60%",
                },
              }}
            >
              TOTALS
            </Box>
            <Box
              className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
              sx={{
                width: {
                  xs: "30%",
                  sm: "30%",
                  md: "30%",
                  lg: "20%",
                  xl: "20%",
                },
              }}
            >
              {/* $73.55 */}
              {/* ${totalMyEarning.toFixed(2)} */}
              {/* {isCrewBoss
                                ? <>$ {totalMyEarning.toFixed(2)}</>
                                : "-"
                            } */}
              {isCrewBoss ? <CurrencyFormat amount={totalMyEarning} /> : "-"}
            </Box>
            <Box
              className={`bc-cell ${!isCrewBoss && "disabled-cell"}`}
              sx={{
                width: {
                  xs: "30%",
                  sm: "30%",
                  md: "30%",
                  lg: "20%",
                  xl: "20%",
                },
              }}
            >
              {/* $860.00 */}
              {/* ${totalYDT.toFixed(2)} */}
              {/* {isCrewBoss
                                ? <>$ {totalYDT.toFixed(2)}</>
                                : "-"
                            } */}
              {isCrewBoss ? <CurrencyFormat amount={totalYDT} /> : "-"}
            </Box>
          </Box>
        </Box>
      </Box>

      {referralModalOpen && (
        <AddReferralModal
          open={referralModalOpen}
          handleClose={closeModal}
          fetchCrewListing={fetchCrewListingDemo}
        />
      )}

      {crewPaymentModelOpen && (
        <CrewPaymentModal
          crewPaymentModelOpen={crewPaymentModelOpen}
          setCrewPaymentModelOpen={setCrewPaymentModelOpen}
          fetchCrewListing={fetchCrewListingDemo}
          allObject={allObject}
          setReferralModalOpen={setReferralModalOpen}
        />
      )}

      {/* {shareReferralModalOpen &&
                <ShareReferralModal
                    shareReferralModalOpen={shareReferralModalOpen}
                    setShareReferralModalOpen={setShareReferralModalOpen}
                />
            } */}

      {/* {pdfViewerModalOpen &&
                <PdfViewerModal
                    pdfViewerModalOpen={pdfViewerModalOpen}
                    setPdfViewerModalOpen={setPdfViewerModalOpen} />
            } */}

      {crewPayDetailsOpen && (
        <CrewPaymentDetailsModal
          crewPayDetailsOpen={crewPayDetailsOpen}
          setCrewPayDetailsOpen={setCrewPayDetailsOpen}
          fromId={crewUserId}
          type={crewUserType}
          crewTotalPayout={crewTotalPayout}
        />
      )}

      <Box>
        <Dialog
          open={userUnauthorized}
          aria-labelledby="draggable-dialog-title"
        >
          <Box className="session-expire">
            <DialogContent>
              <DialogTitle
                style={{
                  color: "#fff",
                  fontSize: "24px",
                  textAlign: "center",
                  marginTop: "-32px",
                }}
              >
                Error
              </DialogTitle>
              <DialogContentText style={{ color: "#fff", fontSize: "16px" }}>
                You must be logged in with same credentials.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className=""
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#de8d14 !important",
                  color: "#fff !important",
                  padding: "6px 50px 6px 50px",
                  borderRadius: "7px",
                  fontFamily: "GothamBold !important",
                  fontSize: "20px !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // Set user verification to false
                  setUserUnauthorized(false);

                  // Redirect to the home page
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 100);
                }}
              >
                OKAY
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>

      {profileCompleteModal && (
        <RentSteps
          handleClose={handleProfileCompeleteModalClose}
          open={profileCompleteModal}
        />
      )}
    </Box>
  );
};

export default BuildACrew;
