import { Box, Divider, List, ListItem, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import { generateTimeSlots } from "utils/commonFxns";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";

const CustomCalendarTime = ({
  label,
  timeZoneAbbr,
  equipRentedTimes,
  setEquipRentedTimes,
  minDateTime,
  equipRentedDates,
  openInModal = false,
  setTempRentalTimes = undefined,
  modalOpen = false,
}) => {
  const dispatch = useDispatch();
  const AllPartialDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.allPartialDates
  );

  const AllReservedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.reservedDates
  );

  const [disabledIndex, setDisabledIndex] = useState(0);
  // highlited time selection index state start
  const [startTimeIndex, setStartTimeIndex] = useState<any>([null, null]);
  const [endTimeIndex, setEndTimeIndex] = useState<any>([null, null]);
  // highlited time selection index state end

  const [bookedPickupIndex, setBookedPickupIndex] = useState<any>({});
  const [bookedDropoffIndex, setBookedDropoffIndex] = useState<any>({});
  const [isToday, setIsToday] = useState<boolean>(false);

  const timeListRef = useRef<HTMLDivElement | null>(null);

  const handleTimeSelect = (time, label) => {
    if (
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      label?.includes("Drop-off") &&
      !equipRentedTimes?.startTime
    ) {
      return false;
    }

    if (
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      equipRentedDates[0]?.format("YYYY-MM-DD") ===
        equipRentedDates[1]?.format("YYYY-MM-DD") &&
      AllReservedDates[0]?.hasOwnProperty(
        equipRentedDates[0]?.format("YYYY-MM-DD")
      )
    ) {
      if (label?.includes("Pickup")) {
        setEquipRentedTimes({ startTime: time, endTime: null });
        if (setTempRentalTimes) {
          setTempRentalTimes({ startTime: time, endTime: null });
        }
        return false;
      }
      if (
        Object.entries(bookedDropoffIndex)?.length &&
        label?.includes("Drop-off")
      ) {
        const slotKeys = Object.keys(bookedDropoffIndex);
        const startIndex = slotKeys.indexOf(equipRentedTimes?.startTime);
        const remainingSlots = slotKeys.slice(startIndex);
        const endIndex = remainingSlots.findIndex(
          (time) => bookedDropoffIndex[time]["disable"]
        );
        const validTimes = slotKeys.slice(
          startIndex,
          endIndex === -1 ? slotKeys.length : startIndex + endIndex
        );
        if (!validTimes?.includes(time)) {
          dispatch(setalertMessage("Please select a valid time range."));
          dispatch(setalertMessagetype("error"));
          return false;
        }
      }
    } else if (
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      equipRentedDates[0]?.format("YYYY-MM-DD") !==
        equipRentedDates[1]?.format("YYYY-MM-DD") &&
      (AllReservedDates[0]?.hasOwnProperty(
        equipRentedDates[1]?.format("YYYY-MM-DD")
      ) ||
        AllReservedDates[0]?.hasOwnProperty(
          equipRentedDates[0]?.format("YYYY-MM-DD")
        ))
    ) {
      // Handle the case where the label includes "Pickup"
      if (label?.includes("Pickup")) {
        setEquipRentedTimes({ startTime: time, endTime: null });
        if (setTempRentalTimes) {
          setTempRentalTimes({ startTime: time, endTime: null });
        }
        return false;
      }

      if (label?.includes("Drop-off")) {
        if (
          Object.entries(bookedDropoffIndex).length &&
          Object.entries(bookedPickupIndex).length
        ) {
          const slotKeys1 = Object.keys(bookedPickupIndex).reverse();
          const startIndex = 0;
          const endIndex = slotKeys1.findIndex(
            (time) => bookedPickupIndex[time]["disable"]
          );
          const validTimes1 = slotKeys1.slice(startIndex, endIndex);
          const slotKeys2 = Object.keys(bookedDropoffIndex);
          const startIndex2 = 0;
          const endIndex2 = slotKeys2.findIndex(
            (time) => bookedDropoffIndex[time]["disable"]
          );
          if (endIndex === -1 || endIndex2 === -1) {
            return false;
          }
          const validTimes2 = slotKeys2.slice(startIndex, endIndex2);
          if (
            !validTimes1.includes(equipRentedTimes?.startTime) ||
            !validTimes2?.includes(time)
          ) {
            dispatch(setalertMessage("Please select a valid time range."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
        } else if (Object.entries(bookedDropoffIndex).length) {
          const slotKeys = Object.keys(bookedDropoffIndex);
          const endIndex = slotKeys.findIndex(
            (time) => bookedDropoffIndex[time]["disable"]
          );
          const validTimes = slotKeys.slice(
            0,
            endIndex === -1 ? slotKeys.length : endIndex
          );
          if (!validTimes.includes(time)) {
            dispatch(setalertMessage("Please select a valid time range."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
        } else if (Object.entries(bookedPickupIndex).length) {
          const slotKeys = Object.keys(bookedPickupIndex).reverse();
          const endIndex = slotKeys.findIndex(
            (time) => bookedPickupIndex[time]["disable"]
          );
          const validTimes = slotKeys.slice(
            0,
            endIndex === -1 ? slotKeys.length : endIndex
          );
          if (!validTimes.includes(equipRentedTimes?.startTime)) {
            dispatch(setalertMessage("Please select a valid time range."));
            dispatch(setalertMessagetype("error"));
            return false;
          }
        }
      }

      // Handle the case where the label includes "Drop-off" and bookedDropoffIndex has entries
      // if (label?.includes("Drop-off") && Object.entries(bookedDropoffIndex).length) {
      //   const slotKeys = Object.keys(bookedDropoffIndex);
      //   const endIndex = slotKeys.findIndex((time) => bookedDropoffIndex[time]["disable"]);
      //   const validTimes = slotKeys.slice(0, endIndex === -1 ? slotKeys.length : endIndex);

      //   if (!validTimes.includes(time)) {
      //     dispatch(setalertMessage("Please select a valid time range."));
      //     dispatch(setalertMessagetype("error"));
      //     return false;
      //   }
      // }

      // Handle the case where label includes "Drop-off", bookedDropoffIndex is empty, and bookedPickupIndex has entries
      // if (
      //   label?.includes("Drop-off") &&
      //   !Object.entries(bookedDropoffIndex).length &&
      //   Object.entries(bookedPickupIndex).length
      // ) {
      //   const slotKeys = Object.keys(bookedPickupIndex);
      //   const endIndex = slotKeys.findIndex((time) => bookedPickupIndex[time]["disable"]);

      //   // If there is a valid end index, show an error message
      //   if (endIndex !== -1) {
      //     dispatch(setalertMessage("Please select a valid time range."));
      //     dispatch(setalertMessagetype("error"));
      //     return false;
      //   }
      // }
    }

    setEquipRentedTimes((prev) => {
      if (label?.includes("Pickup")) {
        if (
          equipRentedDates[0] &&
          equipRentedDates[1] &&
          equipRentedDates[0]?.format("MM/DD/YY") ===
            equipRentedDates[1]?.format("MM/DD/YY")
        ) {
          if (time >= prev?.endTime) {
            const startMoment = moment(time, "HH:mm");
            const endMoment = startMoment.clone().add(30, "minutes");
            return { startTime: time, endTime: endMoment.format("HH:mm") };
          }
        }
        return { ...prev, startTime: time };
      } else if (label?.includes("Drop-off")) {
        return { ...prev, endTime: time };
      }
    });
    if (setTempRentalTimes) {
      setTempRentalTimes((prev) => {
        if (label?.includes("Pickup")) {
          if (
            equipRentedDates[0] &&
            equipRentedDates[1] &&
            equipRentedDates[0]?.format("MM/DD/YY") ===
              equipRentedDates[1]?.format("MM/DD/YY")
          ) {
            if (time >= prev?.endTime) {
              const startMoment = moment(time, "HH:mm");
              const endMoment = startMoment.clone().add(30, "minutes");
              return { startTime: time, endTime: endMoment.format("HH:mm") };
            }
          }
          return { ...prev, startTime: time };
        } else if (label?.includes("Drop-off")) {
          return { ...prev, endTime: time };
        }
      });
    }
  };

  const calculateReturnDisabledIndex = (startTime: string) => {
    const [startHours, startMinutes] = startTime.split(":");
    return parseInt(startHours) * 2 + (parseInt(startMinutes) >= 30 ? 1 : 0);
  };

  const generateIndex = (time24) => {
    const [hours, minutes] = time24?.split(":");
    const index = minutes >= 30 ? parseInt(hours) * 2 + 1 : parseInt(hours) * 2;
    return index;
  };

  useEffect(() => {
    if (
      minDateTime?.minTime &&
      label.includes("Pickup") &&
      equipRentedDates[0]?.format("MM/DD/YY") === moment().format("MM/DD/YY")
    ) {
      const [hours, minutes] = minDateTime?.minTime?.split(":");
      const disabledIndex =
        minutes >= 30 ? parseInt(hours) * 2 + 1 : parseInt(hours) * 2;
      setDisabledIndex(disabledIndex);
    } else if (
      label.includes("Drop-off") &&
      equipRentedTimes?.startTime &&
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      equipRentedDates[0]?.format("MM/DD/YY") ===
        equipRentedDates[1]?.format("MM/DD/YY")
    ) {
      // Disable times based on the selected start time
      const returnDisabledIndex = calculateReturnDisabledIndex(
        equipRentedTimes?.startTime
      );
      setDisabledIndex(returnDisabledIndex);
    } else if (label.includes("Drop-off")) {
      setDisabledIndex(0);
    } else if (label.includes("Pickup")) {
      setDisabledIndex(0);
    }

    if (
      equipRentedTimes?.startTime &&
      equipRentedTimes?.endTime &&
      equipRentedDates[0] &&
      equipRentedDates[1]
    ) {
      if (
        equipRentedDates[0]?.format("MM/DD/YY") ===
        equipRentedDates[1]?.format("MM/DD/YY")
      ) {
        const startIndex = generateIndex(equipRentedTimes?.startTime);
        const endIndex = generateIndex(equipRentedTimes?.endTime);
        setEndTimeIndex([startIndex, endIndex]);
        setStartTimeIndex([startIndex, endIndex]);
      } else {
        const startIndex = generateIndex(equipRentedTimes?.startTime);
        const endIndex = generateIndex(equipRentedTimes?.endTime);
        setStartTimeIndex([startIndex, 48]);
        setEndTimeIndex([0, endIndex]);
      }
    } else {
      setEndTimeIndex([null, null]);
      setStartTimeIndex([null, null]);
    }
    if (equipRentedDates[0] && !equipRentedDates[1]) {
      const startDate = equipRentedDates[0]?.format("YYYY-MM-DD");
      if (AllReservedDates[0]?.hasOwnProperty(startDate)) {
        setBookedPickupIndex(AllReservedDates[0][startDate]);
      } else {
        setBookedPickupIndex({});
      }
    }
    if (equipRentedDates[1]) {
      const endDate = equipRentedDates[1]?.format("YYYY-MM-DD");
      if (AllReservedDates[0]?.hasOwnProperty(endDate)) {
        setBookedDropoffIndex(AllReservedDates[0][endDate]);
      } else {
        setBookedDropoffIndex({});
      }
    }

    if (equipRentedDates[0] || equipRentedDates[0]) {
      const isToday =
        equipRentedDates[0]?.format("MM/DD/YY") ===
          moment().format("MM/DD/YY") ||
        equipRentedDates[1]?.format("MM/DD/YY") === moment().format("MM/DD/YY");
      setIsToday(isToday);
    }
  }, [minDateTime, equipRentedTimes, equipRentedDates]);
  const timeSlots = useMemo(() => generateTimeSlots(), []);
  const selectedSameDate =
    equipRentedDates[0] &&
    equipRentedDates[0] &&
    equipRentedDates[1]?.format("MM/DD/YY") ===
      equipRentedDates[0]?.format("MM/DD/YY");
  useEffect(() => {
    if (equipRentedDates[0] || equipRentedDates[1]) {
      let index = 16;
      if (
        equipRentedDates[0]?.format("MM/DD/YY") === moment().format("MM/DD/YY")
      ) {
        const time = parseInt(minDateTime?.minTime?.split(":")?.[0]);
        if(time){
          const nIndex = time * 2
          if(nIndex>index) {
            index = 0;
          }
        }
      } else if (label?.includes("Drop-off")) {
        index = 34;
        if (selectedSameDate) {
          const time = parseInt(equipRentedTimes?.startTime?.split(":")?.[0]);
          index = time * 2;
        }
      }
      // let index = 0;
      // Array.from(timeListRef.current?.children).forEach((div, idx) => {
      //   const ptag = div?.querySelector("p");
      //   console.log("minDateTime",minDateTime);
      //   if (ptag) {
      //     if (label?.includes("Pickup")) {
      //       if (
      //         ptag.textContent?.includes("8") &&
      //         ptag.textContent?.includes("AM")
      //       ) {
      //         index = idx>0?idx-1:0;
      //       }
      //     } else {
      //       if (selectedSameDate) {
      //         const time = parseInt(
      //           equipRentedTimes?.startTime?.split(":")?.[0]
      //         );
      //         if (
      //           ptag.textContent?.includes(time?.toString()) &&
      //           ptag.textContent?.includes(time >= 12 ? "PM" : "AM")
      //         ) {
      //           index = idx>0?idx-1:0;
      //         }
      //       } else if (
      //         ptag.textContent?.includes("5") &&
      //         ptag.textContent?.includes("PM")
      //       ) {
      //         index = idx>0?idx-1:0;
      //       }
      //     }
      //   }
      // });
      const targetEle = timeListRef.current?.children[index];
      if (targetEle) {
        targetEle.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, [equipRentedDates]);

  useEffect(() => {
    if (modalOpen) {
      document.body.classList.add("body-fix");
    } else {
      document.body.classList.remove("body-fix");
    }
  }, [modalOpen]);

  return (
    <Box
      sx={{
        border: "none",
        borderRadius: 0,
        padding: 1,
        background: "transparent",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          marginBottom: 1,
          fontFamily: "GothamBold !important",
          color: "#faa61a",
          fontWeight: "600",
          mb: 2,
          fontSize: {
            xs: openInModal ? "1rem" : "",
            sm: openInModal ? "1.3rem" : "1.0rem",
            md: openInModal ? "1.5rem" : "1.1rem",
            lg: openInModal ? "1.6rem" : "1.2rem",
            xl: openInModal ? "1.7rem" : "1.3rem",
          },
          textTransform: "capitalize",
        }}
      >
        {label?.replace("Pickup", "Start")?.replace("Drop-off", "End")} (
        {timeZoneAbbr})
      </Typography>
      {!openInModal && <Box sx={{ padding: "4px 0px" }}></Box>}
      {/* {openInModal ? (
        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "center",
              mb: "5px",
              fontWeight: "600",
              fontFamily: "Montserrat !important",
              color: "#fff",
            }}
          >
            {label.includes("Pickup") ? "Pickup Time" : "Drop-off Time"} :
          </Typography>
          <Typography
            sx={{
              fontWeight: "700",
              fontFamily: "Montserrat !important",
              color: "#faa61a",
              // background: "#faa61a",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "12px",
              p: "5px",
              border: "1px solid #5a5a52",
              borderRadius: 2,
            }}
          >
            {label.includes("Pickup") && equipRentedTimes?.startTime
              ? moment(equipRentedTimes.startTime, "HH:mm").format("hh:mm A")
              : label.includes("Drop-off") && equipRentedTimes?.endTime
              ? moment(equipRentedTimes.endTime, "HH:mm").format("hh:mm A")
              : "--:-- AM/PM"}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              textAlign: "center",
              mb: "5px",
              fontWeight: "500",
              fontFamily: "Montserrat !important",
              color: "#fff",
            }}
          >
            {label.includes("Pickup") ? "Pickup Time" : "Drop-off Time"}
          </Typography>
          <Typography
            sx={{
              fontWeight: "700",
              fontFamily: "Montserrat !important",
              color: "#fff",
              // background: "#faa61a",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "12px",
              p: "5px",
              border: "1px solid #5a5a52",
              borderRadius: 2,
            }}
          >
            {label.includes("Pickup") && equipRentedTimes?.startTime
              ? moment(equipRentedTimes.startTime, "HH:mm").format("hh:mm A")
              : label.includes("Drop-off") && equipRentedTimes?.endTime
              ? moment(equipRentedTimes.endTime, "HH:mm").format("hh:mm A")
              : "--:-- AM/PM"}
          </Typography>
        </Box>
      )} */}

      <Box
        ref={timeListRef}
        sx={{
          maxHeight: openInModal ? 450 : 520,
          overflowY: "auto",
          p: 1,
          borderRadius: 1,
          display: "grid",
          // gridTemplateColumns: "repeat(4, 1fr)", //for column
          gridTemplateColumns: `repeat(1, 1fr)`, //for column

          flexWrap: "wrap",
          width: "100%",
          // width:'100px',
          gap: "0.5rem",
          boxSizing: "border-box",
          margin: "auto",
          // background: "#faa61a0d",
        }}
      >
        {/* {timeSlots.map((time, index) => {
          const isPickup = label.includes("Pickup");
          const isDropoff = label.includes("Drop-off");

          // Check if the current time is disabled based on the label
          const isDisabledTime =
            (isPickup && bookedPickupIndex[time]) ||
            (isDropoff && bookedDropoffIndex[time]) ||
            index < disabledIndex;

          const isBookedTime =
            (isPickup && bookedPickupIndex[time]) ||
            (isDropoff && bookedDropoffIndex[time]);
          // Determine if the time slot is in the selected range for Pickup or Drop-off
          const isSelectedTime =
            (isPickup &&
              ((startTimeIndex[0] !== null &&
                startTimeIndex[1] !== null &&
                index >= startTimeIndex[0] &&
                index <= startTimeIndex[1]) ||
                equipRentedTimes?.startTime === time)) ||
            (isDropoff &&
              ((endTimeIndex[0] !== null &&
                endTimeIndex[1] !== null &&
                index >= endTimeIndex[0] &&
                index <= endTimeIndex[1]) ||
                equipRentedTimes?.endTime === time));
          // "#faa61a"
          return (
            <Box
              key={index}
              onClick={() => {
                if (!isDisabledTime) {
                  handleTimeSelect(time, label);
                }
              }}
              sx={{
                display: isToday && index < disabledIndex ? "none" : "block",
                margin: "0 auto",
                width: "50%",
                padding: "5px",
                textAlign: "center",
                borderRadius: 2,
                backgroundColor: isSelectedTime ? "skyblue" : "transparent",
                color: isSelectedTime
                  ? "#fff"
                  : isDisabledTime
                  ? "#aaa"
                  : "#fff",
                border: isSelectedTime
                  ? "1px solid skyblue"
                  : "1px solid #535353",
                "&:hover": {
                  backgroundColor: isDisabledTime ? "transparent" : "skyblue",
                },
                mb: 1,
                cursor: isDisabledTime ? "not-allowed" : "pointer",
                fontFamily: "Montserrat !important",
                fontWeight: "500",
                opacity: isDisabledTime ? 0.5 : 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Montserrat !important",
                }}
              >
                {moment(time, "HH:mm").format("hh:mm A")}
              </Typography>
            </Box>
          );
        })} */}
        {timeSlots.map((time, index) => {
          const isPickup = label.includes("Pickup");
          const isDropoff = label.includes("Drop-off");
          const bookedPickupTime = bookedPickupIndex[time];
          const bookedDropoffTime = bookedDropoffIndex[time];
          // Check if the current time is disabled based on the label
          const isDisabledTime =
            (isPickup && bookedPickupTime?.["disable"]) ||
            (isDropoff && bookedDropoffTime?.["disable"]) ||
            index < disabledIndex;

          // Check if the current time is already booked
          const isBookedTime =
            (isPickup &&
              bookedPickupTime?.["disable"] &&
              bookedPickupTime?.["status"] === "booked") ||
            (isDropoff &&
              bookedDropoffTime?.["disable"] &&
              bookedDropoffTime?.["status"] === "booked");

          const isRequestedTime =
            (isPickup &&
              bookedPickupTime?.["disable"] &&
              bookedPickupTime?.["status"] === "requested") ||
            (isDropoff &&
              bookedDropoffTime?.["disable"] &&
              bookedDropoffTime?.["status"] === "requested");
          // const isBookedTime = false;
          // const isRequestedTime = false;
          // Determine if the time slot is in the selected range for Pickup or Drop-off
          // const isSelectedTime =
          //   (isPickup &&
          //     ((startTimeIndex[0] !== null &&
          //       startTimeIndex[1] !== null &&
          //       index >= startTimeIndex[0] &&
          //       index <= startTimeIndex[1]) ||
          //       equipRentedTimes?.startTime === time)) ||
          // (isDropoff &&
          //   ((endTimeIndex[0] !== null &&
          //     endTimeIndex[1] !== null &&
          //     index >= endTimeIndex[0] &&
          //     index <= endTimeIndex[1]) ||
          //     equipRentedTimes?.endTime === time));
          const isSelectedTime =
            (isDropoff &&
              selectedSameDate &&
              ((endTimeIndex[0] !== null &&
                endTimeIndex[1] !== null &&
                index >= endTimeIndex[0] &&
                index <= endTimeIndex[1]) ||
                equipRentedTimes?.endTime === time)) ||
            (isPickup && equipRentedTimes?.startTime === time) ||
            (isDropoff && equipRentedTimes?.endTime === time);
          const highlightDropOffTime =
            selectedSameDate && equipRentedTimes?.startTime === time;

          return (
            <Box
              key={index}
              onClick={() => {
                if (!isDisabledTime && !highlightDropOffTime) {
                  handleTimeSelect(time, label);
                }
              }}
              sx={{
                display: isToday && index < disabledIndex ? "none" : "block",
                margin: "0 auto",
                width: "50%",
                padding: "5px",
                textAlign: "center",
                borderRadius: 2,
                backgroundColor:
                  isSelectedTime || isRequestedTime || highlightDropOffTime
                    ? "skyblue"
                    : isBookedTime
                    ? "#faa61a"
                    : "transparent",
                color:
                  isSelectedTime || isRequestedTime
                    ? "#fff"
                    : isBookedTime
                    ? "#fff"
                    : isDisabledTime
                    ? "#aaa"
                    : "#fff",
                border: isSelectedTime
                  ? "1px solid skyblue"
                  : "1px solid #535353",
                "&:hover": {
                  backgroundColor: isBookedTime
                    ? "#faa61a"
                    : !isRequestedTime && isDisabledTime
                    ? "transparent"
                    : "skyblue",
                },
                mb: 1,
                cursor:
                  isDisabledTime || highlightDropOffTime
                    ? "not-allowed"
                    : "pointer",
                fontFamily: "Montserrat !important",
                fontWeight: "500",
                opacity: isDisabledTime ? 0.5 : 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Montserrat !important",
                }}
              >
                {moment(time, "HH:mm").format("hh:mm A")}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default CustomCalendarTime;
