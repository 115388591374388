import {
  Box,
  Button,
  TextField,
  Typography,
  Backdrop,
  Container,
} from "@mui/material";
import React, { useCallback, useRef } from "react";
import filterIcon from "../../../assets/images/filter.png";
import dummyImg from "../../../assets/images/jcb.jpg";
import calendarIcon from "../../../assets/images/calendar-icon.png";
import locationIcon from "../../../assets/images/location-white-icon.png";
import chatIcon from "../../../assets/images/chat-icon.png";
import cameraIcon from "../../../assets/images/camera-icon.png";
import EquipmentFilter from "./EquipmentFilterPopup";
import RentalDetailPopup from "./RentalDetailPopup";
import ClearIcon from "@mui/icons-material/Clear";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EquipmentAPIs } from "../../../service/api-service";
import { IStoreValue, OrderDetails, RentalItem } from "utils/types";
import {
  setalertMessagetype,
  setalertMessage,
  setLoginModalOpen,
  setLogInLoading,
} from "redux/redux-slice";
import RentalRequests from "./RentalRequests";
import ActiveRentals from "./ActiveRentals";
import PastRentals from "./PastRentals";
import OrderDetailPopup from "./OrderDetailPopup";
import EquipmentCalender from "./EquipmentCalender";

import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import MainLayout from "layouts/MainLayout";

// Define types for props
interface RentalRequestData {
  title?: string;
  data: RentalItem[];
}

interface ActiveRentalData {
  title?: string;
  data: OrderDetails[];
}

interface cancelOrderParams {
  order_id: number;
}
interface updateRentalRequestParams {
  order_id: number;
  request_id: number;
}

interface activeRentalParams {
  order_id: number;
  renter_id: number;
}

interface filterData {
  title: string;
}

interface filterParams {
  rentalType: string;
  startDate: Date;
  endDate: Date;
  showSelectedType: boolean;
}

interface socketParams {
  orderId: number;
  requestId: number;
  ownerId: number;
  renterId: number;
  type: string;
}

const defaultParams = {
  title: "",
  data: [],
};

let SOCKET_URL = process.env.REACT_APP_STAGING_SOCKET_URL;
if (process.env.REACT_APP_PLATFORM === "Live") {
  SOCKET_URL = process.env.REACT_APP_LIVE_SOCKET_URL;
} else if (process.env.REACT_APP_PLATFORM === "PreLive") {
  SOCKET_URL = process.env.REACT_APP_PRELIVE_SOCKET_URL;
}

let socket;

const EquipmentOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const equipOrOrdercheck = params["id"];
  const urlParamsFilterType = params["filterType"]?.toLowerCase();
  const isActive =
    urlParamsFilterType === "active" || urlParamsFilterType === "advance"
      ? true
      : false;
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfile = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [equipOrOrderId, setEquipOrOrderId] = useState<number>(null);

  const [loading, setLoading] = useState(false);
  const [rentalRequest, setRentalRequest] = useState<RentalRequestData>({
    title: "",
    data: [],
  });
  const [activeRental, setActiveRental] = useState<ActiveRentalData>({
    title: "",
    data: [],
  });
  const [pastRental, setPastRental] = useState<RentalRequestData>({
    title: "",
    data: [],
  });

  const [filterParams, setFilterParams] = useState<filterParams>({
    startDate: null,
    endDate: null,
    rentalType: "RENTAL REQUESTS,ACTIVE RENTALS,PAST RENTALS",
    showSelectedType: true,
  });

  const [requestChecked, setRequestChecked] = useState<boolean>(true);
  const [activeChecked, setActiveChecked] = useState<boolean>(true);
  const [pastChecked, setPastChecked] = useState<boolean>(true);
  const [expiredChecked, setExpiredCheck] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState([]);

  const [extendRentalEquipment, setExtendRentalEquipment] = useState<any>([]);
  const [requestLoadMore, setRequestLoadMore] = useState<boolean>(false);
  const [activeLoadMore, setActiveLoadMore] = useState<boolean>(false);
  const [pastLoadMore, setPastLoadMore] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");
  const [detailModalData, setDetailModalData] = useState<any>([]);
  const [actionType, setActionType] = useState<string>("");
  const [filterModalIsOpen, setFilterModalIsOpen] = useState<boolean>(false);
  const [detailModalIsOpen, setDetailModalIsOpen] = useState<boolean>(false);
  const [orderDetailPopup, setOrderDetailPopup] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isActiveConfirmationOpen, setIsActiveConfirmationOpen] =
    useState<boolean>(false);
  const handleDetailModalOpen = () => setDetailModalIsOpen(true);
  const handleDetailModalClose = () => setDetailModalIsOpen(false);
  const handleFilterModalOpen = () => setFilterModalIsOpen(true);
  const handleFilterModalClose = () => setFilterModalIsOpen(false);
  const handleOrderDetailPopupClose = () => setOrderDetailPopup(false);
  const handleOrderDetailPopupOpen = () => setOrderDetailPopup(true);
  const handleConfirmationOpen = () => setIsConfirmationOpen(true);
  const handleConfirmationClose = () => setIsConfirmationOpen(false);
  const handleActiveConfirmationOpen = () => setIsActiveConfirmationOpen(true);
  const handleActiveConfirmationClose = () =>
    setIsActiveConfirmationOpen(false);

  const [
    isRequestDetailsConfirmationOpen,
    setIsRequestDetailsConfirmationOpen,
  ] = useState<boolean>(false);
  const handleRequestDetailsConfirmationClose = () =>
    setIsRequestDetailsConfirmationOpen(false);
  const handleRequestDetailsConfirmationOpen = () =>
    setIsRequestDetailsConfirmationOpen(true);

  const [requestPageNo, setRequestPageNo] = useState<number>(1);
  const [activePageNo, setActivePageNo] = useState<number>(1);
  const [pastPageNo, setPastPageNo] = useState<number>(1);

  const [defaultFilterApply, setDefaultFilterApply] = useState(false);

  const socketFilterRef = useRef({
    ...filterParams,
    showSelectedType: defaultFilterApply,
    orderId: equipOrOrderId,
    searchKeyword: keyword,
  });

  const equipMentName = useRef("Unknown");

  const fetchData = async ({
    userId,
    rentalType = "RENTAL REQUESTS,ACTIVE RENTALS,PAST RENTALS",
    pageNo = 0,
    startDate = null,
    endDate = null,
    searchKeyword = "",
    showSelectedType = false,
    clearFilter = false,
    stopLoader = false,
    orderId = null,
  }) => {
    try {
      if (!pageNo && !stopLoader) {
        setLoading(true);
      }
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = { user_id: userId.toString() };
      params["filter"] = true;
      // console.log(
      //   "equipOrOrderId",
      //   orderId,
      //   "---",
      //   equipOrOrderId,
      //   urlParamsFilterType,
      //   isActive
      // );
      if (orderId) {
        params[
          `${urlParamsFilterType === "advance" ? "order_id" : "equip_id"}`
        ] = orderId;
        params["is_active"] = isActive;
      }
      if (rentalType) {
        params["filterType"] =
          rentalType +
          (expiredChecked && !clearFilter && rentalType?.includes("REQUESTS")
            ? ",EXPIRED RENTAL"
            : "");
      }
      if (startDate && endDate) {
        params["startDate"] = startDate;
        params["endDate"] = endDate;
      }
      if (searchKeyword) {
        params["searchKeyword"] = searchKeyword.trim();
      } else if (keyword) {
        params["searchKeyword"] = keyword.trim();
      }
      if (pageNo) {
        params["page"] = pageNo;
        params["type"] = rentalType;
      }
      console.log("params to fetch", params);
      const response = await EquipmentAPIs.getOrdersData(params, headers);
      if (response["type"] === "RXSUCCESS") {
        console.log(response.data);
        if (
          rentalType === "ACTIVE RENTALS" &&
          pageNo &&
          response?.data?.length
        ) {
          const combinedData = [...activeRental.data, ...response.data];
          setActiveRental((prev) => ({ ...prev, data: combinedData }));
        } else if (
          rentalType === "PAST RENTALS" &&
          pageNo &&
          response?.data?.length
        ) {
          const combinedData = [...pastRental.data, ...response.data];
          setPastRental((prev) => ({ ...prev, data: combinedData }));
        } else if (
          rentalType === "RENTAL REQUESTS" &&
          pageNo &&
          response?.data?.length
        ) {
          const combinedData = [...rentalRequest.data, ...response.data];
          setRentalRequest((prev) => ({ ...prev, data: combinedData }));
        } else {
          setActivePageNo(1);
          setPastPageNo(1);
          setRequestPageNo(1);
          if (showSelectedType) {
            rentalType = "RENTAL REQUESTS,ACTIVE RENTALS,PAST RENTALS";
          }
          if (rentalType.includes("REQUESTS")) {
            const filteredData = response?.data?.find((ele: filterData) =>
              ele?.title.includes("REQUESTS")
            );
            setRentalRequest(filteredData ?? defaultParams);
          }
          if (rentalType.includes("ACTIVE")) {
            const filteredData = response?.data?.find((ele: filterData) =>
              ele?.title.includes("ACTIVE")
            );
            setActiveRental(filteredData ?? defaultParams);
          }
          if (rentalType.includes("PAST")) {
            const filteredData = response?.data?.find((ele: filterData) =>
              ele?.title.includes("PAST")
            );
            setPastRental(filteredData ?? defaultParams);
          }
        }
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
      setRequestLoadMore(false);
      setActiveLoadMore(false);
      setPastLoadMore(false);
    }
  };

  const fetchRentedEquipment = async () => {
    try {
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const EquipData = await EquipmentAPIs.GetRentedEquip(
        {
          user_id: AuthUserId.toString(),
        },
        headers
      );
      if (EquipData["type"] == "RXSUCCESS") {
        setExtendRentalEquipment(EquipData?.new_data);
      }
    } catch (error) {
      console.log("error fetching rented equipment", error);
    }
  };

  const handleDetailModalData = (orderObj: RentalItem, filterFrom: string) => {
    const modalData = [];
    if (filterFrom === "request") {
      modalData.push({
        ...orderObj,
        showBtn: AuthUserId === orderObj?.owner_id,
      });
    } else {
      modalData.push({
        ...orderObj,
        side: AuthUserId === orderObj?.owner_id ? "owner" : "renter",
      });
    }
    setDetailModalData(modalData);
  };

  const handleNotificationRedirection = (messageObj: RentalItem) => {
    const {
      equip_id: equipId,
      owner_id,
      renter_id,
      owner = "Unknown",
      renter = "Unknown",
      make: EquipMake,
      model: EquipModel,
    } = messageObj;
    const reciverName = AuthUserId === owner_id ? renter : owner;
    const reciverID = AuthUserId === owner_id ? renter_id : owner_id;
    const ChatObj = {
      equipId,
      senderID: AuthUserId,
      reciverID,
      reciverName,
      EquipMake,
      EquipModel,
    };
    localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
    // setActiveStep(5);
    // reloadTabUpdate();
    window.location.href = "/notifications";
  };

  const updateRentalRequestStatus = async (
    rentalObj: updateRentalRequestParams[],
    statusType: string
  ) => {
    try {
      setLoading(true);
      const { request_id, order_id } = rentalObj[0];
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = {
        order_request_id: request_id,
        status: statusType === "accept" ? "1" : "2",
      };
      const response = await EquipmentAPIs.UpdateRequest(params, headers);
      if (response["type"] === "RXSUCCESS") {
        dispatch(setalertMessage("Rental request updated successfully."));
        dispatch(setalertMessagetype("success"));
        let requestData = {
          userId: AuthUserId,
          ...socketFilterRef.current,
        };
        if (statusType === "accept") {
          if (!defaultFilterApply) {
            requestData = {
              ...requestData,
              rentalType: "RENTAL REQUESTS,ACTIVE RENTALS",
            };
          }
          await fetchData(requestData);
        } else if (statusType === "reject") {
          if (!defaultFilterApply) {
            requestData = { ...requestData, rentalType: "RENTAL REQUESTS" };
          }
          await fetchData(requestData);
        }
        setDetailModalData((prev: any) => ({
          ...prev,
          rentalRequestStatus:
            statusType === "accept" ? "Accepted" : "Rejected",
        }));
        handleConfirmationClose();
        handleRequestDetailsConfirmationClose();
        await fetchRentedEquipment();
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const updateActiveRentalStatus = async (
    rentalObj: activeRentalParams[],
    statusType: string
  ) => {
    try {
      setLoading(true);
      const { order_id, renter_id } = rentalObj[0];
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = {
        user_id: AuthUserId,
        order_id: order_id.toString(),
        owner_id: AuthUserId.toString(),
        renter_id: renter_id.toString(),
        title: statusType,
      };
      const response = await EquipmentAPIs.UpdateOrderStatus(params, headers);
      if (response["type"] === "RXSUCCESS") {
        const updatedData = activeRental.data.map((item) => {
          if (item.order_id === order_id) {
            let status = item?.order_status;
            if (statusType === "Pickup") {
              status = "pickup";
            } else if (statusType === "Return") {
              let requestData = {
                userId: AuthUserId,
                ...socketFilterRef.current,
              };
              if (!defaultFilterApply) {
                requestData = { ...requestData, rentalType: "RENTAL REQUESTS" };
              }
              fetchData(requestData);
              status = "returned";
            }
            return { ...item, order_status: status };
          }
          return item;
        });
        handleActiveConfirmationClose();
        setActiveRental((prev) => ({ ...prev, data: updatedData }));
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const cancelRentalRequest = async (rentalObj: cancelOrderParams[]) => {
    try {
      setLoading(true);
      const { order_id } = rentalObj[0];
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = {
        order_id: order_id?.toString(),
        user_id: AuthUserId.toString(),
        reason: "Cancelled by renter",
      };
      const response = await EquipmentAPIs.CancelOrder(params, headers);
      if (response["type"] === "RXSUCCESS") {
        dispatch(setalertMessage("Rental request cancelled successfully."));
        dispatch(setalertMessagetype("success"));
        handleConfirmationClose();
        setDetailModalData((prev: any) => ({
          ...prev,
          rentalRequestStatus: "Cancelled",
        }));
        handleRequestDetailsConfirmationClose();
        let requestData = {
          userId: AuthUserId,
          ...socketFilterRef.current,
        };
        if (!defaultFilterApply) {
          requestData = { ...requestData, rentalType: "RENTAL REQUESTS" };
        }
        await fetchData(requestData);
        await fetchRentedEquipment();
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const handleModalConfirmation = () => {
    // console.log("confirmation for", actionType);
    // console.log("confirmation object", detailModalData);
    if (actionType === "cancel") {
      cancelRentalRequest(detailModalData);
    } else if (actionType === "accept" || actionType === "reject") {
      updateRentalRequestStatus(detailModalData, actionType);
    } else if (actionType === "Pickup" || actionType === "Return") {
      updateActiveRentalStatus(detailModalData, actionType);
    }
  };

  const handleSecurityCompletion = async () => {
    let requestData = {
      userId: AuthUserId,
      ...socketFilterRef.current,
    };
    if (!defaultFilterApply) {
      requestData = {
        ...requestData,
        rentalType: "RENTAL REQUESTS,ACTIVE RENTALS,PAST RENTALS",
      };
    }
    await fetchData(requestData);
  };

  const handlePagination = async (paginationType: string, pageNo: number) => {
    const lowerPaginationType = paginationType.toLocaleLowerCase();
    if (lowerPaginationType.includes("past")) {
      setPastLoadMore(true);
    } else if (lowerPaginationType.includes("active")) {
      setActiveLoadMore(true);
    } else if (lowerPaginationType.includes("request")) {
      setRequestLoadMore(true);
    }
    await fetchData({ userId: AuthUserId, rentalType: paginationType, pageNo });
  };

  const handleKeywordSearch = async (defaultKeyword = false) => {
    if (equipOrOrderId) {
      navigate("/equipmentOrders", { replace: true });
    }
    await fetchData({
      userId: AuthUserId,
      searchKeyword: defaultKeyword ? " " : keyword,
      ...filterParams,
    });
  };

  const handleKeywordChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setKeyword(event.target.value);
    if (event.target.value === "") {
      await handleKeywordSearch(true);
    }
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && keyword?.trim()) {
      // api call
      if (equipOrOrderId) {
        navigate("/equipmentOrders", { replace: true });
      }
      await fetchData({
        userId: AuthUserId,
        searchKeyword: keyword,
        ...filterParams,
      });
    }
  };

  const handleFilterSearch = async (params: filterParams) => {
    if (equipOrOrderId) {
      navigate("/equipmentOrders", { replace: true });
    }
    const newParams = { showSelectedType: true, ...params };
    setFilterParams(newParams);
    setDefaultFilterApply(true);
    await fetchData({
      userId: AuthUserId,
      ...newParams,
      searchKeyword: keyword,
    });
    handleFilterModalClose();
  };

  const onExtendConfirmation = async () => {
    dispatch(setalertMessage("Order extented successfully."));
    dispatch(setalertMessagetype("success"));
    let requestData = {
      userId: AuthUserId,
      ...socketFilterRef.current,
    };
    if (!defaultFilterApply) {
      requestData = { ...requestData, rentalType: "RENTAL REQUESTS" };
    }
    await fetchData(requestData);
    await fetchRentedEquipment();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleMapRedirection = function (element: RentalItem) {
    const sourceLat = Number(AuthProfile?.["lat"]);
    const sourceLng = Number(AuthProfile?.["lng"]);
    let destinationLat = 0;
    let destinationLng = 0;
    if (element?.delivery_required === "1") {
      destinationLat = Number(element?.delivery_lat);
      destinationLng = Number(element?.delivery_lng);
    } else {
      destinationLat = Number(element?.equip_lat);
      destinationLng = Number(element?.equip_lng);
    }
    if (
      !isNaN(sourceLat) &&
      !isNaN(sourceLng) &&
      !isNaN(destinationLat) &&
      !isNaN(destinationLng)
    ) {
      const google_map = `https://www.google.com/maps/dir/?api=1&origin=${sourceLat},${sourceLng}&destination=${destinationLat},${destinationLng}`;
      window.open(google_map, "_blank");
    }
  };

  const handleRentalYardRedirection = function (equipId: number) {
    // console.log("handleRentalYardRedirection", equipId);
    if (equipId) {
      navigate(`/myRentalYard/${AuthUserId}/${equipId}`);
      // setActiveStep(1);
      // reloadTabUpdate();
    }
  };

  const handleOrderCancelConfirmation = async (order: {
    orderType: string;
    orderId: string;
  }) => {
    await fetchRentedEquipment();
    if (order?.orderType === "main") {
      const modifiedActiveRentals = activeRental?.data?.filter(
        (rental) => rental?.order_id !== parseInt(order?.orderId)
      );
      if (
        JSON.stringify(activeRental?.data) !==
        JSON.stringify(modifiedActiveRentals)
      ) {
        setActiveRental((prev) => ({ ...prev, data: modifiedActiveRentals }));
      }
    }
  };

  const handleClearFilter = async (type: string) => {
    const newParams = { ...filterParams };
    const lowerType = type?.toLowerCase();
    const splitedArray = newParams?.rentalType?.split(",");
    if (lowerType === "date") {
      newParams.startDate = null;
      newParams.endDate = null;
      setDateRange([]);
    } else if (lowerType?.includes("requests")) {
      setRequestChecked(false);
      newParams.rentalType = splitedArray
        ?.filter((ele) => ele !== "RENTAL REQUESTS")
        ?.join(",");
    } else if (lowerType?.includes("active")) {
      setActiveChecked(false);
      newParams.rentalType = splitedArray
        ?.filter((ele) => ele !== "ACTIVE RENTALS")
        ?.join(",");
    } else if (lowerType?.includes("past")) {
      setPastChecked(false);
      newParams.rentalType = splitedArray
        ?.filter((ele) => ele !== "PAST RENTALS")
        ?.join(",");
    } else if (lowerType?.includes("expired")) {
      setExpiredCheck(false);
      newParams.rentalType = splitedArray
        ?.filter((ele) => ele !== "EXPIRED RENTAL")
        ?.join(",");
    } else if (lowerType?.includes?.("single")) {
      setRequestChecked(true);
      setActiveChecked(true);
      setPastChecked(true);
      setDefaultFilterApply(false);
      newParams.rentalType = "RENTAL REQUESTS,ACTIVE RENTALS,PAST RENTALS";
    }
    setFilterParams(newParams);
    await fetchData({
      userId: AuthUserId,
      ...newParams,
      searchKeyword: keyword,
      clearFilter: true,
    });
  };

  const handleClearHistory = async () => {
    navigate("/equipmentOrders", { replace: true });
    // await fetchData({
    //   userId: AuthUserId,
    // });
  };

  const getMakeString = (data: any) => {
    // console.log("getMakeString", data);
    const make = data?.[0]?.make || data?.[0]?.modal;
    equipMentName.current = make;
    return make.length > 20 ? make.slice(0, 21) : make;
  };

  function disconnectSocket() {
    if (socket) {
      socket.disconnect();
      socket = undefined;
    }
  }

  const handleSocketData = async (data: socketParams) => {
    const requestObject = {
      userId: AuthUserId,
      stopLoader: true,
      ...socketFilterRef.current,
    };
    // console.log("socketFilterRef",socketFilterRef);
    if (equipOrOrderId) {
      requestObject["orderId"] = equipOrOrderId;
    }
    console.log("handleSocketData", requestObject);
    await fetchData(requestObject);
    await fetchRentedEquipment();
  };

  useEffect(() => {
    let valueToSet = !isNaN(parseFloat(equipOrOrdercheck))
      ? parseInt(equipOrOrdercheck)
      : null;
    setEquipOrOrderId(valueToSet);
  }, [equipOrOrdercheck]);

  useEffect(() => {
    if (AuthUserId) {
      fetchData({
        userId: AuthUserId,
        ...filterParams,
        orderId: equipOrOrdercheck,
      });
      fetchRentedEquipment();
    }
  }, [AuthUserId, AuthToken, equipOrOrdercheck]);

  useEffect(() => {
    socketFilterRef.current = {
      ...filterParams,
      orderId: equipOrOrderId,
      showSelectedType: defaultFilterApply,
      searchKeyword: keyword,
    };
  }, [filterParams, equipOrOrderId, defaultFilterApply, keyword]);

  useEffect(() => {
    if (AuthUserId) {
      socket = io(SOCKET_URL, {
        query: {
          from: "web",
          user_id: AuthUserId,
        },
      });
      socket.on("connect", () => {
        console.log("connected id", socket?.id);
      });
      socket.on("updateOrderTab", async (data: socketParams) => {
        await handleSocketData(data);
      });
    }
    window.addEventListener("beforeunload", (e) => {
      disconnectSocket();
    });
    return () => {
      disconnectSocket();
    };
  }, [AuthUserId]);

  // console.log("updated order Id ",equipOrOrderId)

  const filtredRentalType = filterParams?.rentalType?.split(",");

  return (
    <MainLayout>
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            {/* <CircularProgress color="inherit" /> */}
            <Typography variant="h5">Loading Your Orders...</Typography>
          </Backdrop>
        </Box>
      )}
      <Container>
        <Box>
          {/* Heading */}
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#fff !important",
                fontFamily: "GothamBold !important",
                mb: 1,
                fontSize: {
                  xs: "20px",
                  sm: "26px",
                  md: "32px",
                  lg: "36px",
                  xl: "40px",
                },
              }}
            >
              My Orders
            </Typography>
          </Box>
          {/* Search and Filter */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Box
              sx={{
                width: "400px",
                padding: "0.5rem",
                borderRadius: "10px",
                border: "3px solid #faa61a",
                background: "#fff",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <TextField
                placeholder="Search Orders"
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiInputBase-input": {
                    border: "none",
                    backgroundColor: "transparent",
                    padding: "5px 0px 5px 15px",
                    fontFamily: "Montserrat !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    display: "none",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#000",
                  },
                }}
                value={keyword}
                onKeyDown={handleKeyDown}
                onChange={handleKeywordChange}
              />
              <Button
                variant="contained"
                sx={{
                  background: "#008001",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat !important",
                  "&:hover": {
                    background: "#006401",
                  },
                }}
                onClick={() => handleKeywordSearch()}
              >
                Search
              </Button>
            </Box>
            <Box
              component={"img"}
              src={filterIcon}
              sx={{
                width: {
                  xs: "25px",
                  sm: "30px",
                  md: "35px",
                  lg: "40px",
                  xl: "45px",
                },
                cursor: "pointer",
              }}
              onClick={handleFilterModalOpen}
            />
          </Box>
          {/* filtered element*/}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              flexWrap: "wrap",
              mt: 2,
              mb: 2,
            }}
          >
            {urlParamsFilterType === "history" &&
              pastRental?.data?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    background: "#faa61a",
                    borderRadius: "5px",
                    width: "fit-content",
                    padding: "5px 10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "500",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    {pastRental?.data?.length > 0 &&
                      getMakeString(pastRental?.data)}
                  </Typography>
                  <ClearIcon
                    sx={{ cursor: "pointer", color: "#fff !important" }}
                    onClick={handleClearHistory}
                  />
                </Box>
              )}
            {urlParamsFilterType === "active" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  background: "#faa61a",
                  borderRadius: "5px",
                  width: "fit-content",
                  padding: "5px 10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "500",
                    fontFamily: "Montserrat !important",
                  }}
                >
                  {(activeRental?.data?.length > 0 &&
                    getMakeString(activeRental?.data)) ||
                    (rentalRequest?.data?.length > 0 &&
                      getMakeString(rentalRequest?.data)) ||
                    equipMentName.current}
                </Typography>
                <ClearIcon
                  sx={{ cursor: "pointer", color: "#fff !important" }}
                  onClick={handleClearHistory}
                />
              </Box>
            )}
            {urlParamsFilterType === "advance" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  background: "#faa61a",
                  borderRadius: "5px",
                  width: "fit-content",
                  padding: "5px 10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "500",
                    fontFamily: "Montserrat !important",
                  }}
                >
                  {"Order ID:"}
                  {equipOrOrderId}
                </Typography>
                <ClearIcon
                  sx={{ cursor: "pointer", color: "#fff !important" }}
                  onClick={handleClearHistory}
                />
              </Box>
            )}
            {defaultFilterApply && filtredRentalType?.length > 1 ? (
              filtredRentalType.map((element) => (
                <Box
                  key={element}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    background: "#faa61a",
                    borderRadius: "5px",
                    width: "fit-content",
                    padding: "5px 10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "500",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    {element}
                  </Typography>
                  <ClearIcon
                    sx={{ cursor: "pointer", color: "#fff !important" }}
                    onClick={() => handleClearFilter(element)}
                  />
                </Box>
              ))
            ) : defaultFilterApply && filtredRentalType?.length === 1 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  background: "#faa61a",
                  borderRadius: "5px",
                  width: "fit-content",
                  padding: "5px 10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "500",
                    fontFamily: "Montserrat !important",
                  }}
                >
                  {filtredRentalType[0]}
                </Typography>
                <ClearIcon
                  sx={{ cursor: "pointer", color: "#fff !important" }}
                  onClick={() => handleClearFilter("single")}
                />
              </Box>
            ) : (
              <></>
            )}
            {filterParams?.startDate && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  background: "#faa61a",
                  borderRadius: "5px",
                  width: "fit-content",
                  padding: "5px 10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "500",
                    fontFamily: "Montserrat !important",
                  }}
                >
                  Date Range{" "}
                  {filterParams?.startDate + "--" + filterParams?.endDate}
                </Typography>
                <ClearIcon
                  sx={{ cursor: "pointer", color: "#fff !important" }}
                  onClick={() => handleClearFilter("date")}
                />
              </Box>
            )}
          </Box>
          {!loading &&
            rentalRequest?.data?.length === 0 &&
            activeRental?.data?.length === 0 &&
            pastRental?.data?.length === 0 && (
              <Box
                sx={{
                  width: "100%",
                  padding: "2rem 1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "18px",
                    },
                    fontFamily: "Montserrat !important",
                    fontWeight: "800",
                  }}
                >
                  No Records Found!
                </Typography>
              </Box>
            )}
          <RentalRequests
            handleNotificationRedirection={handleNotificationRedirection}
            handleDetailModalData={handleDetailModalData}
            handleDetailModalOpen={handleDetailModalOpen}
            rentalRequestData={rentalRequest}
            handleModalConfirmation={handleModalConfirmation}
            setActionType={setActionType}
            loading={loading}
            isConfirmationOpen={isConfirmationOpen}
            handleConfirmationOpen={handleConfirmationOpen}
            handleConfirmationClose={handleConfirmationClose}
            handlePagination={handlePagination}
            loadMore={requestLoadMore}
            handleMapRedirection={handleMapRedirection}
            pageNo={requestPageNo}
            setPageNo={setRequestPageNo}
            handleRentalYardRedirection={handleRentalYardRedirection}
          />

          {/* ACTIVE RENTALS */}
          <ActiveRentals
            handleNotificationRedirection={handleNotificationRedirection}
            handleDetailModalData={handleDetailModalData}
            handleDetailModalOpen={handleOrderDetailPopupOpen}
            activeRentalsData={activeRental}
            handleModalConfirmation={handleModalConfirmation}
            setActionType={setActionType}
            loading={loading}
            isConfirmationOpen={isActiveConfirmationOpen}
            handleConfirmationOpen={handleActiveConfirmationOpen}
            handleConfirmationClose={handleActiveConfirmationClose}
            handleSecurityCompletion={handleSecurityCompletion}
            handlePagination={handlePagination}
            onExtendConfirmation={onExtendConfirmation}
            loadMore={activeLoadMore}
            handleMapRedirection={handleMapRedirection}
            extendRentalEquipment={extendRentalEquipment}
            pageNo={activePageNo}
            setPageNo={setActivePageNo}
            handleRentalYardRedirection={handleRentalYardRedirection}
          />

          {/* PAST RENTALS */}
          <PastRentals
            handleDetailModalData={handleDetailModalData}
            handleDetailModalOpen={handleOrderDetailPopupOpen}
            pastRentalsData={pastRental}
            handlePagination={handlePagination}
            pageNo={pastPageNo}
            setPageNo={setPastPageNo}
            loadMore={pastLoadMore}
            handleRentalYardRedirection={handleRentalYardRedirection}
          />
        </Box>
        <EquipmentFilter
          filterModalIsOpen={filterModalIsOpen}
          handleFilterModalClose={handleFilterModalClose}
          handleFilterSearch={handleFilterSearch}
          requestChecked={requestChecked}
          setRequestChecked={setRequestChecked}
          activeChecked={activeChecked}
          setActiveChecked={setActiveChecked}
          pastChecked={pastChecked}
          setPastChecked={setPastChecked}
          expiredChecked={expiredChecked}
          setExpiredCheck={setExpiredCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        <RentalDetailPopup
          detailModalData={detailModalData}
          detailModalIsOpen={detailModalIsOpen}
          handleDetailModalClose={handleDetailModalClose}
          handleNotificationRedirection={handleNotificationRedirection}
          setActionType={setActionType}
          handleModalConfirmation={handleModalConfirmation}
          isConfirmationOpen={isRequestDetailsConfirmationOpen}
          handleConfirmationClose={handleRequestDetailsConfirmationClose}
          handleConfirmationOpen={handleRequestDetailsConfirmationOpen}
          handleMapRedirection={handleMapRedirection}
          loading={loading}
        />
        <OrderDetailPopup
          detailModalData={detailModalData}
          orderDetailPopup={orderDetailPopup}
          handleOrderDetailPopupClose={handleOrderDetailPopupClose}
          onCancelConfirmation={handleOrderCancelConfirmation}
        />
      </Container>
    </MainLayout>
  );
};

export default EquipmentOrders;
