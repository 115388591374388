import { Backdrop, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, Link, Modal, TextField, Typography, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { EquipmentAPIs } from "../../../service/api-service";
import { IApiResponseRawData, IStoreValue } from 'utils/types';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage, setWalletBalance } from 'redux/redux-slice';

import AddBalance from "../../../components/AddBalance/AddBalance";


import addImg from "assets/images/details-page/Add.png";
import cardImgs from "assets/images/buildacrew/cqrd.png"
import dollarImg from "assets/images/go-dollar.png"
import welcomeImg from "assets/images/buildacrew/welcome-img.png"
import ProfilePic from "assets/images/dummy-profile-pic.jpeg";

// stripeCode
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import styled from '@emotion/styled';
import NumericInput from 'material-ui-numeric-input';

import { checkCardExpiry } from 'utils/commonFxns';

let STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
let type = process.env.REACT_APP_PLATFORM;
if (type == "Live") {
    STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY_LIVE;
}

const stripePromise = loadStripe(STRIPE_KEY);

const NewCssTextField = styled(TextField)({
      "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl" : {
    fontFamily:'Gotham !important'
  },
    "& .MuiInput-underline:after": {
        borderBottom: "0 !important",
        borderBottomColor: "transparent !important"
    },
    "& .MuiInput-underline:before": {
        borderBottom: "0 !important",
        borderBottomColor: "transparent !important"
    },
    "& .MuiFilledInput-underline:after": {
        borderBottom: "0 !important",
        borderBottomColor: "transparent !important"
    },
    "& .MuiFilledInput-underline:before": {
        borderBottom: "0 !important",
        borderBottomColor: "transparent !important"
    },

    "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
        color: "#2d2d2d"
    },
    "& .MuiInputBase-root": {
        color: "#2d2d2d",
        background: "#fff",
        fontFamily: "Gotham !important",
        fontSize: "clamp(12px, 3vw, 15px) !important",
        borderRadius: "7px !important"
        // border: "2px solid #faa61a",
    },
    "&. MuiInputLabel-shrink": {
        color: "red !important"
    },

    "&. MuiInputLabel-shrink.Mui-focused": {
        color: "red !important"
    },

    "& label.Mui-focused": {
        color: "#2d2d2d !important"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "2px solid #faa61a",
            backgroundColor: "#fff !important"
        },
        "&:hover fieldset": {
            borderColor: "#2d2d2d",
            border: "2px solid #faa61a",
            backgroundColor: "#fff !important"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#faa61a"
        }
    },
    "& .MuiInputBase-root-MuiFilledInput-root:before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
    }
});

const NewCssNumericInput = styled(NumericInput)({
      "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl" : {
    fontFamily:'Gotham !important'
  },
    "& .MuiInput-underline:after": {
        borderBottom: "0 !important",
        borderBottomColor: "transparent !important"
    },
    "& .MuiInput-underline:before": {
        borderBottom: "0 !important",
        borderBottomColor: "transparent !important"
    },
    "& .MuiFilledInput-underline:after": {
        borderBottom: "0 !important",
        borderBottomColor: "transparent !important"
    },
    "& .MuiFilledInput-underline:before": {
        borderBottom: "0 !important",
        borderBottomColor: "transparent !important"
    },

    "& .MuiInputBase-root": {
        color: "#2d2d2d",
        background: "#fff",
        fontFamily: "Gotham !important",
        fontSize: "clamp(12px, 3vw, 15px) !important",
        borderRadius: "7px !important"
        // border: "2px solid #faa61a",
    },
    "& label.Mui-focused": {
        color: "#2d2d2d"
    },

    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "2px solid #faa61a"
        },
        "&:hover fieldset": {
            borderColor: "#2d2d2d",
            border: "2px solid #faa61a"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#faa61a"
        }
    }
});

const CrewPaymentModal = ({ crewPaymentModelOpen, setCrewPaymentModelOpen, fetchCrewListing, allObject, setReferralModalOpen }) => {

    const dispatch = useDispatch();
    const matchesMobileX = useMediaQuery('(max-width:425px)');
    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);
    const UserID = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser);
    const WalletBalance = useSelector((state: IStoreValue) => state.equipmentReducer.WalletBalance);
    const AuthProfileData = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData);
    const [loading, setLoading] = useState(false);
    // const [amountToPay, setAmountToPay] = useState((parseFloat(allObject?.setting?.admin_charge) - parseFloat(allObject?.setting?.crew_discount)));
    // const [amountToPay, setAmountToPay] = useState(2000);
    const [addBalancePopup, setAddBalancePopup] = useState(false);
    // const [discount, setDiscount] = useState(parseFloat(allObject?.setting?.crew_discount));
    const [cardPayModal, setCardPayModal] = useState(false);
    const [walletConfirmModal, setWalletConfirmModal] = useState(false);
    const [paymentDoneModal, setPaymentDoneModal] = useState(false);
    const [savedCardList, setSavedCardList] = useState([]);
    const [cardToken, setCardToken] = useState("");

    const crewPaymentApi = async () => {
        try {
            // const finalAmount = (parseFloat(allObject?.setting?.admin_charge) - parseFloat(allObject?.setting?.crew_discount));
            //    const adminCharge = parseFloat(allObject?.setting?.admin_charge);
            //     const crewDiscountPercent = parseFloat(allObject?.setting?.crew_discount);

            //     const crewDiscountDecimal = crewDiscountPercent / 100;

            //     // Subtract the percentage from the admin charge 
            //     const finalAmount = adminCharge - (adminCharge * crewDiscountDecimal);
            const finalAmount = parseFloat(allObject?.setting?.admin_charge) - (parseFloat(allObject?.setting?.admin_charge) * parseFloat(allObject?.setting?.crew_discount) / 100);

            // if (amountToPay > WalletBalance) {
            if (finalAmount > WalletBalance) {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Please add balance to proceed"));
                return;
            };

            setLoading(true);
            // Call the API to get the service categories
            const data = {
                user_id: UserID.toString(),
                // amount: amountToPay.toString()
                amount: finalAmount.toString()
            };

            console.log("data in crew payment api: ", data);
            // return;
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const crewPay = await EquipmentAPIs.crewPayment(data, headers);
            console.log("crewPay api: ", crewPay);
            if (crewPay["type"] == "RXSUCCESS") {
                console.log("crewPay : ", crewPay);
                dispatch(setalertMessagetype("success"));
                dispatch(setalertMessage("Payment successfull."));
                setLoading(false);
                // setCrewPaymentModelOpen(false);
                // fetchCrewListing();
                setWalletConfirmModal(false);
                setPaymentDoneModal(true);
                getuserprofile(UserID);
            } else {
                // Display an error message if deletion fails
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage(crewPay["message"]));
                setLoading(false);
                setCrewPaymentModelOpen(false);

                // setDeletePopup(false)
            }
        } catch (err) {
            // Handle errors that occur during the deletion process
            console.log(err);
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("error in payment."));
            setLoading(false);
        }
    };

    const cardPaymentApi = async (token: string, optionalParams = { saveCheck: false, zipCode: "" }) => {
        try {
            setLoading(true);

            const finalAmount = (parseFloat(allObject?.setting?.admin_charge) - parseFloat(allObject?.setting?.crew_discount));
            // if (amountToPay > WalletBalance) {
            // if (finalAmount > WalletBalance) {
            //     dispatch(setalertMessagetype("error"));
            //     dispatch(setalertMessage("you won't have sufficent balance in your wallet. please add balance ."));
            //     return;
            // };

            // setLoading(true);
            // Call the API to get the service categories
            const data = {
                user_id: UserID.toString(),
                // amount: amountToPay.toString()
                amount: finalAmount.toString(),
                token,
                is_card_saved: optionalParams.saveCheck ?? false,
                is_saved: cardToken === "usernewcard" ? false : true,
                billing_zipcode: optionalParams.zipCode ?? "",
            };

            console.log("data in card payment api: ", data);
            // return;
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const cardPay = await EquipmentAPIs.crewCardPayment(data, headers);
            console.log("crewPay api: ", cardPay);
            if (cardPay["type"] == "RXSUCCESS") {
                console.log("crewPay : ", cardPay);
                dispatch(setalertMessagetype("success"));
                dispatch(setalertMessage("Payment successfull."));
                setLoading(false);
                // setCrewPaymentModelOpen(false);
                // getuserprofile(UserID);
                // fetchCrewListing();
                setCardPayModal(false);
                setPaymentDoneModal(true);
            } else {
                // Display an error message if deletion fails
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage(cardPay["message"]));
                setLoading(false);
                setCrewPaymentModelOpen(false);
            }
        } catch (err) {
            // Handle errors that occur during the deletion process
            console.log(err);
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("error while paying with card."));
            setLoading(false);
        }
    };

    // Get User profile data
    const getuserprofile = async (user_id) => {
        try {

            // Make an API request to fetch the user profile data
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const profileData: IApiResponseRawData = await EquipmentAPIs.getProfile(
                {
                    user_id: user_id.toString()
                },
                headers
            );

            // Check the type of response received
            if (profileData["type"] == "RXSUCCESS") {

                // Extract the user balance from the profile data
                let balance = Number(profileData["data"]["balance"]);

                // Dispatch an action to set the wallet balance in the Redux store
                dispatch(setWalletBalance(balance));
                //setWalletBalance(balance);
                // setLoadWalletBalance(false);

                // Show the payment section and hide the checkout section
                // setproceedpaysection(true);
                // setcheckoutsection(false);

            } else if (
                profileData["status"] == false &&
                profileData["slug"] == "Session Expired"
            ) {
                // Dispatch an action to set the session expiry flag
                dispatch(setSessionExpire(true));
                return false;
            }
        } catch (err) {
            // Handle errors, if any
            // setWalletBalance(0);
        }
    };

    // stripeCode
    function StripeComp() {
        const stripe = useStripe();
        const elements = useElements();
        const [error, setError] = useState(null);
        const [loadingCard, setLoadingCard] = useState(false);

        const handleSubmitStripe = async (event) => {
            console.log("in form submit");

            event.preventDefault();

            const { currentTarget } = event;
            const fD = new FormData(currentTarget);
            //added by pp on 9 apr 2024 due to credit card pay includes saved card payment options
            if (cardToken && cardToken !== "usernewcard") {
                const formData = Object.fromEntries(fD.entries());
                const hasData = Object.keys(formData).length === 0;
                if (hasData) {
                    cardPaymentApi(cardToken);
                }
                return;
            }
            // end by pp
            // let balanceamount = fD.get("amount");
            // //let balancecardholdername = fD.get("name");
            // let amount = balanceamount.toString().replace(/,/g, "");
            // // let amount = newAmount;
            // if (amount == null || amount == "") {
            //     dispatch(setalertMessagetype("error"));
            //     dispatch(setalertMessage("Please add amount to proceed"));
            //     // setloading(false);
            //     return false;
            // }
            // if (amount == "." || amount == "0") {
            //     dispatch(setalertMessagetype("error"));
            //     dispatch(setalertMessage("Please enter valid amount to proceed"));
            //     // setloading(false);
            //     return false;
            // }
            // if (!amount || parseFloat(amount) < 1) {
            //     dispatch(setalertMessagetype("error"));
            //     dispatch(setalertMessage("Amount should be greater than 1"));
            //     // setloading(false);
            //     return false;
            // }
            let balancecardholdername = fD.get("name");
            let name = balancecardholdername;
            // let name = newBalancecardholdername;
            if (name != null) {
                name = name;
                // name = name.toString();
                // name.replace(/^\s+/, '');
                // name.trim();
            }
            // console.log("name : " , name);

            if (name == null || name == "" || name.toString().trim() == "") {
                dispatch(setalertMessage("Please provide the card holder name"));
                dispatch(setalertMessagetype("error"));
                // setloading(false);
                return false;
            }

            if (!stripe || !elements) {
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage("Stripe Error in elements error"));
                // setloading(false);
                console.log("Stripe Error in elements error");
                // Stripe.js has not loaded yet. Make sure to disable form submission or show an error.
                return;
            }
            // setLoading(true);
            const result = await stripe.createToken(
                elements.getElement(CardNumberElement)
            );

            const card = elements.getElement(CardNumberElement);
            // console.log("stripe result: ", result);
            // by GD
            // saveCardCheckbox
            const saveCheck = fD.get("saveCardCheckbox") ? true : false;
            // console.log("save card checkbox:", saveCheck);
            // if (saveCheck) {
            //     setSaveCrad('yes');
            //     console.log("in save card if");

            // } else {
            //     console.log("in save card else");
            //     setSaveCrad('no');
            // }
            // setLoading(false);
            if (result.error) {
                console.log("Stripe Error:", result);
                setLoading(false);

                // setError(result.error.message);
                // setloading(false);
                dispatch(setalertMessagetype("error"));
                dispatch(setalertMessage(result.error.message));
                return false;
            } else {
                setLoading(true);
                setLoadingCard(true);
                console.log("Stripe result in success:", result);
                // cardPaymentApi(result.token.id)
                // commented above line next lines added by pp on 10 apr 2024
                const zipCode = fD.get("zipCode")?.toString();
                cardPaymentApi(result.token.id, { saveCheck, zipCode });
                // end by pp
                // addBalance(result.token.id, amount);

                // by GD 2 Feb 2024 for token save and other
                // let dataObj = {
                //   user_id: UserId.toString(),
                //   balance: amount,
                //   token: result.token.id,
                //   description: UserId + ", added_wallet",
                //   // is_card_saved: is_card_saved,
                //   is_card_saved: false,
                //   // card: card,
                //   // month: month,
                //   // year: year,
                //   // cvc: cvc,
                //   is_saved: savedToken == 'other' ? false : true
                // }
                // console.log('dataObj in stripe', dataObj);
                // addBalance(result.token.id, amount, saveCheck ? true : false);
                //console.log('result', result.token.id);
            }

        };

        const inputStyle = {
            base: {
                fontSize: "16px", // Adjust the font size
                color: "#000" // Set the text color
                // fontFamily: "Gotham",
            },
            invalid: {
                color: "#dc3545" // Change color for invalid input
            }
        };
        return (
          <>
            <form onSubmit={handleSubmitStripe}>
              <Box className="addmoney-modal-content modal-content wallet-modal-content">
                {loading && (
                  <Box>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer - 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                )}

                {/* <FormControl>
                            <NewCssNumericInput
                                prefix={"$"}
                                fullWidth
                                name="amount"
                                precision={0}
                                decimalChar="."
                                thousandChar=","
                                placeholder={"100"}
                                label="Amount"
                                //onChange={addBalanceAmount}
                                // value={balanceamount}
                                variant="filled"
                                className="popup-input"
                                InputProps={{ disableUnderline: true }}
                                inputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            sx={{ color: "fff !important" }}
                                        >
                                            $
                                        </InputAdornment>
                                    ),
                                    maxLength: 15
                                }}
                            />
                        </FormControl> */}

                {cardToken === "usernewcard" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.7rem",
                    }}
                  >
                    <FormControl>
                      <FormLabel
                        sx={{
                          mb: 0.5,
                          fontFamily: "Montserrat !important",
                          color: "#fff !important",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        Name on Card
                        <img
                          src={cardImgs}
                          alt=""
                          style={{
                            marginBottom: "5px",
                            width: "auto",
                            height: "20px",
                          }}
                        />
                      </FormLabel>
                      <TextField
                        type="text"
                        fullWidth
                        autoFocus
                        name="name"
                        id="name"
                        inputProps={{
                          maxLength: 60,
                        }}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        placeholder="Enter here"
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "7px",
                            border: "1.5px solid #faa61a",
                            backgroundColor: "white",
                            color: "#2d2d2d",

                            "&:hover": {
                              borderColor: "#faa61a",
                            },

                            "& .MuiInputBase-input": {
                              padding: "14px",
                              // height: '30px',
                              fontFamily: "Gotham !important",
                            },
                            "&:focus-within": {
                              borderColor: "#faa61a",
                            },
                          },
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        sx={{
                          fontFamily: "Montserrat !important",
                          color: "#fff !important",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        Card Number
                        {/* <img src={cardImgs} alt="" style={{ width: 'auto', height: '20px' }} /> */}
                      </FormLabel>
                      <CardNumberElement
                        className={"popup-input-strp-2 cc"}
                        options={{ style: inputStyle }}
                      />
                    </FormControl>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontFamily: "Montserrat !important",
                            color: "#fff !important",
                            fontWeight: "500",
                          }}
                        >
                          Exp. Month/Year
                        </FormLabel>
                        <CardExpiryElement
                          className={"popup-input-strp"}
                          options={{ style: inputStyle }}
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel
                          sx={{
                            fontFamily: "Montserrat !important",
                            color: "#fff !important",
                            fontWeight: "500",
                          }}
                        >
                          CVV
                        </FormLabel>
                        <CardCvcElement
                          className={"popup-input-strp cc"}
                          options={{ placeholder: "CVV", style: inputStyle }}
                        />
                      </FormControl>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <FormControl>
                        <FormLabel
                          sx={{
                            mb: 0.5,
                            fontFamily: "Montserrat !important",
                            color: "#fff !important",
                            fontWeight: "500",
                          }}
                        >
                          Billing Zip Code
                        </FormLabel>
                        <NumericInput
                          className="billing-zipcode"
                          name="zipCode"
                          precision={0}
                          decimalChar=" "
                          thousandChar=" "
                          placeholder={"XXXXX"}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ color: "fff !important" }}
                              >
                                $
                              </InputAdornment>
                            ),
                            maxLength: 10,
                          }}
                          InputLabelProps={{
                            shrink: false,
                          }}
                          sx={{
                            textAlign: "center",
                            border: "1.5px solid #faa61a",
                            borderRadius: "10px",
                            background: "#fff !important",
                            color: "#2d2d2d",
                            fontFamily: "Gotham !important",
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                )}

                {cardToken && (
                  <>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontFamily: "Montserrat !important",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "14px",
                            lg: "16px",
                            xl: "16px",
                          },
                          color: "#fff !important",
                          textAlign: "center",
                          mb: "1rem",
                          mt: "1rem",
                        }}
                      >
                        By submitting this payment, you authorize GoEquipMe to
                        charge $100 to your credit card.
                      </Typography>
                    </Box>

                    {/* by pp on 9 apr 2024 */}
                    {cardToken === "usernewcard" && (
                      <FormControl sx={{ marginBottom: "1rem" }}>
                        <FormControlLabel
                          name="saveCardCheckbox"
                          id="saveCardCheckbox"
                          control={<Checkbox />}
                          label="Save card information to pay faster next time."
                          sx={{
                            background: "#faa61a",
                            borderRadius: "8px",
                            color: "#000 !important",
                            padding: "5px 0 !important",
                            fontFamily: "Gotham !important",
                            marginLeft: "0 !important",
                            marginRight: "0 !important",
                            // "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label":
                            //   {
                            //         fontFamily: "Gotham !important",
                            //         fontSize:'14px !important'
                            //   },
                          }}
                        />
                      </FormControl>
                    )}
                    {/* end by pp */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1rem",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "70%",
                          lg: "70%",
                          xl: "70%",
                          margin: "auto",
                        },
                      }}
                    >
                      <Link
                        sx={{
                          cursor: "pointer",
                          fontSize: "20px !important",
                          textDecorationLine: "underline",
                          textDecorationColor: "#faa61a !important",
                          color: "#faa61a !important",
                          textTransform: "capitalize",
                          fontFamily: "Gotham !important",
                        }}
                        onClick={() => setCardPayModal(false)}
                      >
                        Cancel
                      </Link>
                      <Button
                        type="submit"
                        variant="contained"
                        className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
                        sx={{ textTransform: "capitalize" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </form>
          </>
        );
    }
    // useEffect(() => {
    //     setAmountToPay((parseFloat(allObject?.setting?.admin_charge) - parseFloat(allObject?.setting?.crew_discount)));

    // }, allObject);

    useEffect(() => {
        console.log("AuthDAta in crew popup:", AuthProfileData);


    }, [AuthProfileData]);


    // added by pp on 9 apr 2024 due to changes in pay with credit card has include option to save credit card information
    const getSavedCardList = async (userId: number) => {
        try {
            setLoading(true);
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const result = await EquipmentAPIs.SavedCardList(
                {
                    user_id: userId?.toString(),
                },
                headers
            );
            if (result["type"] == "RXSUCCESS") {
                const newCardObj = [
                    {
                        id: "usernewcard",
                        card: {
                            last4: "Another Card",
                            brand: "",
                        },
                    },
                ];
                let savedcards = [...result.data.data, ...newCardObj];
                if (savedcards.length === 1) {
                    setCardToken(savedcards[0].id)
                }
                savedcards = savedcards.map(cardObj => {
                    if (cardObj.card) {
                        cardObj["cardExpiry"] = checkCardExpiry(cardObj.card.exp_year, cardObj.card.exp_month);
                    }
                    return cardObj;
                })
                setSavedCardList(savedcards);
            } else {
                throw new Error(result["message"]);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            dispatch(setalertMessage(err.message));
            dispatch(setalertMessagetype("error"));
        }
    };
    useEffect(() => {
        if (UserID) {
            getSavedCardList(UserID);
        }
    }, [UserID]);

    // end by pp

    return (
      <>
        {!paymentDoneModal && (
          <Modal
            open={crewPaymentModelOpen}
            onClose={() => setCrewPaymentModelOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                outline: "none",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "#2d2d2d",
                borderRadius: "10px",
                border: "5px solid #faa61a",
                boxShadow: 24,
                p: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.5rem",
                  lg: "2rem",
                  xl: "2rem",
                },
                width: {
                  xs: "80vw",
                  sm: "80vw",
                  md: "500px",
                  lg: "500px",
                  xl: "500px",
                },
                maxWidth: "90vw",
              }}
            >
              <IconButton
                className="cross-btn"
                onClick={() => setCrewPaymentModelOpen(false)}
              >
                <CloseIcon />
              </IconButton>
              {loading && (
                <Box>
                  <Backdrop
                    sx={{
                      color: "#000",
                      zIndex: (theme) => theme.zIndex.drawer - 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              )}
              {/* <Typography variant="h4" id="modal-modal-title" sx={{ color: '#fff',fontSize: 'clamp(24px, 3vw, 32px) !important' }}>Pay<span style={{ color: '#faa61a' }}>From</span>Wallet</Typography> */}
              <Typography
                variant="h4"
                id="modal-modal-title"
                sx={{
                  color: "#fff",
                  fontSize: "clamp(24px, 3vw, 32px) !important",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <img
                  src={dollarImg}
                  alt=""
                  style={{ maxWidth: "32px", minWidth: "24px", height: "auto" }}
                />
                Payment
              </Typography>
              <Box
                sx={{
                  border: "0px solid #faa61a",
                  borderRadius: "5px",
                  padding: "0.8rem 0",
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    id="modal-modal-description"
                    sx={{ color: "#fff" }}
                  >
                    Crew Boss Annual Fee{" "}
                  </Typography>
                  <Typography sx={{ color: "#fff" }}>
                    ${allObject?.setting?.admin_charge.toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "baseline",
                      gap: "4px",
                    }}
                  >
                    Referral Discount
                    {allObject?.userInfo?.my_crew && (
                      <Typography sx={{ color: "#faa61a !important" }}>
                        (activated)
                      </Typography>
                    )}
                  </Typography>
                  <Typography sx={{ color: "#fff" }}>
                    {/* - ${allObject?.setting?.crew_discount.toFixed(2)} */}- $
                    {(
                      (parseFloat(allObject?.setting?.admin_charge) *
                        parseFloat(allObject?.setting?.crew_discount)) /
                      100
                    ).toFixed(2)}
                  </Typography>
                </Box>
                {/* below commented by pp on 17 apr 2023 due to not require to show this*/}
                {/* {(allObject?.setting?.crew_discount===0 && allObject?.userInfo?.my_crew) && (
                                <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                                    <Typography
                                        sx={{ color: '#faa61a !important',fontSize:"13px"}}
                                    >
                                    Discount is not applicable because your crew boss hasn't paid yet.
                                    </Typography>
                                </Box>
                            )} */}

                {parseFloat(allObject?.setting?.crew_discount) === 0 &&
                  allObject?.userInfo?.my_crew == "" && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "space-between",
                      }}
                    >
                      <Link
                        onClick={() => setReferralModalOpen(true)}
                        sx={{
                          color: "#faa61a !important",
                          textDecorationColor: "#faa61a !important",
                          cursor: "pointer",
                          mb: "5px",
                          pl: "1rem",
                        }}
                      >
                        Add Referral Code for Discount
                      </Link>
                    </Box>
                  )}

                <Divider
                  sx={{
                    background: "#faa61a",
                    borderColor: "#faa61a",
                    borderWidth: "1px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    id="modal-modal-description"
                    sx={{ color: "#fff", textTransform: "uppercase" }}
                  >
                    {/* Amount to Pay: {parseFloat(allObject?.setting?.admin_charge) - parseFloat(allObject?.setting?.crew_discount)} */}
                    Total
                  </Typography>
                  <Typography sx={{ color: "#fff" }}>
                    {/* ${amountToPay.toFixed(2)} */}
                    {/* ${(parseFloat(allObject?.setting?.admin_charge) - parseFloat(allObject?.setting?.crew_discount))} */}
                    $
                    {(
                      parseFloat(allObject?.setting?.admin_charge) -
                      (parseFloat(allObject?.setting?.admin_charge) *
                        parseFloat(allObject?.setting?.crew_discount)) /
                        100
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  mt: 2,
                }}
              >
                <Button
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    padding: "0.2rem 1rem",
                    width: "fit-content",
                    background: "#149247 !important",
                    color: "#fff",
                    textTransform: "capitalize",
                    fontFamily: "Montserrat !important",
                    fontWeight: "700",
                    borderRadius: "7px !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    console.log("Add payment working");
                    // crewPaymentApi();
                    // const finalAmount = (parseFloat(allObject?.setting?.admin_charge) - parseFloat(allObject?.setting?.crew_discount));
                    const finalAmount =
                      parseFloat(allObject?.setting?.admin_charge) -
                      (parseFloat(allObject?.setting?.admin_charge) *
                        parseFloat(allObject?.setting?.crew_discount)) /
                        100;
                    if (finalAmount > WalletBalance) {
                      dispatch(setalertMessagetype("error"));
                      dispatch(
                        setalertMessage("Please add balance to proceed")
                      );
                      return;
                    }
                    setWalletConfirmModal(true);
                  }}
                >
                  Pay from My Wallet
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontWeight: "700",
                  }}
                >
                  My Wallet Balance :{" "}
                  <span style={{ color: "#faa61a" }}>
                    ${WalletBalance ? WalletBalance?.toFixed(2) : 0}
                  </span>
                </Typography>
              </Box>

              <Button
                onClick={() => setCrewPaymentModelOpen(false)}
                sx={{
                  fontFamily: "Montserrat !important",
                  color: "#fff",
                  fontSize: "14px",
                  position: "absolute",
                  top: "-15px",
                  right: "-15px",
                  width: "30px !important",
                  height: "30px !important",
                  minWidth: "30px !important",
                  borderRadius: " 50%",
                  border: "2px solid #faa61a",
                  background: "#2d2d2d !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                X
              </Button>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                  mb: 4,
                }}
              >
                <Link
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                    textDecoration: "unset",
                  }}
                >
                  {/* <img src={addImg} alt="" style={{ display: 'block', width: '17px', height: '17px', objectFit: 'contain' }} /> */}
                  <Typography
                    onClick={() => setAddBalancePopup(!addBalancePopup)}
                    sx={{
                      cursor: "pointer",
                      color: "#faa61a !important",
                      textUnderlineOffset: "1px",
                      textDecorationColor: "#faa61a !important",
                      textDecorationLine: "underline",
                    }}
                  >
                    Add Money
                  </Typography>
                </Link>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  mt: 2,
                }}
              >
                <Button
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    padding: "0.2rem 1rem",
                    width: "fit-content",
                    background: "#149247 !important",
                    color: "#fff",
                    textTransform: "capitalize",
                    fontFamily: "Montserrat !important",
                    fontWeight: "700",
                    borderRadius: "7px !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    console.log("pay with credit card works");
                    setCardPayModal(true);
                  }}
                >
                  Pay with Credit Card
                </Button>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "10px" }}
              >
                <img
                  src={cardImgs}
                  alt=""
                  style={{
                    maxWidth: "120px",
                    minWidth: "80px",
                    height: "auto",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                    xl: "100%",
                    margin: "1rem auto 0 auto",
                  },
                }}
              >
                <Link
                  sx={{
                    cursor: "pointer",
                    fontFamily: "Gotham !important",
                    fontSize: "clamp(16px, 3vw ,20px) !important",
                    textDecorationLine: "underline",
                    textDecorationColor: "#faa61a !important",
                    color: "#faa61a !important",
                    textTransform: "capitalize",
                  }}
                  onClick={() => setCrewPaymentModelOpen(false)}
                >
                  Cancel
                </Link>
              </Box>
            </Box>
          </Modal>
        )}

        {/* for add money in wallet */}
        {addBalancePopup && (
          <>
            <Modal
              open={addBalancePopup}
              onClose={() => setAddBalancePopup(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                // className="download-popups"
                sx={{
                  // ...loginstyle,
                  padding: "0px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  background: "#2d2d2d",
                  bgcolor: "#2d2d2d",
                  borderRadius: "10px",
                  border: "5px solid #faa61a",
                  boxShadow: 24,
                  p: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1.5rem",
                    lg: "2rem",
                    xl: "2rem",
                  },
                  width: {
                    xs: "80vw",
                    sm: "80vw",
                    md: "500px",
                    lg: "500px",
                    xl: "500px",
                  },
                  maxWidth: "90vw",
                }}
              >
                <IconButton
                  className="cross-btn"
                  onClick={() => setAddBalancePopup(false)}
                >
                  <CloseIcon />
                </IconButton>

                <Box>
                  <Box>
                    <Box>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#faa61a",
                          marginBottom: "0.5rem",
                          fontSize: "clamp(24px, 3vw, 32px) !important",
                        }}
                      >
                        Add Funds to Wallet
                      </Typography>

                      <AddBalance
                        onSuccess={(e) => {
                          if (e == "success") {
                            // viewWalletHistory(UserId, AuthToken, 0, "new");
                            // setaddbalancebox(false);
                            // getuserprofile(user_id);
                            getuserprofile(UserID);
                            setAddBalancePopup(false);
                            // setLoadWalletBalance(false);
                            // setaddbalancebox(false);
                            // dispatch(setalertMessage(balanceresult["message"]));
                            // dispatch(setalertMessagetype("success"));
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </>
        )}

        {/* pay with credit Card */}
        {cardPayModal && (
          <>
            <Modal
              open={cardPayModal}
              onClose={() => setCardPayModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                className=""
                sx={{
                  // ...loginstyle,
                  padding: "0px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  background: "#2d2d2d",
                  bgcolor: "#2d2d2d",
                  borderRadius: "10px",
                  border: "5px solid #faa61a",
                  boxShadow: 24,
                  // p: {
                  //     xs: '1rem',
                  //     sm: '1rem',
                  //     md: '1.5rem',
                  //     lg: '2rem',
                  //     xl: '2rem',
                  // },
                  width: {
                    xs: "90vw",
                    sm: "90vw",
                    md: "500px",
                    lg: "500px",
                    xl: "500px",
                  },
                  maxWidth: "90vw",
                }}
              >
                <IconButton
                  className="cross-btn"
                  onClick={() => setCardPayModal(false)}
                >
                  <CloseIcon />
                </IconButton>

                {/* <Box className="login__main"> */}

                <Box
                  sx={{
                    overflowY: "auto",
                    maxHeight: {
                      xs: "500px",
                      sm: "600px",
                      md: "600px",
                      lg: "700px",
                      xl: "700px",
                    },
                    overflowX: "hidden",
                    p: {
                      xs: "1rem",
                      sm: "1rem",
                      md: "1.5rem",
                      lg: "2rem",
                      xl: "2rem",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#faa61a !important",
                        mb: "1rem",
                        fontSize: "clamp(24px, 3vw, 32px) !important",
                      }}
                    >
                      Credit Card Payment
                    </Typography>
                    {/* added by pp on 10 apr 2024 due to changes in pay with credit card has include option to save credit card information */}
                    <Box className="credit-box">
                      {savedCardList.length > 1 &&
                        savedCardList.map((card, index) => (
                          <Box key={index}>
                            <Box
                              sx={{
                                background: "#faa61a !important",
                                borderRadius: "10px !important",
                                boxShadow: "unset !important",
                                padding: "0 10px !important",
                                marginLeft: "0 !important",
                                marginRight: "0 !important",
                              }}
                            >
                              <div
                                style={{
                                  pointerEvents: card?.cardExpiry
                                    ? "none"
                                    : "auto",
                                  opacity: card?.cardExpiry ? 0.5 : 1,
                                }}
                              >
                                <FormControlLabel
                                  checked={cardToken === card.id}
                                  value={card.id}
                                  aria-label="Acknowledge"
                                  onClick={(event) => {
                                    setCardToken(card.id);
                                  }}
                                  control={<Checkbox />}
                                  label={
                                    card.id === "usernewcard"
                                      ? "Use Another Card"
                                      : "************" +
                                        card.card.last4 +
                                        " (" +
                                        card.card.brand +
                                        ")"
                                  }
                                  sx={{
                                    color: "#000 !important",
                                    padding: "5px 0 !important",
                                    fontFamily: "Gotham !important",
                                  }}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: "0.5rem",
                                    fontSize: "12px",
                                  }}
                                >
                                  {card?.cardExpiry
                                    ? "This card is past its expiration date"
                                    : ""}
                                </span>
                              </div>
                            </Box>
                          </Box>
                        ))}
                      <Elements stripe={stripePromise}>
                        <StripeComp />
                      </Elements>
                    </Box>
                    {/* end by pp */}
                  </Box>
                </Box>
                {/* </Box> */}
              </Box>
            </Modal>
          </>
        )}

        {/* Confirm payment With Wallet */}
        {walletConfirmModal && (
          <Modal
            open={walletConfirmModal}
            onClose={() => setWalletConfirmModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                outline: "none",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "#2d2d2d",
                borderRadius: "10px",
                border: "5px solid #faa61a",
                boxShadow: 24,
                p: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.5rem",
                  lg: "2rem",
                  xl: "2rem",
                },
                width: {
                  xs: "80vw",
                  sm: "80vw",
                  md: "500px",
                  lg: "500px",
                  xl: "500px",
                },
                maxWidth: "90vw",
              }}
            >
              <IconButton
                className="cross-btn"
                onClick={() => setWalletConfirmModal(false)}
              >
                <CloseIcon />
              </IconButton>
              {loading && (
                <Box>
                  <Backdrop
                    sx={{
                      color: "#000",
                      zIndex: (theme) => theme.zIndex.drawer - 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              )}
              <Typography
                variant="h4"
                id="modal-modal-title"
                sx={{
                  color: "#faa61a",
                  fontSize: "clamp(24px, 3vw, 32px) !important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
              >
                {/* <img src={dollarImg} alt="" style={{ maxWidth: '32px', minWidth: '24px', height: 'auto' }} /> */}
                <img
                  src={
                    AuthProfileData["profile_image"]
                      ? AuthProfileData["profile_image"]
                      : ProfilePic
                  }
                  alt=""
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    minWidth: "30px",
                    minHeight: "30px",
                    borderRadius: "50%",
                    display: "block",
                    objectFit: "cover",
                  }}
                />
                My Wallet Payment
              </Typography>
              <Box
                sx={{
                  border: "0px solid #faa61a",
                  borderRadius: "5px",
                  padding: "0.8rem 0",
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "18px",
                        lg: "20px",
                        xl: "20px",
                      },
                      color: "#fff",
                      fontWeight: "600",
                    }}
                  >
                    Crew Boss Annual Fee:
                  </Typography>
                  {/* <Typography sx={{ fontSize: { xs: '20px', sm: '20px', md: '24px', lg: '28px', xl: '28px' }, color: '#faa61a', fontFamily: 'GothamBold !important' }}>${(parseFloat(allObject?.setting?.admin_charge) - parseFloat(allObject?.setting?.crew_discount))}</Typography> */}
                  {/* above line commented and next line added by pp on 17 apr 2024 due to wrong fee calculation */}
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "20px",
                        sm: "20px",
                        md: "24px",
                        lg: "28px",
                        xl: "28px",
                      },
                      color: "#faa61a",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    $
                    {parseFloat(allObject?.setting?.admin_charge) -
                      (parseFloat(allObject?.setting?.admin_charge) *
                        parseFloat(allObject?.setting?.crew_discount)) /
                        100}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 0,
                }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontWeight: "600",
                    fontSize: {
                      xs: "13px",
                      sm: "13px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  My Wallet Balance :{" "}
                  <span style={{ color: "#faa61a" }}>
                    ${WalletBalance.toFixed(2)}
                  </span>
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontWeight: "300",
                  fontFamily: "Montserrat !important",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "14px",
                    lg: "16px",
                    xl: "16px",
                  },
                  color: "#fff !important",
                  textAlign: "center",
                  mb: "1rem",
                  mt: "1rem",
                }}
              >
                By submitting this payment, you authorize GoEquipMe to charge $
                {parseFloat(allObject?.setting?.admin_charge) -
                  (parseFloat(allObject?.setting?.admin_charge) *
                    parseFloat(allObject?.setting?.crew_discount)) /
                    100}{" "}
                from your Wallet
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  mt: 2,
                }}
              ></Box>

              <Button
                onClick={() => setWalletConfirmModal(false)}
                sx={{
                  fontFamily: "Montserrat !important",
                  color: "#fff",
                  fontSize: "14px",
                  position: "absolute",
                  top: "-15px",
                  right: "-15px",
                  width: "30px !important",
                  height: "30px !important",
                  minWidth: "30px !important",
                  borderRadius: " 50%",
                  border: "2px solid #faa61a",
                  background: "#2d2d2d !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                X
              </Button>

              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "70%",
                    lg: "70%",
                    xl: "70%",
                    margin: "auto",
                  },
                }}
              >
                <Link
                  sx={{
                    cursor: "pointer",
                    fontFamily: "Gotham !important",
                    fontSize: "clamp(16px, 3vw ,20px) !important",
                    textDecorationLine: "underline",
                    textDecorationColor: "#faa61a !important",
                    color: "#faa61a !important",
                    textTransform: "capitalize",
                  }}
                  onClick={() => setWalletConfirmModal(false)}
                >
                  Cancel
                </Link>
                <Button
                  type="submit"
                  variant="contained"
                  className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
                  onClick={() => {
                    console.log("Add payment working in second modal");
                    // const finalAmount = (parseFloat(allObject?.setting?.admin_charge) - parseFloat(allObject?.setting?.crew_discount));
                    const finalAmount =
                      parseFloat(allObject?.setting?.admin_charge) -
                      (parseFloat(allObject?.setting?.admin_charge) *
                        parseFloat(allObject?.setting?.crew_discount)) /
                        100;
                    // if (amountToPay > WalletBalance) {
                    if (finalAmount > WalletBalance) {
                      dispatch(setalertMessagetype("error"));
                      dispatch(
                        setalertMessage("Please add balance to proceed")
                      );
                      return;
                    }
                    // setCrewPaymentModelOpen(false);
                    // setWalletConfirmModal(false);
                    // setPaymentDoneModal(true);
                    crewPaymentApi();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Modal>
        )}

        {/* payment Done Modal */}
        {paymentDoneModal && (
          <Modal
            open={paymentDoneModal}
            onClose={() => {
              setPaymentDoneModal(false);
              setCrewPaymentModelOpen(false);
              fetchCrewListing();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                outline: "none",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "#2d2d2d",
                borderRadius: "10px",
                border: "5px solid #faa61a",
                boxShadow: 24,
                p: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.5rem",
                  lg: "2rem",
                  xl: "2rem",
                },
                width: {
                  xs: "80vw",
                  sm: "80vw",
                  md: "500px",
                  lg: "500px",
                  xl: "500px",
                },
                maxWidth: "90vw",
              }}
            >
              <IconButton
                className="cross-btn"
                onClick={() => {
                  setPaymentDoneModal(false);
                  fetchCrewListing();
                  setCrewPaymentModelOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
              {loading && (
                <Box>
                  <Backdrop
                    sx={{
                      color: "#000",
                      zIndex: (theme) => theme.zIndex.drawer - 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Box>
                  <img
                    src={welcomeImg}
                    alt=""
                    style={{
                      minWidth: "90px",
                      maxWidth: "120px",
                      height: "auto",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    id="modal-modal-title"
                    sx={{
                      color: "#faa61a !important",
                      fontSize: "clamp(24px, 3vw, 32px) !important",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    Welcome, Crew Boss!
                  </Typography>

                  <Typography
                    sx={{
                      mt: 1,
                      color: "#fff !important",
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "16px",
                        lg: "17px",
                        xl: "17px",
                      },
                      fontWeight: "300",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    Start building your Crew and let's earn together!
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  mt: 2,
                }}
              >
                <Button
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    padding: "0.2rem 1rem",
                    width: "fit-content",
                    background: "#149247 !important",
                    color: "#fff",
                    textTransform: "capitalize",
                    fontFamily: "Montserrat !important",
                    fontWeight: "700",
                    borderRadius: "7px !important",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    console.log("final modal working");
                    setPaymentDoneModal(false);
                    fetchCrewListing();
                    setCrewPaymentModelOpen(false);
                  }}
                >
                  Back to Build a Crew
                </Button>
              </Box>

              <Button
                onClick={() => {
                  setPaymentDoneModal(false);
                  fetchCrewListing();
                  setCrewPaymentModelOpen(false);
                }}
                sx={{
                  fontFamily: "Montserrat !important",
                  color: "#fff",
                  fontSize: "14px",
                  position: "absolute",
                  top: "-15px",
                  right: "-15px",
                  width: "30px !important",
                  height: "30px !important",
                  minWidth: "30px !important",
                  borderRadius: " 50%",
                  border: "2px solid #faa61a",
                  background: "#2d2d2d !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                X
              </Button>
            </Box>
          </Modal>
        )}
      </>
    );
};

export default CrewPaymentModal;
