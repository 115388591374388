import { Box, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Calendar } from "react-multi-date-picker";
import moment from "moment";
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const EquipmentCalender = ({
  activeEquip,
  isCalendarPopupOpen,
  handleCalenderPopupClose,
}) => {
  if (isCalendarPopupOpen) {
    const reservedStartDate = new Date(activeEquip?.start_date);
    const reservedEndDate = new Date(activeEquip?.end_date);
    const blockedDates = [];
    if (activeEquip.hasOwnProperty("block_dates")) {
      const dates = activeEquip?.block_dates?.split(",");
      if (dates?.length) {
        blockedDates.push(...dates.slice(1));
      }
    }
    const isDateInRange = (date) => {
      const currentDate = moment(date);
      const startDate = moment(reservedStartDate);
      const endDate = moment(reservedEndDate);
      return currentDate.isBetween(startDate, endDate, "day", "[]");
    };

    const isBlockedDate = (date) => {
      return blockedDates.some(
        (reservedDate) => moment(reservedDate).format("YYYY-MM-DD") === date
      );
    };

    const dayStyle = (date) => {
      const strDate = moment(date.format()).format("YYYY-MM-DD");
      if (isDateInRange(strDate)) {
        if (activeEquip?.request_status === "0") {
          return {
            style: {
              backgroundColor: "skyblue",
              color: "#fff",
            },
            disabled: true
          };
        } else {
          return {
            style: {
              backgroundColor: "#faa61a",
              color: "#fff",
            },
            disabled:true
          };
        }
      } else if (isBlockedDate(strDate)) {
        return {
          style: {
            backgroundColor: "red",
            color: "#fff",
          },
          disabled: true
        };
      }
      return { style: {},disabled:true };
    };
    return (
      <Modal
        keepMounted
        open={isCalendarPopupOpen}
        onClose={handleCalenderPopupClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton className="cross-btn" onClick={handleCalenderPopupClose}>
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form edit-eqp-inner pupup-scroll"
                style={{ display: isCalendarPopupOpen ? "block" : "none" }}
              >
                <Box className="modal-cal">
                  <Calendar
                    numberOfMonths={1}
                    disableMonthPicker
                    disableYearPicker
                    value={reservedStartDate}
                    minDate={reservedStartDate}
                    // className="twin-cal-custom-calendar"
                    mapDays={({ date }) => dayStyle(date)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default EquipmentCalender;
