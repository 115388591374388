import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Link,
  Modal,
  styled,
  TextField,
  Typography,
  Dialog,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import { FaUser, FaPhoneAlt, FaAddressCard } from "react-icons/fa";
import placeholderImg from "assets/images/placeholder.png";
import { useSelector, useDispatch } from "react-redux";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import TextNotificationPopup from "components/common/TxtNotificationPopup/TextNotificationPopup";
import { EquipmentAPIs } from "service/api-service";
import {
  setAuthProfileData,
  setalertMessage,
  setalertMessagetype,
} from "redux/redux-slice";
import FlipImageCard from "components/account/FlipImageCard";
import LicensePlaceholderFront from "assets/images/license-placeholder-front.png";
import LicensePlaceholderBack from "assets/images/license-placeholder-back.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "98%",
    sm: "400px",
    md: "500px",
    lg: "500px",
    xl: "500px",
  },
  boxSizing: "border-box",
  bgcolor: "#2e2e2e",
  border: "5px solid #faa61a",
  borderRadius: "10px",
  outline: "none !important",
  //   p: 3,
};

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const greenButton = {
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontWeight: "700",
  padding: "8px 10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "5px",
  backgroundColor: "#149247",
  "&:hover": {
    backgroundColor: "#149247",
    color: "#fff",
  },
  width: "100%",
};

const yellowButton = {
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontWeight: "700",
  padding: "8px 10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "5px",
  border: "2px solid #faa61a",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#faa61a",
  },
  width: "100%",
};

interface Verification {
  phoneNumber: boolean;
  drivingLicense: boolean;
  profilePicture: boolean;
}

const RentSteps = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const UserProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [remainingVerifications, setRemainingVerifications] =
    useState<Verification>({
      phoneNumber: true,
      drivingLicense: true,
      profilePicture: true,
    });
  const [totalSteps, setTotalSteps] = useState<number>(4);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [drivingLicenseImg, setDrivingLicenseImg] = useState<string | null>(
    null
  );
  const [uploadedLicense, setUploadedLicense] = useState<any>({
    frontUrl: "",
    backUrl: "",
  });

  const [hasUploadedLicense,setHasUploadedLicense] = useState<string>("");

  const [licenseFileObj, setLicenseFileObj] = useState<any>({
    front: null,
    back: null,
  });
  const [profileImg, setProfileImg] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isBtnDisable, setIsBtnDisable] = useState<boolean>(true);
  const [textConfirmationModal, setTextConfirmationModal] =
    useState<boolean>(false);

  const openTextConfirmationModal = () => setTextConfirmationModal(true);
  const closeTextConfirmationModal = () => setTextConfirmationModal(false);
  const updatePhoneNumber = async (textEnable = false) => {
    try {
      setLoading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = {
        user_id: AuthUserId?.toString(),
        phone_no: phoneNumber?.replace(/[^0-9]/g, ""),
        text_notification: textEnable ? "1" : "0",
      };
      const UserStatus: IApiResponseRawData = await EquipmentAPIs.UpdateProfile(
        params,
        headers
      );
      if (UserStatus["type"] === "RXERROR") {
        throw new Error(UserStatus["message"]);
      } else {
        dispatch(setalertMessage("The phone number has been added successfully."));
        dispatch(setalertMessagetype("success"));
        setIsBtnDisable(true);
        handleNext({ ...remainingVerifications, phoneNumber: false });
        // setRemainingVerifications({ ...remainingVerifications, phoneNumber: false });
      }
    } catch (err) {
      dispatch(setalertMessage(err?.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const updateDrivingLicence = async (file) => {
    try {
      setLoading(true);
      // const { front, back } = licenseFileObj;
      // if (!front && !back) {
      //   return false;
      // }
      const headers = {
        Authorization: `${AuthToken}`,
        request_from: "Web",
        "Content-Type": "multipart/form-data", // Add this to indicate you're sending form data
      };

      // Create FormData object
      const count = hasUploadedLicense?1:0;
      const formData = new FormData();
      formData.append("user_id", AuthUserId?.toString());
      formData.append("front", file);
      formData.append("count", count?.toString());

      const response = await EquipmentAPIs.updateDrivingLicense(
        formData,
        headers
      );

      if (response["type"] === "RXSUCCESS") {
        dispatch(setalertMessage("The license image has been successfully verified."));
        dispatch(setalertMessagetype("success"));
        setUploadedLicense({
          frontUrl: response["data"]["front_side"],
          backUrl: null,
        });
        setRemainingVerifications({
          ...remainingVerifications,
          drivingLicense: false,
        });
        setIsBtnDisable(false);
      } else {
        if(response["data"]?.["front_side"] && response["data"]?.["verification_status"] !== 1) {
          setUploadedLicense({
            frontUrl: response["data"]["front_side"],
            backUrl: null,
          });
          setHasUploadedLicense(response["data"]["front_side"]);
          setRemainingVerifications({
            ...remainingVerifications,
            drivingLicense: response["data"]["verification_status"]===0,
          });
          setIsBtnDisable(response["data"]["verification_status"]===0);
        }
        throw new Error(response["message"]);
      }

      // if (UserStatus["type"] === "RXERROR") {
      //   throw new Error(UserStatus["message"]);
      // } else {
      //   dispatch(setalertMessage("Profile updated successfully!"));
      //   dispatch(setalertMessagetype("success"));
      //   setDrivingLicenseImg(fileAsString);
      //   // handleNext({ ...remainingVerifications, drivingLicense: false });
      //   setRemainingVerifications({
      //     ...remainingVerifications,
      //     drivingLicense: false,
      //   });
      //   setIsBtnDisable(false);
      // }
    } catch (err) {
      dispatch(setalertMessage(err?.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const updateAuthProfileData = async () => {
    const headers = {
      Authorization: `${AuthToken}`,
      request_from: "Web",
      "Content-Type": "application/json", // Add this to indicate you're sending form data
    };
    const profileData = await EquipmentAPIs.getProfile(
      {
        user_id: AuthUserId.toString(),
      },
      headers
    );
    if (profileData["type"] === "RXSUCCESS") {
      dispatch(setAuthProfileData(profileData["data"]));
    }
  };

  const updateProfilePicture = async (file = null) => {
    try {
      if (profileImg) {
        handleClose();
        return;
      }
      if (!file) {
        throw new Error("Profile image is required.");
      }
      setLoading(true);

      const headers = {
        Authorization: `${AuthToken}`,
        request_from: "Web",
        "Content-Type": "multipart/form-data", // Add this to indicate you're sending form data
      };

      // Create FormData object
      const formData = new FormData();
      formData.append("user_id", AuthUserId?.toString());
      formData.append("new_signup", "true");
      formData.append("profile_image", file); // 'licence' is the key for the file

      const UserStatus = await EquipmentAPIs.UpdateProfileimage(
        formData,
        headers
      );

      if (UserStatus["type"] === "RXERROR") {
        throw new Error(UserStatus["message"]);
      } else {
        dispatch(setalertMessage("Profile picture uploaded successfully!"));
        dispatch(setalertMessagetype("success"));
        // handleNext({ ...remainingVerifications, profilePicture: false });
        await updateAuthProfileData();
        setRemainingVerifications({
          ...remainingVerifications,
          profilePicture: false,
        });
        // handleClose();
      }
    } catch (err) {
      dispatch(setalertMessage(err?.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setLoading(false);
    }
  };

  const handleNext = (updatedVerificationsparams) => {
    let steps;
    let increaseSteps = false;
    const updatedVerifications = updatedVerificationsparams || {
      ...remainingVerifications,
    };
    if (updatedVerifications?.phoneNumber) {
      steps = 1;
      increaseSteps = true;
    } else if (updatedVerifications?.drivingLicense) {
      steps = 2;
      increaseSteps = true;
    } else if (updatedVerifications?.profilePicture) {
      steps = 3;
      increaseSteps = true;
    } else {
      updateAuthProfileData();
      handleRentStepsClose();
    }
    if (increaseSteps && currentStep !== steps) {
      setCurrentIndex((prev) => prev + 1);
      setCurrentStep(steps);
      if (!updatedVerifications?.phoneNumber) {
        setRemainingVerifications(updatedVerifications);
      }
    }
  };

  const progress = ((currentIndex + 1) / totalSteps) * 100;

  const stepHeadings = {
    0: "Get Approved to Rent",
    1: "Phone Number",
    2: "Upload Driver License",
    3: "Upload Profile Image",
  };

  // const completeProfile = () => {
  //   handleClose();
  // };

  const imageConversion = (file: File, type: string) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (type === "Front") {
          // Type assertion to string
          // setLicenseFileObj((prev) => ({ ...prev, front: file }));
          updateDrivingLicence(file);
          setUploadedLicense((prev) => ({
            ...prev,
            frontUrl: URL.createObjectURL(file),
          }));
        } else if (type === "Back") {
          setLicenseFileObj((prev) => ({ ...prev, back: file }));
          setUploadedLicense((prev) => ({
            ...prev,
            backUrl: URL.createObjectURL(file),
          }));
        } else if (type === "profile") {
          setProfileImg(reader.result as string);
          updateProfilePicture(file);
        }
      };
      reader.readAsDataURL(file); // Read the file as a data URL (base64)
    }
  };

  const handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    imageConversion(file, "profile");
  };

  const handleLicenseChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: string
  ) => {
    const file = event.target.files?.[0];
    imageConversion(file, imageType);
  };

  const formatPhoneNumber = (value: string): string => {
    const cleaned = value.replace(/\D/g, "");

    // Format the number (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      let formatted = "";
      if (match[1]) {
        formatted += `(${match[1]}`;
      }
      if (match[2]) {
        formatted += `) ${match[2]}`;
      }
      if (match[3]) {
        formatted += `-${match[3]}`;
      }
      return formatted;
    }
    return value;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(event.target.value);
    let btn = true;
    if (formattedNumber.length === 14) {
      btn = false;
    }
    setIsBtnDisable(btn);
    setPhoneNumber(formattedNumber);
  };

  const handleRentStepsClose = () => {
    handleClose();
    setCurrentStep(0);
    setCurrentIndex(0);
    setLicenseFileObj({
      front: null,
      back: null,
    });
    setUploadedLicense({
      frontUrl: "",
      backUrl: "",
    });
    setPhoneNumber("");
    setProfileImg(null);
  };

  useEffect(() => {
    console.log("AuthProfile", UserProfileData);
    if (AuthUserId) {
      const verificationObject = {
        phoneNumber: !UserProfileData?.["phone_no"],
        drivingLicense: !UserProfileData?.["licence_pic"] || UserProfileData["dl_verified"] === 0,
        profilePicture: !UserProfileData?.["profile_image"],
      };
      const totalStepsCount = Object.values(verificationObject).reduce(
        (total, current) => {
          return total + +current;
        },
        1
      );
      setTotalSteps(totalStepsCount);
      setRemainingVerifications(verificationObject);
      // setHasUploadedLicense(UserProfileData["licence_pic"]);
    }
  }, [AuthUserId, UserProfileData]);

  const ActionSteps = [
    { label: "Continue", handler: () => handleNext(remainingVerifications) },
    {
      // label: currentIndex + 1 === totalSteps ? "Finish" : "Next",
      label:"Continue",
      handler: openTextConfirmationModal,
      disabled: isBtnDisable,
    },
    {
      // label: currentIndex + 1 === totalSteps ? "Finish" : "Next",
      label:"Continue",
      handler: () => handleNext(remainingVerifications),
      disabled: isBtnDisable,
    },
    { label: "Continue", handler: () => updateProfilePicture() },
    // { label: "Finish", handler: completeProfile },
  ];

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      keepMounted
    >
      <Box sx={style}>
        {loading && (
          <Box>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}
        <IconButton
          onClick={handleRentStepsClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: {
              xs: "5px",
              sm: "-20px",
              md: "-20px",
              lg: "-20px",
              xl: "-20px",
            },
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Heading Above Progress Bar */}
        {/* <Typography variant="h6" textAlign="center" color="#faa61a" mb={2}>
          Step {currentStep + 1} of {totalSteps}
        </Typography> */}

        <Box
          sx={{
            maxHeight: { xs: "500px", sm: "600px", md: "700px" },
            overflowY: "auto",
            p: 3,
          }}
        >
          {/* Heading Above Progress Bar */}
          <Typography
            variant="h6"
            textAlign="center"
            // color="#faa61a"
            mb={2}
            sx={{
              color: "#faa61a",
              fontFamily: "GothamBold !important",
              fontSize: {
                xs: "18px",
                sm: "20px",
                md: "22px",
                lg: "24px",
                xl: "26px",
              },
              textAlign: "center",
              fontWeight: "900",
            }}
          >
            {stepHeadings[currentStep]}
          </Typography>

          {/* Progress Bar */}
          <Box
            sx={{
              height: "10px",
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              overflow: "hidden",
              mb: 4,
            }}
          >
            <Box
              sx={{
                width: `${progress}%`,
                height: "100%",
                backgroundColor: "#149247",
                transition: "width 0.3s ease",
              }}
            />
          </Box>

          {/* Step Content Below Progress Bar */}
          <Box>
            {currentStep === 0 && (
              <Box>
                <Typography
                  sx={{
                    color: " #fff",
                    fontFamily: "Montserrat !important",
                    fontSize: "14px",
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  As this is your first time renting equipment, you'll need to
                  provide us with some information before you can complete your
                  checkout.
                </Typography>

                <Box
                  sx={{
                    mt: 4,
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    p: 2,
                    backgroundColor: "#484740",
                    borderRadius: "10px",
                  }}
                >
                  {remainingVerifications?.phoneNumber && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "1rem",
                      }}
                    >
                      <FaPhoneAlt
                        style={{ color: "#faa61a", fontSize: "35px" }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Montserrat !important",
                            fontWeight: "700",
                          }}
                        >
                          Phone Number
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Montserrat !important",
                            fontSize: "14px",
                          }}
                        >
                          You must have a valid phone so that you can get
                          notifications about this rental.
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  {remainingVerifications?.drivingLicense && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "1rem",
                      }}
                    >
                      <FaAddressCard
                        style={{ color: "#faa61a", fontSize: "35px" }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Montserrat !important",
                            fontWeight: "700",
                          }}
                        >
                          Driver's License
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Montserrat !important",
                            fontSize: "14px",
                          }}
                        >
                          You must have a valid drivers license to rent on
                          GoEquipMe.
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  {remainingVerifications?.profilePicture && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "1rem",
                        //   filter: "grayscale(0.99)",
                      }}
                    >
                      <FaUser
                        style={{
                          color: "#faa61a",
                          fontSize: "35px",
                        }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Montserrat !important",
                            fontWeight: "700",
                          }}
                        >
                          Profile Photo
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontFamily: "Montserrat !important",
                            fontSize: "14px",
                          }}
                        >
                          Upload a profile picture so that the owners know who
                          you are.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box>
                  <Typography
                    sx={{
                      color: " #fff",
                      fontFamily: "Montserrat !important",
                      fontSize: "14px",
                    }}
                  >
                    Your information is stored securely. Users only see your
                    name and profile picture. The rest stays private. Learn more
                    about GoEquipMe &nbsp;
                    <Link
                      href="/privacy_policy"
                      target="_blank"
                      sx={{
                        color: " #faa61a",
                        fontFamily: "Montserrat !important",
                        fontSize: "14px",
                        textDecorationColor: "#faa61a",
                        cursor: "pointer",
                      }}
                    >
                      privacy policy
                    </Link>
                    .
                  </Typography>
                </Box>
              </Box>
            )}
            {currentStep === 1 && (
              <Box
                sx={{
                  mt: 4,
                  mb: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  p: 2,
                  backgroundColor: "#484740",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontSize: "14px",
                      }}
                    >
                      Please enter a valid phone so that you can get
                      notifications about this rental.
                    </Typography>
                  </Box>
                </Box>

                {/* Input */}
                <CssTextField
                  id="phone-no"
                  variant="outlined"
                  placeholder="Phone number"
                  onChange={handlePhoneChange}
                  type="text"
                  sx={{ width: "100%" }}
                  inputProps={{
                    maxLength: 14,
                  }}
                  value={phoneNumber}
                />
              </Box>
            )}
            {currentStep === 2 && (
              <Box
                sx={{
                  mt: 4,
                  mb: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  p: 2,
                  backgroundColor: "#484740",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Box>
                    {/* <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontWeight: "700",
                }}
              >
                Driver's License
              </Typography> */}
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontSize: "14px",
                      }}
                    >
                      Please upload a valid drivers license to rent on
                      GoEquipMe.
                    </Typography>
                  </Box>
                </Box>

                {/* Upload Button */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gap: "1rem",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      fontFamily: "Montserrat !important",
                      fontWeight: "700",
                      //   color: "#fff",
                      //   backgroundColor: "#149247",
                      backgroundColor: "transparent",
                      color: "#faa61a",
                      border: "2px dotted #faa61a",
                      padding: ".5rem",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: "#faa61a",
                        color: "#fff",
                      },
                    }}
                  >
                    Upload front of drivers license
                    <input
                      type="file"
                      hidden
                      accept=".jpg,.jpeg,.png"
                      onChange={(event) => handleLicenseChange(event, "Front")}
                      id="licenseInputfront"
                    />
                  </Button>
                  {/* <Button
                    variant="contained"
                    component="label"
                    sx={{
                      fontFamily: "Montserrat !important",
                      fontWeight: "700",
                      //   color: "#fff",
                      //   backgroundColor: "#149247",
                      backgroundColor: "transparent",
                      color: "#faa61a",
                      border: "2px dotted #faa61a",
                      padding: ".5rem",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: "#faa61a",
                        color: "#fff",
                      },
                    }}
                  >
                    Select Back Image
                    <input
                      type="file"
                      hidden
                      accept=".jpg,.jpeg,.png"
                      onChange={(event) => handleLicenseChange(event, "Back")}
                      id="licenseInputback"
                    />
                  </Button> */}
                </Box>
                {(uploadedLicense?.frontUrl || uploadedLicense?.backUrl) && (
                  <FlipImageCard
                    frontImage={
                      uploadedLicense?.frontUrl || LicensePlaceholderFront
                    }
                    backImage={
                      uploadedLicense?.backUrl || LicensePlaceholderBack
                    }
                    modal={true}
                    // flip={uploadedLicense?.backUrl}
                  />
                )}
                {/* {uploadedLicense?.frontUrl && uploadedLicense?.backUrl && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={updateDrivingLicence}
                      sx={{
                        background: "#149247",
                        "&:hover": {
                          background: "#149247",
                        },
                      }}
                    >
                      Upload License
                    </Button>
                  </Box>
                )} */}
                {/* {drivingLicenseImg && (
                  <Box
                    component={"img"}
                    src={drivingLicenseImg || placeholderImg}
                    alt="Uploaded"
                    sx={{
                      width: "100%",
                      maxHeight: "250px",
                      border: "2px solid #faa61a",
                      borderRadius: "9px",
                    }}
                  />
                )} */}
              </Box>
            )}
            {currentStep === 3 && (
              <Box
                sx={{
                  mt: 4,
                  mb: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  p: 2,
                  backgroundColor: "#484740",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Box>
                    {/* <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontWeight: "700",
                }}
              >
                Driver's License
              </Typography> */}
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontSize: "14px",
                      }}
                    >
                      Upload a profile picture so that the owner know who you
                      are.
                    </Typography>
                  </Box>
                </Box>

                {/* Upload Button */}
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    fontFamily: "Montserrat !important",
                    fontWeight: "700",
                    //   color: "#fff",
                    //   backgroundColor: "#149247",
                    backgroundColor: "transparent",
                    color: "#faa61a",
                    border: "2px dotted #faa61a",
                    padding: "1rem",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "#faa61a",
                      color: "#fff",
                    },
                  }}
                >
                  Upload Picture
                  <input
                    type="file"
                    hidden
                    accept=".jpg,.jpeg,.png"
                    onChange={handleProfileChange}
                    id="profileInput"
                  />
                </Button>
                {profileImg && (
                  <Box
                    component={"img"}
                    src={profileImg || placeholderImg}
                    alt="Uploaded"
                    sx={{
                      width: "100%",
                      maxHeight: "250px",
                      border: "2px solid #faa61a",
                      borderRadius: "9px",
                    }}
                  />
                )}
              </Box>
            )}

            <Box
              sx={{
                width: "100%",
                mt: 4,
                mb: 1,
                display: "flex",
                gap: "2rem",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* {currentStep > 0 && (
                <Button sx={yellowButton} onClick={handleBack}>
                  Back
                </Button>
              )} */}
              <Button
                sx={greenButton}
                onClick={ActionSteps[currentStep]?.handler}
                disabled={ActionSteps[currentStep]?.disabled || false}
              >
                {ActionSteps[currentStep]?.label}
              </Button>
            </Box>
          </Box>
        </Box>
        {/* text notofication confirmation */}
        <Box className="text-notification">
          <BootstrapDialog
            //onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={textConfirmationModal}
            className="tn-popup"
          >
            <TextNotificationPopup
              onClose={(result) => {
                if (result == "Accept") {
                  updatePhoneNumber(true);
                } else {
                  updatePhoneNumber(false);
                }
                closeTextConfirmationModal();
              }}
            />
          </BootstrapDialog>
        </Box>
      </Box>
    </Modal>
  );
};

export default RentSteps;
