import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import EquipProtection from "assets/images/details-page/EquipmentProtection.png";
import CalendarModal from "./equipmentPopup/CalendarModal";
// import CalendarWithDatesModal from "./equipmentPopup/CalendarWithDatesModal";
import addImg from "assets/images/details-page/Add.png";
import moment from "moment";
import UsCurrencyFormat from "components/MyofficeTrailer/EquipmentOrders/UsCurrencyFormat";
import { useDispatch, useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import TransportOptions from "./TransportOptions";
import CloseIcon from "@mui/icons-material/Close";
import LocationIcon from "assets/images/location.png";
import GOOGLELOCATION from "../../components/equipment-list/GoogleautoComplete";
import {
  setLoginModalOpen,
  setalertMessage,
  setalertMessagetype,
} from "redux/redux-slice";
const headingStyle = {
  color: "#fff",
  fontFamily: "GothamBold !important",
  fontSize: {
    xs: "16px",
    sm: "18px",
    md: "20px",
    lg: "22px",
    xl: "24px",
  },
};

const textStyle = {
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  fontWeight:'500'
};

const greenButton = {
  background: "#149247",
  "&:hover": {
    background: "#096e32",
  },
  fontSize: {
    xs: "16px",
    sm: "18px",
    md: "20px",
    lg: "24px",
    xl: "28px",
  },
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Montserrat !important",
  padding: "0px 15px",
  fontWeight: "800",
  width: "100%",
  borderRadius: "7px",
};

const yellowButton = {
  background: "#faa61a",
  "&:hover": {
    background: "#faa61a",
  },
  fontSize: {
    xs: "14px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  color: "#000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Montserrat !important",
  padding: "0px 15px",
  fontWeight: "800",
  width: "100%",
  borderRadius: "7px",
};

const rentalFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
};

const underlineYellowText = {
  color: "#faa61a",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  textDecorationColor: "#faa61a",
  textDecoration: "underline",
};

const selectHereButton = {
  background: "#2d2d2d",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontSize: "14px",
  // border: "none",
  border: "1px solid #faa61a",
  borderRadius: "7px",
  width: "100%",
};

const flexCommon = {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
};

const underlinewhiteText = {
  color: "#fff",
  fontSize: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "15px",
    xl: "16px",
  },
  textDecorationColor: "#fff",
  textDecoration: "underline",
  fontFamily: "Montserrat !important",
  fontWeight: "600",
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  //  overflowX:'hidden',
  bgcolor: "#2e2e2e",
  border: "5px solid #faa61a",
  borderRadius: "10px",
  outline: "none !important",
  // p: 3,
  width: {
    xs: "90%",
    sm: "400px",
    md: "450px",
    lg: "550px",
    xl: "550px",
    // sm:'auto'
  },
};

const RentalSummary = ({
  timeZoneAbbr,
  equipRentedDates,
  setEquipRentedDates,
  equipRentedTimes,
  setEquipRentedTimes,
  minDateTime,
  priceDetails,
  DeliveryInstructionsval,
  DeliveryInstructionsvallength,
  DeliveryInstructions,
  calculateDistance,
  transportCharge,
  handleTransportSelection,
  chooseTransport,
  bookingSteps,
  setBookingSteps,
  FullAddress,
  deliveryError,
  equipmentData,
  submitRentalRequest,
  handleAddBalancePopupOpen,
  setCompleteProfilePopup,
  currentMonth,
  setCurrentMonth,
  calendarRef,
}) => {
  const dispatch = useDispatch();
  const WalletBalance = useSelector(
    (state: IStoreValue) => state.equipmentReducer.WalletBalance
  );
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const inRef = useRef(null);
  const searchRef = useRef("");
  const [autoCompAddress, setAutoCompAddress] = useState(
    FullAddress?.address || ""
  );
  const [open, setOpen] = useState(false);

  const [transportOption, setTransportOption] = useState<boolean>(false);
  const handleTransportOptionClose = () => setTransportOption(false);
  const handleTransportOptionOpen = () => setTransportOption(true);
  const handleOpen = () => {
    if (!isLoggedin) {
      dispatch(setLoginModalOpen(true));
      return false;
    }

    if (parseFloat(AuthProfileData?.["is_profile_completed"]) === 0) {
      setCompleteProfilePopup(true);
      return false;
    }
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // For smooth scrolling
    });
  };

  const handleRentNowBtn = () => {
    // handleTransportOptionOpen();
    // return false;
    if (!isLoggedin) {
      dispatch(setLoginModalOpen(true));
      return false;
    }

    if (parseFloat(AuthProfileData?.["is_profile_completed"]) === 0) {
      setCompleteProfilePopup(true);
      return false;
    }

    if (
      !equipRentedDates[0] ||
      !equipRentedDates[1] ||
      !equipRentedTimes?.startTime ||
      !equipRentedTimes?.endTime
    ) {
      setOpen(true);
      return false;
    }
    if (!chooseTransport) {
      handleTransportOptionOpen();
      return false;
    }

    const startDate = moment(
      `${equipRentedDates[0]} ${equipRentedTimes?.startTime}`,
      "YYYY/MM/DD HH:mm"
    );
    const endDate = moment(
      `${equipRentedDates[1]} ${equipRentedTimes?.endTime}`,
      "YYYY/MM/DD HH:mm"
    );

    if (startDate.isSame(endDate, "day") && endDate.isBefore(startDate)) {
      dispatch(
        setalertMessage("The selected pickup or drop-off time is invalid.")
      );
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (chooseTransport === "owner" && !FullAddress.address) {
      dispatch(setalertMessage("Enter delivery location to proceed."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (chooseTransport === "owner" && deliveryError) {
      dispatch(setalertMessage("Delivery is not available to your location."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (equipmentData["user_id"] === AuthUserId) {
      dispatch(setalertMessage("You Can't own equipment order ."));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    setBookingSteps(2);
    scrollToTop();
    console.log("rent row click");
  };

  const handleTransportConfirmation = () => {
    if (!chooseTransport) {
      dispatch(setalertMessage("Please select option to confirm"));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    if (chooseTransport === "owner" && !FullAddress.address) {
      dispatch(setalertMessage("Enter delivery location to proceed."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    const startDate = moment(
      `${equipRentedDates[0]} ${equipRentedTimes?.startTime}`,
      "YYYY/MM/DD HH:mm"
    );
    const endDate = moment(
      `${equipRentedDates[1]} ${equipRentedTimes?.endTime}`,
      "YYYY/MM/DD HH:mm"
    );

    if (startDate.isSame(endDate, "day") && endDate.isBefore(startDate)) {
      dispatch(
        setalertMessage("The selected pickup or drop-off time is invalid.")
      );
      dispatch(setalertMessagetype("error"));
      return false;
    }

    if (chooseTransport === "owner" && deliveryError) {
      dispatch(setalertMessage("Delivery is not available to your location."));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (equipmentData["user_id"] === AuthUserId) {
      dispatch(setalertMessage("You Can't own equipment order ."));
      dispatch(setalertMessagetype("error"));
      return false;
    }

    setBookingSteps(2);
    handleTransportOptionClose();
    scrollToTop();
  };

  return (
    <Box sx={{ border: "2px solid #faa61a", p: 4, borderRadius: "15px" }}>
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h5"
          component={"h5"}
          sx={{ ...headingStyle, mb: 2 }}
        >
          Rental Dates
        </Typography>
        <Box sx={{ ...flexCommon, flexDirection: "column", gap: "1rem" }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "0.5fr 1fr",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Typography sx={{ ...textStyle, fontSize: "clamp(12px,3vw,18px)" }}>
              Start
            </Typography>
            <Button
              // sx={
              //   bookingSteps === 2
              //     ? { ...selectHereButton, background: "none", border: "none" }
              //     : selectHereButton
              // }
              sx={{
                ...selectHereButton,
                textTransform: "capitalize",
                fontWeight: "500",
              }}
              onClick={handleOpen}
            >
              {equipRentedDates[0] && equipRentedTimes.startTime
                ? moment(
                    `${equipRentedDates[0]?.format("MM/DD/YY")} ${
                      equipRentedTimes.startTime
                    }`
                  ).format("MM/DD/YY hh:mm A") + ` (${timeZoneAbbr})`
                : "Select Here"}
            </Button>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "0.5fr 1fr",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Typography sx={{ ...textStyle, fontSize: "clamp(12px,3vw,18px)" }}>
              End
            </Typography>
            <Button
              // sx={
              //   bookingSteps === 2
              //     ? { ...selectHereButton, background: "none", border: "none" }
              //     : selectHereButton
              // }
              sx={{
                ...selectHereButton,
                textTransform: "capitalize",
                fontWeight: "500",
              }}
              onClick={handleOpen}
            >
              {equipRentedDates[1] && equipRentedTimes.endTime
                ? moment(
                    `${equipRentedDates[1]?.format("MM/DD/YY")} ${
                      equipRentedTimes.endTime
                    }`
                  ).format("MM/DD/YY hh:mm A") + ` (${timeZoneAbbr})`
                : "Select Here"}
            </Button>
          </Box>
        </Box>
      </Box>
      <Typography variant="h5" component="h5" sx={headingStyle}>
        Rental Summary
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3px",
          mt: 1.5,
          mb: 1.5,
        }}
      >
        <Box sx={rentalFlex}>
          <Typography sx={textStyle}>Rental Price</Typography>
          <Typography sx={textStyle}>
            <UsCurrencyFormat amount={priceDetails?.rentPrice || 0} />
          </Typography>
        </Box>
        <Box sx={rentalFlex}>
          <Typography sx={textStyle}>Security Deposit</Typography>
          <Typography sx={textStyle}>
            <UsCurrencyFormat amount={priceDetails?.securityDeposit || 0} />
          </Typography>
        </Box>
        <Box sx={rentalFlex}>
          <Typography sx={underlineYellowText}>EquipMe Protection</Typography>
          <Typography sx={textStyle}>
            <UsCurrencyFormat amount={priceDetails?.equipProtection || 0} />
          </Typography>
        </Box>
        <Box sx={rentalFlex}>
          <Typography sx={textStyle}>Transport</Typography>
          <Typography sx={textStyle}>
            <UsCurrencyFormat amount={priceDetails?.deliveryPrice || 0} />
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            color: "#faa61a",
            fontWeight: "900",
            fontFamily: "Montserrat !important",
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "20px",
              lg: "24px",
              xl: "28px",
            },
          }}
        >
          Total:
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "900",
            fontFamily: "Montserrat !important",
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "20px",
              lg: "24px",
              xl: "28px",
            },
          }}
        >
          <UsCurrencyFormat amount={priceDetails?.grandTotal || 0} />
        </Typography>
      </Box>
      {/* first option start*/}
      {/* {bookingSteps === 1 && ( */}
      {/* <Box sx={{ display: bookingSteps === 1 ? "block" : "none" }}> */}
      <Box>
        <Button sx={greenButton} onClick={handleRentNowBtn}>
          Rent Now
        </Button>
        <TransportOptions
          priceDetails={priceDetails}
          DeliveryInstructionsval={DeliveryInstructionsval}
          DeliveryInstructionsvallength={DeliveryInstructionsvallength}
          DeliveryInstructions={DeliveryInstructions}
          calculateDistance={calculateDistance}
          transportCharge={transportCharge}
          chooseTransport={chooseTransport}
          handleTransportSelection={handleTransportSelection}
          equipmentData={equipmentData}
          inRef={inRef}
          searchRef={searchRef}
          autoCompAddress={autoCompAddress}
          setAutoCompAddress={setAutoCompAddress}
        />
      </Box>
      {/* )} */}
      {/* first option end*/}
      {/* second options start */}
      {/* {bookingSteps === 2 && (
        <Box>
          <Box
            sx={{
              ...flexCommon,
              mt: 2,
              justifyContent: "center",
              flexDirection: "column",
              gap: "5px",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: "#faa61a",
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                },
              }}
            >
              Total Amount to Pay
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                },
              }}
            >
              <UsCurrencyFormat amount={priceDetails?.grandTotal || 0} />
            </Typography>
          </Box>
          <Button sx={yellowButton} onClick={submitRentalRequest}>
            Request Rental
          </Button>
          <Box sx={{ ...flexCommon, mt: 2, justifyContent: "center" }}>
            <Typography
              sx={{
                color: "#faa61a",
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                },
              }}
            >
              My Wallet Balance:
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "16px",
                  lg: "18px",
                  xl: "20px",
                },
              }}
            >
              <UsCurrencyFormat amount={WalletBalance || 0} />
            </Typography>
          </Box>
          <Box
            sx={{
              ...flexCommon,
              justifyContent: "center",
              mt: 2,
              gap: "0.5rem",
            }}
          >
            <Box
              component={"img"}
              src={addImg}
              alt=""
              sx={{ width: "20px", height: "20px", objectFit: "contain" }}
            />
            <Typography
              sx={underlinewhiteText}
              onClick={handleAddBalancePopupOpen}
            >
              Add Money
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                setBookingSteps(1);
              }}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      )} */}

      {/* <CalendarWithDatesModal open={open} handleClose={handleClose} /> */}
      <CalendarModal
        open={open}
        handleClose={handleClose}
        timeZoneAbbr={timeZoneAbbr}
        equipRentedDates={equipRentedDates}
        setEquipRentedDates={setEquipRentedDates}
        equipRentedTimes={equipRentedTimes}
        setEquipRentedTimes={setEquipRentedTimes}
        minDateTime={minDateTime}
        currentMonth = {currentMonth}
        setCurrentMonth = {setCurrentMonth}
        calendarRef = {calendarRef}
        handleRentNowBtn = {handleRentNowBtn}
      />

      {/* {transportOption && ( */}
      <Modal
        open={transportOption}
        onClose={handleTransportOptionClose}
        keepMounted
      >
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleTransportOptionClose}
            sx={{
              position: "absolute",
              top: "-20px",
              right: {
                xs: "-5px",
                sm: "-20px",
                md: "-20px",
                lg: "-20px",
                xl: "-20px",
              },
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ p: 4, mt:-4 }}>
            <TransportOptions
              priceDetails={priceDetails}
              DeliveryInstructionsval={DeliveryInstructionsval}
              DeliveryInstructionsvallength={DeliveryInstructionsvallength}
              DeliveryInstructions={DeliveryInstructions}
              calculateDistance={calculateDistance}
              transportCharge={transportCharge}
              chooseTransport={chooseTransport}
              handleTransportSelection={handleTransportSelection}
              equipmentData={equipmentData}
              inRef={inRef}
              searchRef={searchRef}
              autoCompAddress={autoCompAddress}
              setAutoCompAddress={setAutoCompAddress}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              <Button
                onClick={handleTransportOptionClose}
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontWeight: "600",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleTransportConfirmation}
                sx={{
                  backgroundColor: "#149247",
                  fontFamily: "Montserrat !important",
                  fontWeight: "600",
                  "&:hover": {
                    bgcolor: "#149247",
                  },
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* )} */}
      {/* second options end */}
    </Box>
  );
};

export default RentalSummary;
