// Helper to generate all time slots in 24-hour format with half-hour intervals
export function generateTimeSlots() {
  const slots = {};
  for (let hour = 0; hour < 24; hour++) {
    const hourStr = String(hour).padStart(2, "0");
    slots[`${hourStr}:00:00`] = {disable:false,status:"default"};
    slots[`${hourStr}:30:00`] = {disable:false,status:"default"};
  }
  return slots;
}

// Helper to get time slots between two times, with optional adjustments for start and end inclusivity
function getTimeSlotsBetween({
  startTime,
  endTime,
  slots,
  includeStart,
  includeEnd,
  status,
}) {
  const slotKeys = Object.keys(slots);
  let startIndex = slotKeys.indexOf(startTime) + (includeStart ? 1 : 0);
  let endIndex = slotKeys.indexOf(endTime) + (includeEnd ? 1 : 0);
  return slotKeys.slice(startIndex, endIndex);
}

// Main function to get all slots with booked status for a specific date
export function getAllSlotsForDate({
  date,
  reservedDate: bookedDates,
}) {
  const timeSlots = generateTimeSlots();

  bookedDates.forEach((booking) => {
    // const {start_date:startDate,start_time:startTime,end_date:endDate,end_time:endTime} = booking;
    const { startDate, startTime, endDate, endTime,status } = booking;
    const bookingStartDate = new Date(startDate);
    const bookingEndDate = new Date(endDate);
    const targetDate = new Date(date);

    // Case 1: Single-day booking within target date
    if (startDate === date && endDate === date) {
      const slotsForBooking = getTimeSlotsBetween({
        startTime,
        endTime,
        slots: timeSlots,
        includeStart: false,
        includeEnd: true,
        status,
      });
      slotsForBooking.forEach((slot) => {
        timeSlots[slot]["disable"] = true;
        timeSlots[slot]["status"] = status;
      });

      // Case 2: Multi-day booking, target date is the start day
    } else if (startDate === date && bookingEndDate > targetDate) {
      const slotsForBooking = getTimeSlotsBetween({
        startTime,
        endTime: "23:30:00",
        slots: timeSlots,
        includeStart: false,
        includeEnd: true,
        status,
      });
      slotsForBooking.forEach((slot) => {
        timeSlots[slot]["disable"] = true;
        timeSlots[slot]["status"] = status;
      });

      // Case 3: Multi-day booking, target date is the end day
    } else if (
      bookingEndDate.toISOString().split("T")[0] === date &&
      bookingStartDate < targetDate
    ) {
      const slotsForBooking = getTimeSlotsBetween({
        startTime: "00:00:00",
        endTime,
        slots: timeSlots,
        includeStart: false,
        includeEnd: false,
        status,
      });
      slotsForBooking.forEach((slot) => {
        timeSlots[slot]["disable"] = true;
        timeSlots[slot]["status"] = status;
      });

      // Case 4: Multi-day booking, target date is between start and end days
    } else if (bookingStartDate < targetDate && bookingEndDate > targetDate) {
      Object.keys(timeSlots).forEach((slot) => (timeSlots[slot] = {disbale:true,status}));
    }
  });

  const slotKeys = Object.keys(timeSlots);
  for (let i = 1; i < slotKeys.length - 1; i++) {
    if (
      timeSlots[slotKeys[i - 1]] === true &&
      timeSlots[slotKeys[i + 1]] === true &&
      timeSlots[slotKeys[i]] === false
    ) {
      timeSlots[slotKeys[i]] = true;
    }
  }

  return timeSlots;
}
