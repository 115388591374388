import moment from "moment";
export const newPhoneFormat = (phNumber) => {
  let rawNumber = phNumber.replace(/[^0-9]/g, "");
  // second way to format number
  if (rawNumber.length <= 3) return rawNumber;
  const segments = [
    rawNumber.slice(0, 3),
    rawNumber.slice(3, 6),
    // rawNumber.slice(6, 10)
    rawNumber.slice(6),
  ];
  return segments.filter(Boolean).join("-");
};

export function convertTimeZone(timeZone: string) {
  // Convert GMT date string to Date object
  // const nextDayDate = new Date(gmtDate);
  // nextDayDate.setDate(gmtDate.getDate() + 5);
  // Convert GMT time to MST time
  let dateTime = new Date().toLocaleString("en-US");
  if (timeZone) {
    dateTime = new Date().toLocaleString("en-US", { timeZone: timeZone });
  }
  let [currentDate, currentTimeFormat] = dateTime.split(",");
  let [currentTime, timeSign] = currentTimeFormat.trim().split(" ");
  console.log("currentDate in fxn: ", currentDate);

  let [hours, minutes] = currentTime.split(":");
  if (parseInt(minutes) > 30) {
    hours = ((parseInt(hours) % 12) + 1).toString().padStart(2, "0");
    if (hours === "12" && timeSign === "AM") {
      timeSign = "PM";
    } else if (hours === "12" && timeSign === "PM") {
      currentDate = moment(currentDate)
        .add(1, "day")
        .format("MM/DD/YYYY")
        .toString();
      timeSign = "AM";
    }
    minutes = "00";
  } else {
    minutes = "30";
  }
  // Convert hours to string and add leading zero if necessary
  const time = `${hours}:${minutes} ${timeSign}`;
  // Return the MST date and time as a string
  return {
    dateTime,
    currentDate,
    time,
    hours: parseInt(hours),
    minutes,
    timeSign,
  };
}

export function convertTimeZoneShortName(shortName: string) {
  if (!shortName) {
    return shortName;
  }
  if (shortName.includes("MDT")) {
    shortName = shortName.replace("MDT", "MST");
  } else if (shortName.includes("EDT")) {
    shortName = shortName.replace("EDT", "EST");
  } else if (shortName.includes("PDT")) {
    shortName = shortName.replace("PDT", "PST");
  } else if (shortName.includes("CDT")) {
    shortName = shortName.replace("CDT", "CST");
  }
  return shortName;
}

export function checkCardExpiry(expYear: number, expMonth: number) {
  if (!expYear || !expMonth) {
    return false;
  }
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const isExpired =
    expYear < currentYear ||
    (expYear === currentYear && expMonth < currentMonth);
  return isExpired;
}

export const getDatesInRange = (startDate, endDate) => {
  let start = new Date(startDate);
  let end = new Date(endDate);
  let dates = [];

  // Loop from start to end date
  while (start <= end) {
    let year = start.getFullYear();
    let month = String(start.getMonth() + 1).padStart(2, "0"); // Add leading zero
    let day = String(start.getDate()).padStart(2, "0"); // Add leading zero

    // Push the date in 'YYYY-MM-DD' format
    dates.push(`${year}-${month}-${day}`);

    // Move to the next day
    start.setDate(start.getDate() + 1);
  }

  return dates;
};

export const generateTimeSlots = () => {
  const timeSlots = [];
  let startTime = new Date();
  startTime.setHours(0, 0, 0, 0);

  for (let i = 0; i < 48; i++) {
    const hours = startTime.getHours().toString().padStart(2, "0");
    const minutes = startTime.getMinutes().toString().padStart(2, "0");
    timeSlots.push(`${hours}:${minutes}:00`);
    startTime.setMinutes(startTime.getMinutes() + 30);
  }

  return timeSlots;
};
