import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Backdrop,
  Link,
  Modal,
  Button,
  useMediaQuery,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Container,
} from "@mui/material";
import moment from "moment";
import calendarIcon from "assets/images/calendar-icon.png";
import chatIcon from "assets/images/chat-icon.png";
import locationIcon from "assets/images/location-white-icon.png";
import { IStoreValue, RentalItem } from "utils/types";
import { EquipmentAPIs } from "../../service/api-service";
import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  setLoginModalOpen,
  setLogInLoading,
  SetRedirectPage,
} from "redux/redux-slice";
import { useParams, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import "./styles.scss";
import CountdownTimer from "../common/CountDownTimer";
import momentTimezone from "moment-timezone";
import { convertTimeZoneShortName } from "utils/commonFxns";
import ConfirmationModal from "components/MyofficeTrailer/EquipmentOrders/ConfirmationModal";

const OrderRequestSummary = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  // Get User data using radux
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfile = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [loading, setloading] = useState<boolean>(false);
  const [OrderRequestData, setOrderRequestData] = useState(null);
  const [OwnerStatus, setOwnerStatus] = useState<boolean>(true);

  const [paymentExpiredPopup, setPaymentExpiredPopup] =
    useState<boolean>(false);
  const [requestExpireMessage, setRequestExpireMessage] = useState<string>("");
  const [requestId, setRequestId] = useState<number>(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [actionType, setActionType] = useState<string>("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const handleConfirmationOpen = () => setIsConfirmationOpen(true);
  const handleConfirmationClose = () => setIsConfirmationOpen(false);

  // Get Order request summary
  const LoadOrderRequest = async (order_requestId, owner_id) => {
    try {
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.RequestSummary(
        {
          request_id: order_requestId,
          user_id: AuthUserID,
        },
        headers
      );

      if (Result["type"] == "RXSUCCESS") {
        console.log("Result['data']", Result["data"]);
        setOwnerStatus(Result["data"]?.["owner_id"] === AuthUserID);
        setOrderRequestData(Result["data"]);
        setloading(false);
      } else {
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  /*
   * Update order request
   * Order request approve or reject
   * Status will update after login
   */
  const UpdateOrderRequest = async (request_id, status) => {
    try {
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.UpdateRequest(
        {
          order_request_id: request_id,
          status: status.toString(),
        },
        headers
      );

      if (Result["type"] == "RXSUCCESS") {
        setloading(false);
        await LoadOrderRequest(request_id, params.user_id);
        if (parseInt(status) === 1) {
          const parentOrderId = parseInt(
            OrderRequestData?.order_request_data?.parent_order_id
          );
          const orderId =
            parentOrderId > 0 ? parentOrderId : OrderRequestData?.order_id;
          setTimeout(
            () => navigate(`/equipmentOrders/${orderId}/advance`),
            300
          );
        } else {
          setTimeout(() => navigate("/equipmentOrders"), 300);
        }
        return false;
      } else {
        setloading(false);
        if (Result["statusCode"]) {
          setPaymentExpiredPopup(true);
        } else {
          setPaymentExpiredPopup(true);
          setRequestExpireMessage(Result["message"]);
        }
        return false;
      }
    } catch (err) {
      setloading(false);
      console.log(err);
      return false;
    }
  };

  /*
   * Handle chat with renter by owner
   * If user want to chat with renter
   * If user not login then get a message "You must be login" and open login popup
   * if user already login and click on chat link 'click here' then user redirect to notification section
   * Create a chat object for open chat window
   * Chat object store on localStroage
   */
  const HandkeChatwithRenter = async (messageObj) => {
    if (!AuthUserID) {
      dispatch(setalertMessage("You must be logged in."));
      dispatch(setalertMessagetype("error"));
      dispatch(SetRedirectPage(window.location.origin + "/notifications"));
      dispatch(setLoginModalOpen(true));
      dispatch(setLogInLoading(false));
    }
    const reciverID = messageObj?.user_id;
    const equipId = messageObj?.order_request_data?.equip_id;
    const reciverName = messageObj?.renter;
    const EquipMake = messageObj?.make || "N/A";
    let ChatObj = {
      equipId,
      senderID: AuthUserID,
      reciverID,
      reciverName,
      EquipMake,
    };
    localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
    navigate("/notifications");
  };

  /*
   * Handle click on Approve or Reject Button
   * If user not login then display message "You must be login" and open login popup
   * After Login page reload and user can approve or reject request
   * if User login then open confirm popup
   * On click confirm popup cancel button popup closed
   * On click confirm popup ok button updated request status
   */

  /*
   * Handle update state request
   * When user click on ok button from update status confirm popup
   * Get Request object form a state
   * Call UpdateOrderRequest function for update order request status
   */
  const UpdateRequest = async () => {
    UpdateOrderRequest(requestId, actionType === "accept" ? "1" : "2");
  };

  /*
   * Call on page load
   * Check order request Id and user id form URL
   * Get Order Request summary using user_id and order_request_id
   * */

  const handleMapRedirection = function (element: RentalItem) {
    const sourceLat = Number(AuthProfile?.["lat"]);
    const sourceLng = Number(AuthProfile?.["lng"]);
    let destinationLat = 0;
    let destinationLng = 0;
    if (element?.delivery_required === "1") {
      destinationLat = Number(element?.delivery_lat);
      destinationLng = Number(element?.delivery_lng);
    } else {
      destinationLat = Number(element?.equip_lat);
      destinationLng = Number(element?.equip_lng);
    }
    if (
      !isNaN(sourceLat) &&
      !isNaN(sourceLng) &&
      !isNaN(destinationLat) &&
      !isNaN(destinationLng)
    ) {
      const google_map = `https://www.google.com/maps/dir/?api=1&origin=${sourceLat},${sourceLng}&destination=${destinationLat},${destinationLng}`;
      window.open(google_map, "_blank");
    }
  };

  useEffect(() => {
    let order_request_id = params.id;
    let owner_id = params.user_id;
    if (AuthUserID) {
      LoadOrderRequest(order_request_id, owner_id);
    }
  }, [AuthUserID]);

  return (
    <MainLayout>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {OrderRequestData != null && OwnerStatus && (
        <Box>
          <Container maxWidth="sm">
            <Typography
              sx={{
                color: "#fff",
                fontSize: {
                  xs: "18px",
                  sm: "24px",
                  md: "26px",
                  lg: "28px",
                  xl: "32px",
                },
                fontWeight: "700",
                fontFamily: "Montserrat !important",
              }}
            >
              Rental Request Detail
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1rem",
                mt: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <Box
                  component={"img"}
                  src={OrderRequestData?.thumbnail}
                  sx={{
                    width: {
                      xs: "55px",
                      sm: "70px",
                      md: "75px",
                      lg: "80px",
                      xl: "85px",
                    },
                    height: {
                      xs: "45px",
                      sm: "60px",
                      md: "65px",
                      lg: "70px",
                      xl: "75px",
                    },
                    objectFit: "cover",
                    borderRadius: "5px",
                    border: "2px solid #faa61a",
                    boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.5)",
                  }}
                />
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1px" }}
                >
                  <Typography
                    sx={{
                      color: "#faa61a",
                      fontWeight: "700",
                      fontSize: {
                        xs: "14px",
                        sm: "18px",
                        md: "20px",
                        lg: "23px",
                        xl: "24px",
                      },
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    {OrderRequestData?.make ? OrderRequestData?.make : "N/A"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Order ID #
                    {OrderRequestData?.request_type === "extend_order"
                      ? OrderRequestData?.parent_order_id
                      : OrderRequestData?.order_id}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* owner detail start */}
            <Box sx={{ mt: 2, mb: 2 }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Rental:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    $
                    {parseFloat(
                      OrderRequestData["order_request_data"]["price"]
                    ).toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Security Deposit:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    $
                    {parseFloat(
                      OrderRequestData["order_request_data"]["security_deposit"]
                    ).toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Rental Admin Fee:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    -$
                    {parseFloat(
                      OrderRequestData["order_request_data"]["admin_commission"]
                    ).toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Equipment Protection:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Paid by Renter
                  </Typography>
                </Box>
              </Box>
              <Divider
                sx={{
                  borderColor: "#faa61a",
                  borderWidth: "1.2px",
                  mt: 1.5,
                  mb: 1.5,
                }}
              />

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Transport:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    $
                    {OrderRequestData["order_request_data"]?.[
                      "delivery_required"
                    ] == "1"
                      ? (
                          parseFloat(
                            OrderRequestData["order_request_data"]?.[
                              "delivery_charges"
                            ]
                          ) +
                          parseFloat(
                            OrderRequestData["order_request_data"]?.[
                              "pickup_chages"
                            ]
                          ) +
                          parseFloat(
                            OrderRequestData["order_request_data"]?.[
                              "minimum_delivery_fees"
                            ]
                          )
                        ).toFixed(2)
                      : "0.00"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Transport Admin Fee:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    -$
                    {parseFloat(
                      OrderRequestData["order_request_data"][
                        "admin_transport_commission"
                      ]
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Divider
                sx={{
                  borderColor: "#faa61a",
                  borderWidth: "1.2px",
                  mt: 1.5,
                  mb: 1.5,
                }}
              />

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#faa61a",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                        xl: "24px",
                      },
                      fontWeight: "700",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                        xl: "24px",
                      },
                      fontWeight: "700",
                      fontFamily: "Montserrat !important",
                    }}
                  >
                    $
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(
                      Number(OrderRequestData["order_request_data"]["price"]) +
                        Number(
                          OrderRequestData["order_request_data"][
                            "security_deposit"
                          ]
                        ) -
                        Number(
                          OrderRequestData["order_request_data"][
                            "admin_commission"
                          ]
                        ) +
                        (OrderRequestData["order_request_data"][
                          "delivery_address"
                        ] == undefined
                          ? 0
                          : Number(
                              OrderRequestData["order_request_data"][
                                "delivery_charges"
                              ]
                            ) +
                            Number(
                              OrderRequestData["order_request_data"][
                                "pickup_chages"
                              ]
                            ) +
                            Number(
                              OrderRequestData["order_request_data"][
                                "minimum_delivery_fees"
                              ]
                            ) -
                            Number(
                              OrderRequestData["order_request_data"][
                                "admin_transport_commission"
                              ]
                            ))
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* owner detail end */}

            {/* Rental Dates */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
                mt: 2,
                mb: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                >
                  <Box
                    component={"img"}
                    src={calendarIcon}
                    alt=""
                    sx={{
                      width: {
                        xs: "12px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      mt: "-1px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Rental Dates:
                  </Typography>
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "2px" }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    Start:{" "}
                    {OrderRequestData?.timezone
                      ? convertTimeZoneShortName(
                          momentTimezone
                            .tz(
                              `${OrderRequestData?.order_request_data?.start_date} ${OrderRequestData?.order_request_data?.start_time}`,
                              OrderRequestData?.renter_timezone
                            )
                            .format("MM/DD hh:mm A   (z)")
                        )
                      : moment(
                          `${OrderRequestData?.order_request_data?.start_date} ${OrderRequestData?.order_request_data?.start_time}`
                        ).format("MM/DD hh:mm A (UTC)")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        lg: "15px",
                        xl: "16px",
                      },
                    }}
                  >
                    End:{" "}
                    {OrderRequestData?.timezone
                      ? convertTimeZoneShortName(
                          momentTimezone
                            .tz(
                              `${OrderRequestData?.order_request_data?.end_date} ${OrderRequestData?.order_request_data?.end_time}`,
                              OrderRequestData?.renter_timezone
                            )
                            .format("MM/DD hh:mm A (z)")
                        )
                      : moment(
                          `${OrderRequestData?.order_request_data?.start_date} ${OrderRequestData?.order_request_data?.start_time}`
                        ).format("MM/DD hh:mm A (UTC)")}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={locationIcon}
                        alt=""
                        sx={{
                          width: {
                            xs: "12px",
                            sm: "14px",
                            md: "15px",
                            lg: "16px",
                            xl: "16px",
                          },
                          mt: "-1px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "12px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        {OrderRequestData?.delivery_required === "1"
                          ? "Deliver to"
                          : "Renter Pickup"}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: "#faa61a",
                        textDecoration: "underline",
                        textDecorationColor: "#faa61a",
                        fontSize: {
                          xs: "12px",
                          sm: "14px",
                          md: "14px",
                          lg: "15px",
                          xl: "16px",
                        },
                        cursor: "default",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() => {
                        if (AuthUserID === OrderRequestData?.owner_id) {
                          handleMapRedirection(OrderRequestData);
                        }
                      }}
                    >
                      {OrderRequestData?.delivery_required === "1"
                        ? OrderRequestData?.delivery_address
                        : OrderRequestData?.equip_address}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Accept/reject */}
            {/* {OrderRequestData?.showBtn && ( */}
            <Box
              sx={{
                border: "3px solid #E61A23",
                borderRadius: "10px",
                padding: "1rem 0.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.5rem",
                boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.5)",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                margin: "auto",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    width: "100%",
                    // mb: 2,
                    position: "relative",
                  }}
                >
                  <Box
                    component={"img"}
                    src={OrderRequestData?.renter_profile_pic}
                    alt=""
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      border: "2px solid #fff",
                      // position:{
                      //   xs:'unset',
                      //   sm:'absolute',
                      //   md:'absolute'
                      // },
                      // left:{
                      //   xs:'unset',
                      //   sm:'30px',
                      //   md:'30px',
                      // }
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontWeight: "400",
                        fontSize: {
                          xs: "11px",
                          sm: "14px",
                          md: "16px",
                          lg: "17px",
                          xl: "18px",
                        },
                      }}
                    >
                      {OrderRequestData?.renter?.replace(
                        OrderRequestData?.renter[0],
                        OrderRequestData?.renter[0]?.toUpperCase()
                      )}{" "}
                      {"  has requested this rental."}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        mt: "10px",
                        mb: "10px",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={chatIcon}
                        alt=""
                        sx={{
                          //  marginTop:'-5px',
                          width: {
                            xs: "14px",
                            sm: "15px",
                            md: "18px",
                            lg: "20px",
                            xl: "20px",
                          },
                          // mt: "2px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#faa61a",
                          textDecorationColor: "#faa61a",
                          textDecoration: "underline",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "14px",
                            lg: "14px",
                            xl: "14px",
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => HandkeChatwithRenter(OrderRequestData)}
                      >
                        Message Renter
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2rem",
                  marginRight: "-70px",
                }}
              >
                {OrderRequestData["status"] == "0" ? (
                  <>
                    <Button
                      sx={{
                        background: "#E61A23",
                        color: "#fff",
                        textTransform: "capitalize",
                        fontFamily: "Montserrat !important",
                        borderRadius: "8px",
                        fontWeight: "800",
                        padding: "7px 15px",
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                          lg: "16px",
                          xl: "18px",
                        },
                        "&:hover": {
                          background: "#b81118",
                        },
                      }}
                      onClick={() => {
                        setRequestId(OrderRequestData["id"]);
                        setActionType("reject");
                        setConfirmationMessage("REJECT");
                        handleConfirmationOpen();
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      sx={{
                        background: "#008001",
                        color: "#fff",
                        textTransform: "capitalize",
                        fontFamily: "Montserrat !important",
                        borderRadius: "8px",
                        fontWeight: "800",
                        padding: "7px 15px",
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                          lg: "16px",
                          xl: "18px",
                        },
                        "&:hover": {
                          background: "#006001",
                        },
                      }}
                      onClick={() => {
                        setRequestId(OrderRequestData["id"]);
                        setActionType("accept");
                        setConfirmationMessage("ACCEPT");
                        handleConfirmationOpen();
                      }}
                    >
                      Accept
                    </Button>
                  </>
                ) : OrderRequestData["status"] == "1" ? (
                  <Box
                    sx={{
                      // background: "#faa61a !important",
                      color: "#fff",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat !important",
                      borderRadius: "8px",
                      fontWeight: "800",
                      padding: "7px 15px",
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      // "&:hover": {
                      //   // background: "#faa61a !important",
                      // },
                    }}
                  >
                    Rental Accepted
                  </Box>
                ) : OrderRequestData["status"] == "2" ? (
                  <Box
                    sx={{
                      // background: "#faa61a !important",
                      color: "#fff",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat !important",
                      borderRadius: "8px",
                      fontWeight: "800",
                      padding: "7px 15px",
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      // "&:hover": {
                      //   // background: "#faa61a !important",
                      // },
                    }}
                  >
                    Rental Rejected
                  </Box>
                ) : OrderRequestData["status"] == "3" ? (
                  <Box
                    sx={{
                      // background: "#E61A23",
                      color: "#fff",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat !important",
                      borderRadius: "8px",
                      fontWeight: "800",
                      padding: "7px 15px",
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      // "&:hover": {
                      //   background: "#b81118",
                      // },
                    }}
                  >
                    Cancelled By Renter
                  </Box>
                ) : (
                  <Box
                    sx={{
                      // background: "#E61A23",
                      color: "#fff",
                      textTransform: "capitalize",
                      fontFamily: "Montserrat !important",
                      borderRadius: "8px",
                      fontWeight: "800",
                      padding: "7px 15px",
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      // "&:hover": {
                      //   background: "#b81118",
                      // },
                    }}
                  >
                    This Rental Request Has Expired
                  </Box>
                )}
              </Box>
            </Box>
            {/* )} */}
            {OrderRequestData["status"] === "0" && (
              <Box sx={{ color: "#fff", textAlign: "center", mt: 2 }}>
                <span>Note: </span>
                <span>
                  The rental request will automatically cancel if not accepted
                  in next
                </span>
                <CountdownTimer
                  expirationDateTime={OrderRequestData["expire_at"]}
                  color="#faa61a"
                />
              </Box>
            )}
          </Container>
        </Box>
      )}

      {!OwnerStatus && (
        <Box
          className="req-sum-main"
          sx={{ border: "5px solid #faa61a", borderRadius: "12px" }}
        >
          <Box
            className="req-msg"
            sx={{ backgroundColor: "#2e2e2e !important" }}
          >
            <Box
              className="req-msg-text"
              sx={{ backgroundColor: "#2e2e2e !important" }}
            >
              <Typography
                sx={{
                  color: "#fff !important",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                You must be login with same credentials.
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <ConfirmationModal
        isConfirmationOpen={isConfirmationOpen}
        handleConfirmationClose={handleConfirmationClose}
        onConfirm={UpdateRequest}
        message={"Are you sure want to " + confirmationMessage + "?"}
        loading={loading}
      />

      <Box>
        <Dialog
          open={paymentExpiredPopup}
          // open={true}
          aria-labelledby="draggable-dialog-title"
        >
          <Box className="session-expire">
            <DialogContent>
              <DialogTitle
                style={{
                  color: "#fff",
                  fontSize: "24px",
                  textAlign: "center",
                  marginTop: "-32px",
                }}
              >
                Error
              </DialogTitle>
              <DialogContentText style={{ color: "#fff", fontSize: "18px" }}>
                {requestExpireMessage
                  ? requestExpireMessage
                  : "This Rental request is no longer valid."}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className=""
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#de8d14 !important",
                  color: "#fff !important",
                  padding: "6px 50px 6px 50px",
                  borderRadius: "7px",
                  fontFamily: "GothamBold !important",
                  fontSize: "20px !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  LoadOrderRequest(params.id, params.user_id);
                  setPaymentExpiredPopup(false);
                  console.log("order request expired");
                }}
              >
                OKAY
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </MainLayout>
  );
};

export default OrderRequestSummary;
