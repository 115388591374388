import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Typography,
  styled,
  Backdrop,
  CircularProgress,
  Modal,
  IconButton,
  useMediaQuery,
  FormControl,
  Link,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  CheckboxProps,
  InputAdornment,
  Divider,
} from "@mui/material";
import pencilIcon from "assets/images/pencil.png";
import locationIcon from "assets/images/icons_equipmentlocation.png";
import HelpLogo from "assets/images/help-icon.png";
import cross from "assets/images/Social-Icon/cross-delete.png";
import MainLayout from "layouts/MainLayout";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoginStatus,
  setLoginModalOpen,
  setLogInLoading,
  setalertMessagetype,
  setalertMessage,
  setaccessToken,
  SetRedirectPage,
  setAuthuser,
  setSessionExpire,
  setEmailVerify,
  setAccountCompleted,
} from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import GoogleautoComplete from "../equipment-list/GoogleautoComplete";
import CloseIcon from "@mui/icons-material/Close";
import Geocode from "react-geocode";
import TxtNotification from "../common/TxtNotificationPopup/TextNotificationPopup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useDebounce from "./useDebounce";
import checkIcon from "assets/images/check.png";
import crossIcon from "assets/images/cross.png";
import { io } from "socket.io-client";
const CssTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: " 8px !important",
    padding: "10px",
    [theme.breakpoints.up("xs")]: {
      padding: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "12px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "15px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "16px",
    },
    fontSize: {
      xs: "12px !important",
      sm: "12px !important",
      md: "13px !important",
      lg: "14px !important",
      xl: "14px !important",
    },
  },
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    borderRadius: " 8px !important",
    fontFamily: "Montserrat !important",
    fontWeight: "600",
    fontSize: "clamp(14px, 3vw, 16px) !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      fontSize: {
        xs: "12px !important",
        sm: "12px !important",
        md: "13px !important",
        lg: "14px !important",
        xl: "14px !important",
      },
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: "2px solid #faa61a",
  backgroundColor: "#fff",
  ".Mui-focusVisible &": {},
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#fff",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd'  clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%232d2d2d'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: 800,
  //bgcolor: 'rgb(46 46 46)',
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

let SOCKET_URL = process.env.REACT_APP_STAGING_SOCKET_URL;
if (process.env.REACT_APP_PLATFORM === "Live") {
  SOCKET_URL = process.env.REACT_APP_LIVE_SOCKET_URL;
} else if (process.env.REACT_APP_PLATFORM === "PreLive") {
  SOCKET_URL = process.env.REACT_APP_PRELIVE_SOCKET_URL;
}

let socket;

const SignUp = () => {
  const dispatch = useDispatch();
  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY;
  Geocode.setApiKey(APIKEY);
  const inRef = useRef(null);
  const searchRef = useRef();

  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const OnelinkQaCode = useSelector(
    (state: IStoreValue) => state.equipmentReducer.qrCodeValue
  );
  const AuthProfile = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const matchesMobileX = useMediaQuery("(max-width:425px)");
  // 1st Step
  const [FirstName, setFirstName] = React.useState("");
  const [LastName, setLastName] = React.useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [referral, setReferral] = useState("");
  const [zipCode, setZipCode] = useState("");
  const debouncedZipCode = useDebounce(zipCode, 500);
  const [isValidZipcode, setIsValidZipcode] = useState(false);
  const [zipLoading, setZipLoading] = useState(false);
  const [loading, setloading] = useState<boolean>(false);
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  const ProfileStatus = useSelector(
    (state: IStoreValue) => state.equipmentReducer.ProfileStatus
  );
  const EmailVerify = useSelector(
    (state: IStoreValue) => state.equipmentReducer.EmailVerify
  );

  // const [FirsttimePopup, setFirsttimePopup] = useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCnfrmPassword, setShowCnfrmPassword] = React.useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const otpInputRefs = useRef([]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCnfrmPassword = () =>
    setShowCnfrmPassword((show) => !show);

  // 2nd step
  const RedirectPage = useSelector(
    (state: IStoreValue) => state.equipmentReducer.RedirectPage
  );
  const [MiddleName, setMiddleName] = React.useState("");
  const [Company, setCompany] = React.useState("");
  const [Address, setAddress] = React.useState("");
  const [Aaddress, setAaddress] = React.useState("");
  const [Phone, setPhone] = React.useState("");
  const [Terms, setTerms] = React.useState("no");
  const [MapPopup, setMapPopup] = useState<boolean>(false);

  const [suggesaddress, setsuggesaddress] = useState("");
  const defaultLocationcods = {
    lat: 39.321980897034734,
    lng: -111.10149320091872,
  };
  const [defaultLocation, setdefaultLocation] = useState(null);
  const [LocationAddress, setLocationAddress] = useState(null);
  const [defaultNewLocation, setdefaultNewLocation] =
    useState(defaultLocationcods);

  /*
   * Last step
   * OPen text notification poup
   */
  const [NotificationSettingPopup, setNotificationSettingPopup] =
    useState(false);

  //user Verification email
  const [UserMail, setUserMail] = useState("");

  const [FullAddress, setFullAddress] = useState({
    lat: "",
    lng: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    address: "",
  });

  const HandleSignup = async (e) => {
    e.preventDefault();
    try {
      setloading(true);
      var mailformat =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!FirstName) {
        dispatch(setalertMessage("Please fill first name!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (email == "") {
        dispatch(setalertMessage("Please fill email address!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (!email.match(mailformat)) {
        dispatch(setalertMessage("Invalid email address!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (!zipCode) {
        dispatch(setalertMessage("Please fill Zip code!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (zipCode && !isValidZipcode) {
        dispatch(setalertMessage("Invalid Zip code!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (password == "") {
        dispatch(setalertMessage("Please fill password!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (cpassword == "") {
        dispatch(setalertMessage("Please fill confirm password!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      }
      if (password != cpassword) {
        dispatch(setalertMessage("Confirm password did not match!"));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        dispatch(setLoginStatus(false));
        return false;
      } else {
        const Signup_status: IApiResponseRawData = await EquipmentAPIs.SignUp({
          email: email,
          password: password,
          c_password: cpassword,
          first_name: FirstName,
          last_name: LastName,
          referred_by: referral,
          postal: zipCode,
          request_from: "Web",
          ...FullAddress,
        });

        if (Signup_status["status"] == 200) {
          if (Signup_status["type"] == "RXERROR") {
            dispatch(setalertMessage(Signup_status["message"]));
            dispatch(setalertMessagetype("error"));
            setloading(false);
            dispatch(setLoginStatus(false));
            return false;
          } else {
            setloading(false);
            let auth_user = Signup_status["data"];
            localStorage.setItem("token-info", JSON.stringify(auth_user));
            dispatch(setaccessToken(Signup_status["data"]["auth_token"]));
            dispatch(setAuthuser(Signup_status["data"]["user_id"]));
            dispatch(setEmailVerify(Signup_status["data"]["is_user_verify"]));
            dispatch(setAccountCompleted(1));
            setUserMail(email);
            setEmail("");
            setPassword("");
            setCPassword("");
            dispatch(setLoginModalOpen(false));
          }
        } else {
          setloading(false);
          dispatch(setLoginStatus(false));
          dispatch(setalertMessage(Signup_status["message"]));
          dispatch(setalertMessagetype("error"));
        }
      }
    } catch (err) {
      console.log(err);
      setloading(false);
      setEmail("");
      setPassword("");
      dispatch(setLoginStatus(false));
    }
  };

  const UpdateProfile = async (smsnotification = false) => {
    //e.preventDefault();
    try {
      var new_FirstName = FirstName;
      if (new_FirstName != null) {
        if (new_FirstName.length > 0) {
          new_FirstName = new_FirstName.trimStart();
        }
      }

      if (new_FirstName == "" || new_FirstName == null) {
        dispatch(setalertMessage("Please fill first name to continue!"));
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (Address == "") {
        dispatch(
          setalertMessage("Please fill valid street address to continue!")
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (
        FullAddress.lat == "" ||
        FullAddress.lat == null ||
        FullAddress.lng == "" ||
        FullAddress.lng == null
      ) {
        dispatch(
          setalertMessage("Please fill valid street address to continue!")
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (Phone == "") {
        dispatch(
          setalertMessage("Please enter your phone number to continue!")
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (Phone.length != 12) {
        dispatch(
          setalertMessage("Please enter valid phone number to continue!")
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      if (Terms == "no") {
        dispatch(
          setalertMessage(
            "Please check Terms of Service and Privacy Policy to continue!"
          )
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      var new_Phone = Phone.replace(/[^0-9]/g, "");

      let body = {
        first_name: new_FirstName,
        middle_name: MiddleName ? MiddleName.trim() : "",
        last_name: LastName ? LastName.trim() : "",
        company_name: Company ? Company.trim() : "",
        additional_address: Aaddress ? Aaddress.trim() : "",
        user_id: UserId.toString(),
        address: FullAddress.address,
        lat: FullAddress.lat,
        lng: FullAddress.lng,
        city: FullAddress.city,
        state: FullAddress.state,
        country: FullAddress.country,
        postal_code: FullAddress.postal_code,
        phone_no: new_Phone.toString(),
        status: "1",
        device_type: "web",
        term: Terms,
        text_notification: smsnotification ? "1" : "0",
      };

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const UserStatus: IApiResponseRawData = await EquipmentAPIs.UpdateProfile(
        body,
        headers
      );

      if (UserStatus["status"] == 200) {
        resetForm();
        if (UserStatus["type"] == "RXERROR") {
          dispatch(setalertMessage(UserStatus["message"]));
          dispatch(setalertMessagetype("error"));
          return false;
        } else {
          dispatch(setalertMessage(UserStatus["message"]));
          dispatch(setalertMessagetype("success"));
          // setFirsttimePopup(true)
          localStorage.setItem("profileCompleted", "true");
          dispatch(setAccountCompleted(3));
          window.location.href = "/";
        }
      } else {
        setloading(false);
        dispatch(setalertMessage(UserStatus["message"]));
        dispatch(setalertMessagetype("error"));
        dispatch(setSessionExpire(true));
      }
    } catch (err) {
      setloading(false);
      console.log(err);
    }
  };

  //  Get city state country address etc from geocode
  const getFullAddress = async (
    locations: { lat?: number; lng?: number } = null
  ) => {
    let center = locations || defaultLocation;

    if (center.lat == null && center.lng == null) {
      setFullAddress({
        lat: "",
        lng: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        address: "",
      });
      setsuggesaddress("");

      dispatch(
        setalertMessage("Please fill valid street address to continue!")
      );
      dispatch(setalertMessagetype("error"));
      return false;
    } else {
      Geocode.fromLatLng(center.lat, center.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city, state, country, short_state, postal_code;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  short_state =
                    response.results[0].address_components[i].short_name;
                  break;
                case "postal_code":
                  postal_code =
                    response.results[0].address_components[i].short_name;
                  break;
              }
            }
          }
          let location_address = {
            lat: center.lat.toString(),
            lng: center.lng.toString(),
            city: city,
            state: state,
            country: country,
            postal_code: postal_code,
            address: address,
          };
          setFullAddress(location_address);
          setMapPopup(false);
          setAddress(address);

          setdefaultNewLocation({ lat: center.lat, lng: center.lng });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const GetProfile = async (user_id, token) => {
    try {
      setloading(true);
      const headers = { Authorization: `${token}`, request_from: "Web" };
      const Result = await EquipmentAPIs.getProfile(
        {
          user_id: user_id.toString(),
        },
        headers
      );

      if (Result["type"] == "RXSUCCESS") {
        if (
          Result["data"]["is_user_verify"] == 0 &&
          Result["data"]["is_profile_completed"] == 1
        ) {
          window.location.href = "/";
          return false;
        } else if (
          Result["data"]["is_user_verify"] == 0 &&
          window.location.pathname.includes("verify")
        ) {
          localStorage.setItem("openCompleteProfilePopup", "true");
          window.location.href = "/";
          return false;
        } else if (Result["data"]["is_user_verify"] == 0) {
          window.location.href = "/";
          return false;
        } else {
          dispatch(setAccountCompleted(1));
          setFirstName(Result["data"]["first_name"]);
          setLastName(Result["data"]["last_name"]);
          setUserMail(Result["data"]["email"]);
        }
        setloading(false);
      } else {
        setloading(false);
      }
    } catch (err) {
      setloading(false);
    }
  };

  const resetForm = async () => {
    //setFirstName('');
    setMiddleName("");
    setLastName("");
    setCompany("");
    setAddress("");
    setAaddress("");
    setPhone("");
    setTerms("no");
    setdefaultNewLocation(defaultLocationcods);
  };

  const VerificationEmailResendHandle = async () => {
    try {
      let body = {
        email: UserMail,
      };
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const MailStatus: IApiResponseRawData = await EquipmentAPIs.ResendMail(
        body,
        headers
      );

      if (MailStatus["type"] == "RXERROR") {
        setloading(false);
        dispatch(setalertMessage(MailStatus["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      } else {
        setloading(false);
        dispatch(setalertMessage(MailStatus["message"]));
        dispatch(setalertMessagetype("success"));
        return false;
      }
    } catch (err) {
      setloading(false);
      console.log(err);
    }
  };

  // After email verify from web browser user auto login
  const VerifyUserLogin = async (email = null) => {
    try {
      setloading(true);
      const Result = await EquipmentAPIs.webverifyLogin({
        email: email.toString(),
      });
      console.log("Result API Result", Result);
      if (Result["type"] == "RXSUCCESS") {
        setloading(false);
        let auth_user = Result["data"];
        console.log("Auth data in signup header: ", auth_user);

        if (
          Result["data"]["is_user_verify"] == 0 &&
          Result["data"]["is_profile_completed"] == 1
        ) {
          localStorage.setItem("login-popup", "open");
          window.location.href = "/";
          return false;
        } else if (Result["data"]["is_user_verify"] == 0) {
          localStorage.setItem("openCompleteProfilePopup", "true");
          window.location.href = "/";
          return false;
        }
        localStorage.setItem("token-info", JSON.stringify(auth_user));
        dispatch(setaccessToken(Result["data"]["auth_token"]));
        dispatch(setAuthuser(Result["data"]["user_id"]));
        dispatch(setEmailVerify(Result["data"]["is_user_verify"]));
        setUserMail(email);
        dispatch(setLoginStatus(true));
        dispatch(setLoginModalOpen(false));
        dispatch(setAccountCompleted(1));
      } else {
        setloading(false);
      }
    } catch (err) {
      setloading(false);
    }
  };

  const verifyZipCode = async (zip) => {
    setZipLoading(true);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      zip
    )}&key=${APIKEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        setIsValidZipcode(true);
        const { lat, lng } = data.results[0].geometry.location;
        getFullAddress({ lat, lng });
      } else {
        setIsValidZipcode(false);
      }
    } catch (error) {
      console.error("Error verifying zipcode:", error);
    } finally {
      setZipLoading(false);
    }
  };

  function disconnectSocket() {
    if (socket) {
      socket.disconnect();
      socket = undefined;
    }
  }

  const sendEmailVerificationCode = async () => {
    try {
      setloading(true);
      const userProfile = JSON.parse(localStorage.getItem("token-info"));
      const headers = {
        Authorization: `${userProfile["auth_token"]}`,
        request_from: "Web",
      };
      const response = await EquipmentAPIs.resendVerificationCode(
        {
          email: userProfile["email"],
        },
        headers
      );

      if (response["type"] === "RXSUCCESS") {
        dispatch(setalertMessage("OTP sent successfully kindly check your mail."));
        dispatch(setalertMessagetype("success"));
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error?.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setloading(false);
    }
  };

  const verifyEmailViaOTP = async () => {
    try {
      const userOtp = otpValues.join("");
      if(!userOtp) {
        dispatch(setalertMessage("Please enter OTP."));
        dispatch(setalertMessagetype("error"));
        return false;
      } else if(userOtp.length<4) {
        dispatch(setalertMessage("Please enter valid OTP."));
        dispatch(setalertMessagetype("error"));
        return false;
      }
      setloading(true);
      const userProfile = JSON.parse(localStorage.getItem("token-info"));
      const headers = {
        Authorization: `${userProfile["auth_token"]}`,
        request_from: "Web",
      };
      const response = await EquipmentAPIs.emailVerifybyOTP(
        {
          email: userProfile["email"],
          code: userOtp,
        },
        headers
      );

      if (response["type"] === "RXSUCCESS") {
        localStorage.setItem("openCompleteProfilePopup", "true");
        window.location.href = "/";
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error?.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setloading(false);
    }
  };


  const handleOtpChange = (event, index) => {
    const inputValue = event.target.value;
  
    // Ensure only numbers from 0 to 9 are allowed
    if (/^[0-9]?$/.test(inputValue)) {
      let newOtpValues = [...otpValues];
      
      // Update the current index with the input value
      newOtpValues[index] = inputValue;
      setOtpValues(newOtpValues);
      
      // Move focus to the next input if the current input is filled
      if (inputValue.length === 1 && index < otpInputRefs.current.length - 1) {
        otpInputRefs.current[index + 1].focus();
      }
    }
  };
  

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otpValues[index] === "" && index > 0) {
        // Move to the previous input and clear it if backspace is pressed and the current input is empty
        otpInputRefs.current[index - 1].focus();
        let newOtpValues = [...otpValues];
        newOtpValues[index - 1] = "";
        setOtpValues(newOtpValues);
      }
    }
  };


  useEffect(() => {
    if (UserId) {
      socket = io(SOCKET_URL, {
        query: {
          from: "web",
          user_id: UserId,
        },
      });
      socket.on("connect", () => {
        console.log("connected id", socket?.id);
      });
      socket.on("emailVerified", async (data) => {
        if (data?.userId === UserId) {
          localStorage.setItem("openCompleteProfilePopup", "true");
          window.location.href = "/";
        }
      });
    }
    window.addEventListener("beforeunload", (e) => {
      disconnectSocket();
    });
    return () => {
      disconnectSocket();
    };
  }, [UserId]);

  useEffect(() => {
    // If the debounced value is empty, reset result
    if (!debouncedZipCode) {
      return;
    }
    // Call the verification function only after the debounced value updates
    verifyZipCode(debouncedZipCode);
  }, [debouncedZipCode]);

  useEffect(() => {
    console.log("AAuthProfileData in sign up section: ", AuthProfile);

    let user_email = window.location.href.split("/").at(-1);
    let user_status = window.location.href.split("/").at(-2);
    let auth_user = localStorage.getItem("token-info");

    if (!auth_user && user_status == "verify") {
      if (user_email != "") {
        console.log("user_email", user_email);
        VerifyUserLogin(user_email);
      }
    }
    // End

    // _getlocation()
    if (auth_user) {
      auth_user = JSON.parse(auth_user);
      if (auth_user != null && auth_user) {
        GetProfile(auth_user["user_id"], auth_user["auth_token"]);

        dispatch(setEmailVerify(auth_user["is_user_verify"]));

        let AccessLocation = localStorage.getItem("accesslocation");
        if (AccessLocation != "undefined") {
          AccessLocation = JSON.parse(AccessLocation);
          if (AccessLocation != null && AccessLocation) {
            setdefaultLocation({
              lat: AccessLocation["lat"],
              lng: AccessLocation["lng"],
            });
          }
        }
      }
    }
  }, []);

  return (
    <MainLayout>
      {loading && (
        <Box>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}

      {/* Email Verification section 21 sep 2023 */}
      {EmailVerify == 1 ? (
        // {isLoggedin && EmailVerify == 1 && (
        <Box>
          <Container>
            <Box sx={{ p: 2 }}>
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xs: "20px",
                      sm: "28px",
                      md: "30px",
                      lg: "34px",
                      xl: "38px",
                    },
                    fontWeight: "700",
                    fontFamily: "GothamBold !important",
                  }}
                >
                  Please verify your E-mail address
                </Typography>
              </Box>
              {/* <Divider sx={{ borderColor: "#444", mt: 4, mb: 4 }} /> */}
              <Box
              // sx={{ background: "#47473F", p: 2, borderRadius: "8px", mt: 2 }}
              >
                <Box
                  sx={{
                    // border: "2px solid #139246",
                    p: 3,
                    mt: 2,
                    borderRadius: "5px",
                    background: "#1E1E1C",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "14px", md: "18px" },
                    }}
                  >
                    Please check your e-mail account for the verification code
                    we just sent you and enter that code in the box below
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 2,
                      mt: 6,
                    }}
                  >
                    {[0, 1, 2, 3].map((index) => (
                      <TextField
                        key={index}
                        inputRef={(el) => (otpInputRefs.current[index] = el)}
                        variant="outlined"
                        type="text"
                        value={otpValues[index]}
                        inputProps={{
                          maxLength: 1,
                        }}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        sx={{
                          width: "60px !important",
                          color: "#fff",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              // borderColor: "#ccc",
                              color: "#fff",
                              fontFamily: "Montserrat !important",
                              borderRadius: "8px",
                              // borderWidth: "3px",
                            },
                            "&:hover fieldset": {
                              // borderColor: "#faa61a",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#faa61a",
                              // borderWidth: "3px",
                            },
                            "& .MuiInputBase-input": {
                              textAlign: "center",
                              color: "#000",
                              fontSize: "24px",
                              fontFamily: "Montserrat, sans-serif",
                              fontWeight: 800,
                              width: "60px !important",
                              height: "60px !imortant",
                              background: "#fff",
                              borderRadius: "8px",
                            },
                          },
                        }}
                      />
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                      mb: 6,
                    }}
                  >
                    <Button
                      sx={{
                        boxShadow: "none",
                        color: "#faa61a",
                        fontFamily: "Montserrat !important",
                        fontSize: {
                          xs: "16px",
                          sm: "16px",
                          md: "16px",
                          lg: "18px",
                          xl: "18px",
                        },
                        fontWeight: "700",
                        background: "transparent",
                        minWidth: "auto",
                        minHeight: "auto",
                        width: "auto",
                        height: "auto",
                        "&:hover": {
                          background: "none",
                        },
                        textTransform: "capitalize",
                        textDecoration: "underline",
                      }}
                      onClick={sendEmailVerificationCode}
                    >
                      Resend
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={{
                      pl: 3,
                      pr: 3,
                      backgroundColor: "#139246",
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "18px",
                        xl: "18px",
                      },
                      fontWeight: "700",
                      "&:hover": {
                        background: "green",
                      },
                      textTransform: "capitalize",
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "auto",
                        lg: "auto",
                        xl: "auto",
                      },
                    }}
                    onClick={verifyEmailViaOTP}
                  >
                    continue
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <>
          <Box className="signup">
            <Container>
              <Box className="signup-inner">
                {!isLoggedin && EmailVerify == 0 && (
                  // {!isLoggedin && (
                  <>
                    <Box
                      // className="signup-heading"
                      sx={{ margin: "1rem 0", textAlign: "center" }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "GothamBold !important",
                          fontSize: {
                            xs: "25px",
                            sm: "30px",
                            md: "45px",
                            lg: "60px",
                            xl: "68px",
                          },
                        }}
                      >
                        Join the GoEquipMe Community
                      </Typography>
                    </Box>
                  </>
                )}
                {isLoggedin && EmailVerify == 0 && !ProfileStatus && (
                  // {isLoggedin && !ProfileStatus &&
                  <>
                    <Box
                      // className="signup-heading"
                      sx={{ margin: "1rem 0", textAlign: "center" }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontFamily: "GothamBold !important",
                          fontSize: {
                            xs: "25px",
                            sm: "30px",
                            md: "45px",
                            lg: "60px",
                            xl: "68px",
                          },
                        }}
                      >
                        Join the GoEquipMe Community
                      </Typography>
                    </Box>
                  </>
                )}
                <Box
                  // className="signup-grid"
                  sx={{
                    mt: 6,
                    display: "grid",
                    gridTemplateColumns: {
                      xs: "1fr",
                      sm: "1fr",
                      md: "1fr 1fr",
                      lg: "1fr 1fr",
                      xl: "1fr 1fr",
                    },
                    gap: {
                      xs: "1rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "2rem",
                      xl: "2rem",
                    },
                  }}
                >
                  {!isLoggedin && (
                    <>
                      <Box
                        // className="signup-grid-item"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#faa61a",
                            fontFamily: "GothamBold !important",
                            fontSize: {
                              xs: "18px",
                              sm: "20px",
                              md: "24px",
                              lg: "26px",
                              xl: "30px",
                            },
                          }}
                        >
                          Lets' Get Started
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              xs: "15px",
                              sm: "16px",
                              md: "18px",
                              lg: "20px",
                              xl: "22px",
                            },
                          }}
                        >
                          Find equipment when and where you need it in easy to
                          access categories.
                        </Typography>
                        <Typography
                          sx={{
                            color: "#faa61a",
                            fontFamily: "GothamBold !important",
                            fontSize: {
                              xs: "18px",
                              sm: "20px",
                              md: "24px",
                              lg: "26px",
                              xl: "30px",
                            },
                          }}
                        >
                          Rent & Save... List & Earn
                        </Typography>
                      </Box>
                    </>
                  )}

                  {isLoggedin && EmailVerify == 0 && !ProfileStatus && (
                    //  {isLoggedin && !ProfileStatus && (
                    <>
                      <Box
                        // className="signup-grid-item"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#faa61a",
                            fontFamily: "GothamBold !important",
                            fontSize: {
                              xs: "18px",
                              sm: "20px",
                              md: "24px",
                              lg: "26px",
                              xl: "30px",
                            },
                          }}
                        >
                          Lets' Get Started
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              xs: "15px",
                              sm: "16px",
                              md: "18px",
                              lg: "20px",
                              xl: "22px",
                            },
                          }}
                        >
                          Find equipment when and where you need it in easy to
                          access categories.
                        </Typography>
                        <Typography
                          sx={{
                            color: "#faa61a",
                            fontFamily: "GothamBold !important",
                            fontSize: {
                              xs: "18px",
                              sm: "20px",
                              md: "24px",
                              lg: "26px",
                              xl: "30px",
                            },
                          }}
                        >
                          Rent & Save... List & Earn
                        </Typography>
                      </Box>
                    </>
                  )}
                  <Box className="signup-grid-item">
                    {/* 1st Step */}
                    {!isLoggedin && (
                      <>
                        <Box className="signup-steps-sec">
                          <Box
                            // className="signup-steps-heading"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                              marginBottom: "1rem",
                            }}
                          >
                            <Box
                              component={"img"}
                              src={pencilIcon}
                              alt=""
                              sx={{ width: "35px", height: "35px" }}
                            />
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: {
                                  xs: "25px",
                                  sm: "30px",
                                  md: "35px",
                                  lg: "40px",
                                  xl: "45px",
                                },
                                fontFamily: "GothamBold !important",
                              }}
                            >
                              Sign Up
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                        // className="signup-fields"
                        >
                          <Box
                            sx={{
                              margin: "1.2rem 0",
                              display: "flex",
                              textAlign: "center",
                              justifyContent: "center",
                              gap: "1rem",
                            }}
                          >
                            <CssTextField
                              id="user-first-name"
                              variant="outlined"
                              placeholder="First Name"
                              type="text"
                              sx={{ width: "100%" }}
                              onChange={(e) =>
                                setFirstName(e.target.value?.trim())
                              }
                              value={FirstName}
                            />
                            <CssTextField
                              id="user-last-name"
                              variant="outlined"
                              placeholder="Last Name"
                              type="text"
                              sx={{ width: "100%" }}
                              onChange={(e) =>
                                setLastName(e.target.value?.trim())
                              }
                              value={LastName}
                            />
                          </Box>
                          {/* <Box sx={{ margin: "1.2rem 0" }}>
                          </Box> */}

                          <Box
                            // className="input-div"
                            sx={{ margin: "1.2rem 0" }}
                          >
                            <CssTextField
                              id="login-email"
                              variant="outlined"
                              placeholder="Email Address"
                              type="email"
                              sx={{ width: "100%" }}
                              onChange={(e) => setEmail(e.target.value.trim())}
                              value={email}
                            />
                          </Box>

                          <Box sx={{ margin: "1.2rem 0" }}>
                            <CssTextField
                              id="user-zipcode"
                              variant="outlined"
                              placeholder="Zipcode"
                              type="text"
                              sx={{ width: "100%" }}
                              onChange={(e) =>
                                setZipCode(
                                  e.target.value?.trim()?.replace(/[^0-9]/g, "")
                                )
                              }
                              value={zipCode}
                              inputProps={{ maxLength: 6 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      edge="end"
                                      className="eye-icon"
                                    >
                                      {zipLoading ? (
                                        <CircularProgress
                                          size={"25px"}
                                          color="warning"
                                        />
                                      ) : isValidZipcode && zipCode ? (
                                        <Box
                                          component={"img"}
                                          src={checkIcon}
                                          sx={{ width: "25px", height: "auto" }}
                                        />
                                      ) : !isValidZipcode && zipCode ? (
                                        <Box
                                          component={"img"}
                                          src={crossIcon}
                                          sx={{ width: "25px", height: "auto" }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>

                          <Box
                            // className="input-div"
                            sx={{ margin: "1.2rem 0" }}
                          >
                            <CssTextField
                              id="login-password"
                              variant="outlined"
                              placeholder="Password"
                              type={showPassword ? "text" : "password"}
                              autoComplete="new-password"
                              inputProps={{ maxLength: 10 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                      // className="eye-icon"
                                    >
                                      {showPassword ? (
                                        <Visibility
                                          sx={{ color: "#2d2d2d !important" }}
                                        />
                                      ) : (
                                        <VisibilityOff
                                          sx={{ color: "#2d2d2d !important" }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{ width: "100%" }}
                              onChange={(e) =>
                                setPassword(e.target.value.trim())
                              }
                              value={password}
                            />
                          </Box>
                          <Box
                            // className="input-div"
                            sx={{ margin: "1.2rem 0" }}
                          >
                            <CssTextField
                              id="login-c-password"
                              variant="outlined"
                              placeholder="Confirm Password"
                              type={showCnfrmPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowCnfrmPassword}
                                      edge="end"
                                      className="eye-icon"
                                    >
                                      {showCnfrmPassword ? (
                                        <Visibility
                                          sx={{ color: "#2d2d2d !important" }}
                                        />
                                      ) : (
                                        <VisibilityOff
                                          sx={{ color: "#2d2d2d !important" }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{ width: "100%" }}
                              onChange={(e) =>
                                setCPassword(e.target.value.trim())
                              }
                              value={cpassword}
                            />
                          </Box>

                          {/* by GD 26 Feb 2024 for build a crew referral field */}
                          <Box
                            // className="input-div"
                            sx={{ margin: "1.2rem 0" }}
                          >
                            <CssTextField
                              id="login-refferal-code"
                              variant="outlined"
                              placeholder="Referral Code"
                              type="text"
                              sx={{ width: "100%" }}
                              onChange={(e) =>
                                setReferral(
                                  e.target.value.replace(/[^0-9]/g, "")
                                )
                              }
                              value={referral}
                            />
                          </Box>
                          {/* GD end */}
                        </Box>
                        <Box className="next-btn-div">
                          <Button
                            // className="next-btn"
                            sx={{
                              fontSize: {
                                xs: "20px",
                                sm: "22px",
                                md: "24px",
                                lg: "26px",
                                xl: "28px",
                              },
                              backgroundColor: "#149248",
                              fontFamily: "Montserrat",
                              fontWeight: "700",
                              borderRadius: "10px",
                              color: "#fff",
                              boxShadow: "none",
                              padding: "0.5rem 3rem",
                              "&:hover": {
                                background: "#149248",
                              },
                            }}
                            onClickCapture={HandleSignup}
                          >
                            Sign Up
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
        </>
      )}

      {/* Google map Popup */}
      <Modal
        keepMounted
        open={MapPopup}
        onClose={() => dispatch(setLoginModalOpen(false))}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            width: matchesMobileX ? "calc(100vw - 100px)" : 800,
            padding: "0px",
          }}
        >
          <IconButton
            onClick={() => setMapPopup(false)}
            // sx={{ position: 'absolute', right: 0, top: 0, color: '#faa61a' }}
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <>
            <Box className="login__main">
              <Box className="login-main-div location-modal">
                <Box className="login-main-inner">
                  <Box className="login-fields-div ">
                    {MapPopup && (
                      <GoogleautoComplete
                        onPress={(center, address) => {
                          // console.log('center',center);
                          setdefaultLocation(center);
                        }}
                        inputRefs={inRef}
                        searchRef={searchRef}
                        equipLocation={defaultNewLocation}
                      />
                    )}
                    <Box
                      // className="next-btn-div location-btn-grp"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        justifyContent: "flex-end",
                        mt: 2,
                        mb: 1,
                      }}
                    >
                      <Button
                        // className="next-btn cancel-btn-2"
                        // className="next-btn cancel-btn-2"
                        sx={{
                          fontSize: {
                            xs: "14px",
                            sm: "16px",
                            md: "18px",
                            lg: "18px",
                            xl: "20px",
                          },
                          backgroundColor: "#faa61a",
                          fontFamily: "Montserrat",
                          fontWeight: "700",
                          borderRadius: "10px",
                          color: "#fff",
                          boxShadow: "none",
                          padding: "0.2rem 1rem",
                          "&:hover": {
                            background: "#faa61a",
                          },
                        }}
                        onClickCapture={() => {
                          setMapPopup(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        // className="next-btn ok-btn"
                        sx={{
                          fontSize: {
                            xs: "14px",
                            sm: "16px",
                            md: "18px",
                            lg: "18px",
                            xl: "20px",
                          },
                          backgroundColor: "#faa61a",
                          fontFamily: "Montserrat",
                          fontWeight: "700",
                          borderRadius: "10px",
                          color: "#fff",
                          boxShadow: "none",
                          padding: "0.2rem 1rem",
                          "&:hover": {
                            background: "#faa61a",
                          },
                        }}
                        onClickCapture={() => {
                          getFullAddress();
                        }}
                      >
                        Ok
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Modal>
      {/* Text notification popup */}
      <Box className="text-notification">
        <BootstrapDialog
          //onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={NotificationSettingPopup}
          className="tn-popup"
        >
          <TxtNotification
            onClose={(result) => {
              if (result == "Accept") {
                UpdateProfile(true);
              } else {
                UpdateProfile(false);
              }
              setNotificationSettingPopup(false);
            }}
          />
        </BootstrapDialog>
      </Box>
    </MainLayout>
  );
};

export default SignUp;
