import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../service/api-service";
import {
  Box,
  CircularProgress,
  Link,
  Typography,
  Backdrop,
  Container,
  Button,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  setLoginStatus,
  setLoginModalOpen,
  setalertMessagetype,
  setalertMessage,
  setaccessToken,
  setLogInLoading,
  setSessionExpire,
  setRedirectToChat,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import MainLayout from "../../layouts/MainLayout";
import "./styles.scss";

import MyOfficeTrailer from "components/MyofficeTrailer/MyOfficeTrailer";
import Irented from "components/MyofficeTrailer/Equipment-Rented/Irented";
import NotificationTab from "./../../components/Notifications/Notification";
import Gps from "./../../components/MyofficeTrailer/Gps/Gps";
// import ThumbsUp from "./../../components/MyofficeTrailer/Thumbs-up/ThumbsUp";
import MyServiceDirectory from "components/serviceDirectory/MyServiceDirectory";
import ProfilePic from "./../../assets/images/dummy-profile-pic.jpeg";
import Sale from "./../../components/MyofficeTrailer/Equipment-Sale/Sale-Equips";
import BuildACrew from "components/MyofficeTrailer/BuildACrew/BuildACrew";
import Dashboard from "components/MyofficeTrailer/dashboard/Dashboard";
import { useNavigate, useLocation } from "react-router-dom";
import RentSteps from "components/user-signup/signupPopup/RentSteps";
// import EquipmentOrders from "components/MyofficeTrailer/EquipmentOrders/EquipmentOrders";

const MyOfficeTrailerPage = () => {
  const dispatch = useDispatch();
  const equipId = useRef();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const CountNotification = useSelector(
    (state: IStoreValue) => state.equipmentReducer.NotificationCount
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const ActiveNotificationTab = useSelector(
    (state: IStoreValue) => state.equipmentReducer.RedirectToChat
  );
  const [loading, setloading] = React.useState(false);
  const [ChatData, setChatData] = React.useState(null);

  const [ActiveStep, setActiveStep] = React.useState(8);
  const [Profile, setProfile] = React.useState(null);
  const [completeProfile, setCompleteProfile] = useState<boolean>(false);
  const handleCloseCompleteProfile = () => setCompleteProfile(false);
  // const [ReloadTabs, setReloadTabs] = React.useState(false);
  // by gd for api reload on tab change
  const [ReloadTabs, setReloadTabs] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const UserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  // by GD for update tab count and reload apis of sales and rent listing
  const reloadTabUpdate = () => {
    let rTab = ReloadTabs + 1;
    setReloadTabs(rTab);
  };

  if (ActiveNotificationTab) {
    setChatData(null);
    setActiveStep(5);
    dispatch(setRedirectToChat(false));
  }

  const handleListEquipment = (event) => {
    event.stopPropagation();
    if (parseFloat(AuthProfileData?.["is_profile_completed"]) === 0) {
      setCompleteProfile(true);
      return false;
    }
    window.location.href = "/listequipment";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!UserID) {
      if (
        !localStorage.getItem("RedirectLink") &&
        location.pathname.includes("/buildACrew/")
      ) {
        localStorage.setItem("RedirectLink", location.pathname);
      }
    } else {
      localStorage.removeItem("RedirectLink");
    }
    if (AuthProfileData && AuthProfileData != null) {
      // let location = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
      console.log("location: " + location.pathname);
      // if (location == 'notifications') {
      if (location.pathname.includes("/notifications")) {
        setChatData(null);
        setActiveStep(5);
      }

      // by GD 26 Feb for build a Crew
      if (location.pathname.includes("/buildACrew")) setActiveStep(7);
      // GD end
      // by GD 14 march for tabs select
      if (location.pathname.includes("/myRentalYard")) setActiveStep(1);
      if (location.pathname.includes("/mySalesYard")) setActiveStep(6);
      if (location.pathname.includes("/equipmentRented")) setActiveStep(2);
      if (location.pathname.includes("/myServiceDirectory")) setActiveStep(3);
      if (location.pathname.includes("/myGps")) setActiveStep(4);
      if (location.pathname.includes("/dashboard")) setActiveStep(8);
      if (location.pathname.includes("/equipmentOrders")) setActiveStep(9);
      // GD end
      let lat = AuthProfileData["lat"];
      let lng = AuthProfileData["lng"];
      setProfile(AuthProfileData);

      // Check order placed or not
      let OrderPlacedId = localStorage.getItem("OrderPlacedId");

      if (OrderPlacedId != null && OrderPlacedId) {
        setChatData(null);
        setActiveStep(2);
      }

      //check list equip type
      let equipType = localStorage.getItem("EquipType");
      if (
        equipType != "undefined" &&
        equipType == "sale" &&
        equipType != null
      ) {
        setActiveStep(6);
      }
    }
  }, [AuthProfileData, UserID, location.pathname]);

  // by GD 15 march 2024
  const LoginPopup = () => {
    dispatch(setLoginModalOpen(true));
    dispatch(setLogInLoading(false));
  };
  // GD end
  return (
    <>
      <MainLayout>
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}

        <Box className="rentyrd-main">
          <Box className="rentyrd-inner">
            <Box className="rentyrd-top-grid">
              <Box className="rentyrd-top-grid-item">
                <Box className="rentyrd-renter-img">
                  <img
                    src={
                      Profile?.profile_image
                        ? Profile.profile_image
                        : ProfilePic
                    }
                    alt=""
                  />
                </Box>
              </Box>
              <Box className="rentyrd-top-grid-item">
                <Box className="rentyrd-menu office-trailer-tabs">
                  <Link
                    className={
                      ActiveStep == 8
                        ? "rentyrd-menu-link active-tab"
                        : "rentyrd-menu-link"
                    }
                    onClick={() => {
                      if (!UserID) return LoginPopup();
                      navigate("/dashboard");
                      setActiveStep(8);
                      reloadTabUpdate();
                    }}
                  >
                    Dashboard
                  </Link>
                  <Link
                    className={
                      ActiveStep == 1
                        ? "rentyrd-menu-link active-tab"
                        : "rentyrd-menu-link"
                    }
                    onClick={() => {
                      if (!UserID) return LoginPopup();
                      navigate("/myRentalYard");
                      setActiveStep(1);
                      reloadTabUpdate();
                    }}
                  >
                    Rental Yard
                  </Link>

                  <Link
                    className={
                      ActiveStep == 6
                        ? "rentyrd-menu-link active-tab"
                        : "rentyrd-menu-link"
                    }
                    onClick={() => {
                      if (!UserID) return LoginPopup();
                      navigate("/mySalesYard");
                      setActiveStep(6);
                      reloadTabUpdate();
                    }}
                  >
                    MY Sales Yard
                  </Link>

                  <Link
                    className={
                      ActiveStep == 2
                        ? "rentyrd-menu-link active-tab"
                        : "rentyrd-menu-link"
                    }
                    onClick={() => {
                      if (!UserID) return LoginPopup();
                      navigate("/equipmentRented");
                      setActiveStep(2);
                    }}
                  >
                    Equipment Rented
                  </Link>

                  <Link
                    className={
                      ActiveStep == 3
                        ? "rentyrd-menu-link active-tab"
                        : "rentyrd-menu-link"
                    }
                    onClick={() => {
                      if (!UserID) return LoginPopup();
                      navigate("/myServiceDirectory");
                      setActiveStep(3);
                    }}
                  >
                    My Services{" "}
                  </Link>
                  {/* Below code is added by pp due to addition of order feature */}
                  {/* <Link
                    className={
                      ActiveStep === 9
                        ? "rentyrd-menu-link active-tab"
                        : "rentyrd-menu-link"
                    }
                    onClick={() => {
                      if (!UserID) return LoginPopup();
                      navigate("/equipmentOrders");
                      setActiveStep(9);
                    }}
                  >
                    Orders
                  </Link> */}
                  {/* <Link className={ActiveStep == 4 ? "rentyrd-menu-link active-tab" : "rentyrd-menu-link"} onClick={() => {
                                        dispatch(setalertMessage('Upcoming feature'));
                                        dispatch(setalertMessagetype('info'));
                                    }}>GPS</Link> */}
                  <Link
                    className={
                      ActiveStep == 4
                        ? "rentyrd-menu-link active-tab"
                        : "rentyrd-menu-link"
                    }
                    onClick={() => {
                      if (!UserID) return LoginPopup();
                      navigate("/myGps");
                      setActiveStep(4);
                    }}
                  >
                    GPS
                  </Link>
                  {/* by GD 21 Feb for build a crew */}
                  <Link
                    className={
                      ActiveStep == 7
                        ? "rentyrd-menu-link active-tab"
                        : "rentyrd-menu-link"
                    }
                    onClick={() => {
                      if (!UserID) return LoginPopup();
                      navigate("/buildACrew");
                      setActiveStep(7);
                    }}
                  >
                    Build a Crew
                  </Link>
                  {/* GD end */}
                  {/* <Link
                    className={
                      ActiveStep == 5
                        ? "rentyrd-menu-link active-tab"
                        : "rentyrd-menu-link"
                    }
                    onClick={() => {
                      if (!UserID) return LoginPopup();
                      navigate("/notifications");
                      setActiveStep(5);
                      setChatData(null);
                    }}
                  >
                    Notifications
                    {CountNotification > 0 && (
                      <span className="rentyrd-notification">
                        {(CountNotification + "").length > 1 ? "9+" : CountNotification}
                      </span>
                    )}{" "}
                  </Link> */}
                </Box>
              </Box>
            </Box>

            <Box>
              {ActiveStep === 1 ? (
                <MyOfficeTrailer
                  ChatClick={(data) => {
                    if (data) {
                      setChatData(data);
                      setActiveStep(5);
                    }
                  }}
                  isLoad={true}
                  UpdateTab={(ress) => {
                    console.log("from rental yard", ress);
                    if (
                      ress.status == "success" &&
                      ress.type == "sale" &&
                      ActiveStep == 1
                    ) {
                      navigate("/mySalesYard");
                      setActiveStep(6);
                      reloadTabUpdate();
                    }
                  }}
                  setActiveStep={setActiveStep}
                  ReloadTab={ReloadTabs}
                  reloadTabUpdate={reloadTabUpdate}
                  handleListEquipment={handleListEquipment}
                />
              ) : ActiveStep == 2 ? (
                <Irented
                  ChatClick={(data) => {
                    if (data) {
                      setChatData(data);
                      setActiveStep(5);
                    }
                  }}
                  handleListEquipment={handleListEquipment}
                />
              ) : ActiveStep == 3 ? (
                <MyServiceDirectory />
              ) : ActiveStep == 4 ? (
                <Gps handleListEquipment={handleListEquipment} />
              ) : ActiveStep === 6 ? (
                <Sale
                  UpdateTab={(ress) => {
                    console.log("from sales yard", ress);
                    if (ress.status == "success" && ress.type == "sale") {
                      navigate("/mySalesYard");
                      setActiveStep(6);
                    }
                    if (
                      ress.status == "success" &&
                      ress.type == "rent" &&
                      ActiveStep == 6
                    ) {
                      navigate("/myRentalYard");
                      setActiveStep(1);
                      reloadTabUpdate();
                    }
                  }}
                  ReloadTab={ReloadTabs}
                  reloadTabUpdate={reloadTabUpdate}
                  handleListEquipment={handleListEquipment}
                />
              ) : ActiveStep == 7 ? (
                <BuildACrew />
              ) : ActiveStep == 8 ? (
                <Dashboard
                  setActiveStep={setActiveStep}
                  reloadTabUpdate={reloadTabUpdate}
                  handleListEquipment={handleListEquipment}
                />
              ) : (
                // ActiveStep === 9 ? (
                //   <EquipmentOrders
                //     setActiveStep={setActiveStep}
                //     reloadTabUpdate={reloadTabUpdate}
                //   />
                // ) :
                ""
              )}
            </Box>
          </Box>
        </Box>
        <RentSteps
          open={completeProfile}
          handleClose={handleCloseCompleteProfile}
        />
      </MainLayout>
    </>
  );
};
export default MyOfficeTrailerPage;
