import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css";
import "./styles.css";
import { IStoreValue } from "utils/types";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";

const CustomCalendar = ({
  equipRentedDates,
  equipRentedTimes,
  setEquipRentedDates,
  label = null,
  minDateTime,
  setEquipRentedTimes,
  setTempDates = undefined,
  setTempRentalTimes = undefined,
  currentMonth,
  setCurrentMonth,
  calendarRef,
  enableDateSelection,
  setEnableDateSelection,
}) => {
  const dispatch = useDispatch();
  // const [selectedRange, setSelectedRange] = useState([null, null]);
  const bookedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentBookedDates
  );
  const blockedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentBlockedDates
  );
  const requestedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.equipmentReuestedDates
  );

  const isWeekendAvailable = useSelector(
    (state: IStoreValue) => state.equipmentReducer.rentOnWeekend
  );

  const partiallyBookedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partiallyBookedDates
  );
  const partiallyPendingDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partiallyPendingDates
  );

  const AllReservedDates = useSelector(
    (state: IStoreValue) => state.equipmentReducer.reservedDates
  );

  const partialDatesCounts = useSelector(
    (state: IStoreValue) => state.equipmentReducer.partialDatesCount
  );

  const getDateStyle = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    if (formattedDate >= today) {
      let isWeekend = false;

      // Check if the date is a weekend and whether weekends are available for booking
      if (
        (date.weekDay.index === 0 && isWeekendAvailable?.sunday === "0") ||
        (date.weekDay.index === 6 && isWeekendAvailable?.saturday === "0")
      ) {
        isWeekend = true;
      }

      if (isWeekend) {
        return {
          className: "",
          style: {
            backgroundColor: "#606060",
            color: "#b7b7b7",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
        };
      } else if (blockedDates?.includes(formattedDate)) {
        return {
          className: "",
          style: {
            backgroundColor: "red",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
        };
      } else if (AllReservedDates[0]?.hasOwnProperty(formattedDate)) {
       
        if (
          partialDatesCounts[formattedDate] >= 2 &&
          ((partiallyBookedDates[0]?.includes(formattedDate) &&
            partiallyBookedDates[1]?.includes(formattedDate)) ||
            (partiallyBookedDates[1]?.includes(formattedDate) &&
              partiallyPendingDates[0]?.includes(formattedDate)) ||
            (partiallyBookedDates[0]?.includes(formattedDate) &&
              partiallyPendingDates[1]?.includes(formattedDate)))
        ) {
          return {
            className: "partial",
            style: {
              backgroundColor: "#faa61a",
              color: "#fff",
              cursor: "pointer",
            },
            disabled: false,
          };
        } else if (
          partialDatesCounts[formattedDate] >= 2 &&
          partiallyPendingDates[0]?.includes(formattedDate) &&
          partiallyPendingDates[1]?.includes(formattedDate)
        ) {
          return {
            className: "partial",
            style: {
              backgroundColor: "skyblue",
              color: "#fff",
              cursor: "pointer",
            },
            disabled: false,
          };
        } else if (partiallyPendingDates[0]?.includes(formattedDate)) {
          return {
            className: "partial-pending-start",
            style: {
              color: "#fff",
              cursor: "pointer",
            },
            disabled: false,
          };
        } else if (partiallyPendingDates[1]?.includes(formattedDate)) {
          return {
            className: "partial-pending-end",
            style: {
              color: "#fff",
              cursor: "pointer",
            },
            disabled: false,
          };
        } else if (partiallyBookedDates[0]?.includes(formattedDate)) {
          return {
            className: "partial-booked-start",
            style: {
              color: "#fff",
              cursor: "pointer",
            },
            disabled: false,
          };
        } else if (partiallyBookedDates[1]?.includes(formattedDate)) {
          return {
            className: "partial-booked-end",
            style: {
              color: "#fff",
              cursor: "pointer",
            },
            disabled: false,
          };
        }
        // if (
        //   partiallyPendingDates[0]?.includes(formattedDate) ||
        //   partiallyPendingDates[1]?.includes(formattedDate)
        // ) {
        //   return {
        //     className: "partial-pending-end",
        //     style: {
        //       color: "#fff",
        //       cursor: "pointer",
        //     },
        //     disabled: false,
        //   };
        // }
        // return {
        //   className: "partial-booked-end",
        //   style: {
        //     color: "#fff",
        //     cursor: "pointer",
        //   },
        //   disabled: false,
        // };
      } else if (bookedDates?.includes(formattedDate)) {
        return {
          className: "",
          style: {
            backgroundColor: "#faa61a",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
        };
      } else if (requestedDates?.includes(formattedDate)) {
        return {
          className: "",
          style: {
            backgroundColor: "skyblue",
            color: "#fff",
            cursor: "not-allowed",
          },
          disabled: true, // Disable selection
        };
      }
    }

    return {
      style: {},
      disabled: false,
      className: "",
    };
  };

  const getDatesInRange = (startDate, endDate) => {
    let start = new Date(startDate);
    let end = new Date(endDate);
    let dates = [];

    // Loop from start to end date
    while (start <= end) {
      let year = start.getFullYear();
      let month = String(start.getMonth() + 1).padStart(2, "0"); // Add leading zero
      let day = String(start.getDate()).padStart(2, "0"); // Add leading zero

      // Push the date in 'YYYY-MM-DD' format
      dates.push(`${year}-${month}-${day}`);

      // Move to the next day
      start.setDate(start.getDate() + 1);
    }

    return dates;
  };

  const isRangeValid = (dateRange) => {
    const matchValue = dateRange.filter((date) => date in AllReservedDates[0]);
    if (matchValue?.length >= 1 && dateRange?.length > 2) {
      if (
        matchValue?.includes(dateRange[0]) ||
        matchValue?.includes(dateRange[dateRange.length - 1])
      ) {
        return true;
      }
      return false;
    }
    for (const date of dateRange) {
      if (blockedDates?.includes(date)) {
        return false;
      } else if (
        // partiallyPendingDates[0]?.includes(date) ||
        // partiallyPendingDates[1]?.includes(date) ||
        // partiallyBookedDates[0]?.includes(date) ||
        // partiallyBookedDates[1]?.includes(date)
        AllReservedDates[0]?.hasOwnProperty(date)
      ) {
        return true;
      } else if (requestedDates?.includes(date)) {
        return false;
      } else if (bookedDates.includes(date)) {
        return false;
      }
    }
    return true;
  };

  const handleRangeChange = (range) => {
    const [startDate, endDate] = range;
    // if (equipRentedDates[0] && equipRentedDates[1] && startDate) {
    //   if (equipRentedDates[0].toDate() > startDate.toDate()) {
    //     setEquipRentedDates([startDate, equipRentedDates[1]]);
    //     setEquipRentedTimes((prev) => ({ ...prev, startTime: null }));
    //     if (setTempRentalTimes) {
    //       setTempRentalTimes((prev) => ({ ...prev, startTime: null }));
    //     }
    //     if (setTempDates) {
    //       setTempDates([startDate, null]);
    //     }
    //   } else if (equipRentedDates[1].toDate() < startDate) {
    //     setEquipRentedDates([equipRentedDates[0], startDate]);
    //     setEquipRentedTimes((prev) => ({ ...prev, endTime: null }));
    //     if (setTempRentalTimes) {
    //       setTempRentalTimes((prev) => ({ ...prev, endTime: null }));
    //     }
    //     if (setTempDates) {
    //       setTempDates([null, startDate]);
    //     }
    //   }
    //   return;
    // } else if (
    //   startDate &&
    //   equipRentedDates[0] &&
    //   equipRentedDates[0].toDate() > startDate.toDate()
    // ) {
    //   setEquipRentedDates([startDate, null]);
    //   setEquipRentedTimes({ startTime: null, endTime: null });
    //   if (setTempRentalTimes) {
    //     setTempRentalTimes({ startTime: null, endTime: null });
    //   }
    //   if (setTempDates) {
    //     setTempDates([startDate, null]);
    //   }
    //   return;
    // }

    if (
      startDate &&
      equipRentedDates[0] &&
      !equipRentedDates[1] &&
      equipRentedDates[0].toDate() > startDate.toDate()
    ) {
      setEquipRentedDates([startDate, null]);
      setEquipRentedTimes({ startTime: null, endTime: null });
      if (setTempRentalTimes) {
        setTempRentalTimes({ startTime: null, endTime: null });
      }
      if (setTempDates) {
        setTempDates([startDate, null]);
      }
      return;
    }

    if (
      startDate &&
      endDate &&
      !isRangeValid(
        getDatesInRange(
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        )
      )
    ) {
      setEquipRentedDates([startDate, null]);
      if (setTempDates) {
        setTempDates([startDate, null]);
      }
      dispatch(setalertMessage("Please select a valid date range."));
      dispatch(setalertMessagetype("error"));
      return;
    }

    if (equipRentedDates[0] && equipRentedDates[1] && startDate) {
      let startD = null;
      let endD = null;
      if (enableDateSelection === "end") {
        startD = equipRentedDates[0];
        endD = startDate;
      }
      if (enableDateSelection === "start") {
        startD = startDate;
        endD = equipRentedDates[1];
      }
      if (
        !isRangeValid(
          getDatesInRange(
            startD.format("YYYY-MM-DD"),
            endD.format("YYYY-MM-DD")
          )
        )
      ) {
        setEquipRentedDates([equipRentedDates[0], equipRentedDates[1]]);
        if (setTempDates) {
          setTempDates([equipRentedDates[0], equipRentedDates[1]]);
        }
        dispatch(setalertMessage("Please select a valid date range."));
        dispatch(setalertMessagetype("error"));
        return;
      }
    }

    if (
      endDate ||
      (startDate &&
        equipRentedDates[0] &&
        equipRentedDates[1] &&
        ((enableDateSelection === "end" &&
          startDate.toDate() >= equipRentedDates[0].toDate()) ||
          (enableDateSelection === "start" &&
            startDate.toDate() <= equipRentedDates[1].toDate())))
    ) {
      let startFormatDate = startDate?.format("YYYY-MM-DD");
      let endFormatDate = endDate?.format("YYYY-MM-DD");
      if (!endDate) {
        if (enableDateSelection === "end") {
          startFormatDate = equipRentedDates[0]?.format("YYYY-MM-DD");
          endFormatDate = startDate?.format("YYYY-MM-DD");
        } else {
          startFormatDate = startDate?.format("YYYY-MM-DD");
          endFormatDate = equipRentedDates[0]?.format("YYYY-MM-DD");
        }
      }

      if (startFormatDate !== endFormatDate) {
        const reservedDates = AllReservedDates[0];
        const startDateSlots = reservedDates?.[startFormatDate];
        const endDateSlots = reservedDates?.[endFormatDate];
        // console.log("startDateSlots",startDateSlots)
        // console.log("endDateSlots",endDateSlots)
        const alertFunc = () => {
          let sDate;
          let eDate = null;
          if (equipRentedDates[0]) {
            sDate = equipRentedDates[0];
          } else {
            sDate = startDate;
          }
          if (equipRentedDates[1]) {
            eDate = equipRentedDates[1];
          }
          setEquipRentedDates([sDate, eDate]);
          if (setTempDates) {
            setTempDates([sDate, eDate]);
          }
          // setEquipRentedTimes(prev=>({...prev,endTime:null}))
          // if(setTempRentalTimes) {
          //   setTempRentalTimes(prev=>({...prev,endTime:null}))
          // }
          dispatch(setalertMessage("Please select a valid date range."));
          dispatch(setalertMessagetype("error"));
        };

        if (endDateSlots) {
          if (endFormatDate > startFormatDate) {
            if (endDateSlots["00:00:00"]["disable"]) {
              alertFunc();
              return;
            }
          } else {
            if (endDateSlots["23:30:00"]["disable"]) {
              alertFunc();
              return;
            }
          }
        } else if (startDateSlots) {
          if (endFormatDate > startFormatDate) {
            if (startDateSlots["23:30:00"]["disable"]) {
              alertFunc();
              return;
            }
          } else {
            if (startDateSlots["00:00:00"]["disable"]) {
              alertFunc();
              return;
            }
          }
        }
      }
      // setEquipRentedTimes((prev) => ({ ...prev, endTime: null }));
    }

    setEquipRentedDates(range);
    if (setTempDates) {
      setTempDates(range);
    }
    if (
      enableDateSelection === "end" &&
      startDate &&
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      startDate.toDate() >= equipRentedDates[0].toDate()
    ) {
      setEquipRentedDates([equipRentedDates[0], startDate]);
      setEquipRentedTimes((prev) => ({ ...prev, endTime: null }));
      if (setTempRentalTimes) {
        setTempRentalTimes((prev) => ({ ...prev, endTime: null }));
      }
      if (setTempDates) {
        setTempDates([equipRentedDates[0], startDate]);
      }
      // return;
    }
    // if (
    //   enableDateSelection === "end" &&
    //   startDate &&
    //   equipRentedDates[0] &&
    //   equipRentedDates[1] &&
    //   startDate.toDate() < equipRentedDates[0].toDate()
    // ) {
    //   setEquipRentedTimes({ startTime: null, endTime: null });
    //   if (setTempRentalTimes) {
    //     setTempRentalTimes({ startTime: null, endTime: null });
    //   }
    // }

    if (
      enableDateSelection === "start" &&
      startDate &&
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      startDate.toDate() <= equipRentedDates[1].toDate()
    ) {
      setEquipRentedDates([startDate, equipRentedDates[1]]);
      setEquipRentedTimes((prev) => ({ ...prev, startTime: null }));
      if (setTempRentalTimes) {
        setTempRentalTimes((prev) => ({ ...prev, startTime: null }));
      }
      if (setTempDates) {
        setTempDates([startDate, null]);
      }
      // setEnableDateSelection("end");
      // return;
    }

    // if (
    //   enableDateSelection === "start" &&
    //   startDate &&
    //   equipRentedDates[0] &&
    //   equipRentedDates[1] &&
    //   startDate.toDate() > equipRentedDates[1].toDate()
    // ) {
    //   setEquipRentedTimes((prev) => ({ startTime: null, endTime: null }));
    //   if (setTempRentalTimes) {
    //     setTempRentalTimes((prev) => ({ startTime: null, endTime: null }));
    //   }
    // }
    if (
      startDate &&
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      ((enableDateSelection === "end" &&
        startDate.toDate() < equipRentedDates[0].toDate()) ||
        (enableDateSelection === "start" &&
          startDate.toDate() > equipRentedDates[1].toDate()))
    ) {
      setEquipRentedTimes({ startTime: null, endTime: null });

      if (setTempRentalTimes) {
        setTempRentalTimes({ startTime: null, endTime: null });
      }
    }

    if (range[0]?.format("MM/DD/YY") === moment().format("MM/DD/YY")) {
      setEquipRentedTimes((prev) => {
        if (prev?.startTime < minDateTime?.minTime) {
          const startMoment = moment(minDateTime?.minTime, "HH:mm");
          const endMoment = startMoment.clone().add(30, "minutes");
          return {
            startTime: minDateTime?.minTime,
            endTime: endMoment.format("HH:mm"),
          };
        }
        return { ...prev };
      });
    } else if (
      range[0] &&
      range[1] &&
      range[0]?.format("MM/DD/YY") === range[1]?.format("MM/DD/YY")
    ) {
      setEquipRentedTimes((prev) => {
        if (prev?.startTime > prev?.endTime) {
          const startMoment = moment(prev?.startTime, "HH:mm");
          const endMoment = startMoment.clone().add(30, "minutes");
          return { ...prev, endTime: endMoment.format("HH:mm") };
        }
        return { ...prev };
      });
    }
    // setEquipRentedDates(range);
    // if (setTempDates) {
    //   setTempDates(range);
    // }
    // }
  };

  const removeSelectionAttributes = () => {
    // // Remove 'partial-selecting-end' class from all elements with this class
    // const elementsEndToRemove = document.querySelectorAll(
    //   ".partial-selecting-end, .full-book"
    // );
    // elementsEndToRemove.forEach((element) => {
    //   element.classList.remove("partial-selecting-end full-book");
    // });

    // // Remove 'partial-selecting-start' class from all elements with this class
    // const elementsStartToRemove = document.querySelectorAll(
    //   ".partial-selecting-start"
    // );
    // elementsStartToRemove.forEach((element) => {
    //   element.classList.remove("partial-selecting-start");
    // });
    const elementsToRemove = document.querySelectorAll(
      ".partial-selecting-end, .full-book, .partial-selecting-start"
    );

    elementsToRemove.forEach((element) => {
      element.classList.remove(
        "partial-selecting-end",
        "full-book",
        "partial-selecting-start"
      );
    });
  };

  const handleMonthChange = (newMonth) => {
    // const date = calendarRef.current.date;
    // console.log("date and time",date);
    // console.log("date and time month",newMonth["month"]);
    const monthKey = "month";
    const yearKey = "year";
    calendarRef.current.set(monthKey, newMonth[monthKey]);
    calendarRef.current.set(yearKey, newMonth[yearKey]);
    removeSelectionAttributes();
    const [selectedDay, selectedMonth, selectedYear] = newMonth
      ?.format("DD/MM/YYYY")
      ?.split("/");
    if (
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      equipRentedTimes?.startTime &&
      equipRentedTimes?.endTime
    ) {
      const [choosedDay, choosedMonth, choosedYear] = equipRentedDates[0]
        ?.format("DD/MM/YYYY")
        ?.split("/");
      const [chooseEndDay, chooseEndMonth, chooseEndYear] = equipRentedDates[1]
        ?.format("DD/MM/YYYY")
        ?.split("/");
      if (
        (selectedMonth === choosedMonth || selectedMonth === chooseEndMonth) &&
        (selectedYear === choosedYear || selectedYear === chooseEndYear)
      ) {
        setTimeout(
          () => ModifyPartialSelection({ equipRentedDates, equipRentedTimes }),
          100
        );
      }
    }
    setCurrentMonth(newMonth);
  };

  const getPartialDates = ({ startDate, startTime, endDate, endTime }) => {
    if (
      startDate === endDate &&
      (startTime !== "00:00:00" || endTime !== "23:30:00")
    ) {
      return { startDate: startDate, endDate: startDate };
    } else {
      const partialDate = { startDate: null, endDate: null };
      if (startTime !== "00:00:00") {
        partialDate["startDate"] = startDate;
      }
      if (endTime !== "23:30:00") {
        partialDate["endDate"] = endDate;
      }
      return partialDate;
    }
  };

  const ModifyPartialSelection = ({ equipRentedDates, equipRentedTimes }) => {
    if (
      equipRentedDates[0] &&
      equipRentedDates[1] &&
      equipRentedTimes?.startTime &&
      equipRentedTimes?.endTime
    ) {
      const { startDate, endDate } = getPartialDates({
        startDate: equipRentedDates[0]?.format("YYYY-MM-DD"),
        startTime: equipRentedTimes?.startTime,
        endDate: equipRentedDates[1]?.format("YYYY-MM-DD"),
        endTime: equipRentedTimes?.endTime,
      });
      if (
        startDate === endDate &&
        !AllReservedDates[0]?.hasOwnProperty(startDate)
      ) {
        return;
      }

      const startElements = document.querySelectorAll(
        ".rmdp-day.rmdp-range.start"
      );
      const endElements = document.querySelectorAll(".rmdp-day.rmdp-range.end");

      // Loop through end elements to add or remove the class
      endElements.forEach((el) => {
        const elementAttribute = el
          .querySelector("span[data-status]")
          ?.getAttribute("data-status");
        // console.log("elementAttribute end", elementAttribute);
        if (elementAttribute?.includes("booked")) {
          if (!el.classList.contains("full-book")) {
            el.classList.add("full-book");
          }
        } else if (elementAttribute?.includes("pending")) {
          // continue
        } else if (endDate) {
          if (!el.classList.contains("partial-selecting-end")) {
            el.classList.add("partial-selecting-end");
          }
        } else {
          el.classList.remove("partial-selecting-end", "full-book");
        }
      });

      // Loop through start elements to add or remove the class
      startElements.forEach((el) => {
        const elementAttribute = el
          .querySelector("span[data-status]")
          ?.getAttribute("data-status");
        // console.log("elementAttribute start", elementAttribute);
        if (elementAttribute?.includes("booked")) {
          if (!el.classList.contains("full-book")) {
            el.classList.add("full-book");
          }
        } else if (elementAttribute?.includes("pending")) {
          // continue
        } else if (startDate) {
          if (!el.classList.contains("partial-selecting-start")) {
            el.classList.add("partial-selecting-start");
          }
        } else {
          el.classList.remove("partial-selecting-start", "full-book");
        }
      });
    } else {
      removeSelectionAttributes();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      ModifyPartialSelection({ equipRentedDates, equipRentedTimes });
      // const todayElements = Array.from(
      //   document.querySelectorAll(".rmdp-day.rmdp-today")
      // ).filter((element) => element.classList.length === 2);
      // todayElements.forEach((element) => {
      //   const spanChild = element.querySelector(
      //     'span[data-status="partial-booked-end"], span[data-status="partial-pending-end"], span[data-status="partial-selecting-end"]'
      //   );
      //   if (spanChild) {
      //     element.classList.remove("rmdp-today");
      //   }
      // });
      // const elements = document.querySelectorAll(
      //   ".rmdp-day.rmdp-today.rmdp-range.start, .rmdp-day.rmdp-today.rmdp-range"
      // );
      // const today = moment().format("YYYY-MM-DD");
      // if (equipRentedDates[0]?.format("YYYY-MM-DD") === today || equipRentedDates[1]?.format("YYYY-MM-DD") === today) {
      //   elements.forEach((element) => {
      //     const spanChild = element.querySelector("span");
      //     console.log("spanChild",spanChild)
      //     if (spanChild) {
      //       spanChild.classList.add("custom-today");
      //     }
      //   });
      // } else {
      //   const customTodayElements = document.querySelectorAll(".custom-today");

      //   customTodayElements.forEach((element) => {
      //     element.classList.remove("custom-today");
      //   });
      // }
    }, 100);
    // if (
    //   equipRentedDates[0] &&
    //   equipRentedTimes?.startTime &&
    //   !equipRentedDates[1] &&
    //   !equipRentedTimes?.endTime
    // ) {
    // setEnableDateSelection("end");
    // }
  }, [equipRentedDates, equipRentedTimes]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.5rem",
        width: "100%",
        maxHeight: { xs: "500px", sm: "700px", md: "750px", overflowY: "auto" },
      }}
    >
      <Box
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          maxWidth: "375px",
          // backgroundColor: "#faa61a0d",
          padding: "10px",
        }}
      >
        {label && (
          <Box
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.3rem",
                md: "1.5rem",
                lg: "1.6rem",
                xl: "1.7rem",
              },
              textAlign: "center",
              // marginBottom: 1,
              fontFamily: "GothamBold !important",
              color: "#faa61a",
              fontWeight: "600",
              mb: 1,
              width: "100%",
            }}
          >
            {label}
          </Box>
        )}

        {/* New Added Design */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #3a3a3a",
          }}
        >
          <Calendar
            ref={calendarRef}
            value={equipRentedDates}
            mapDays={({ date }) => {
              const dateConfig = getDateStyle(date);
              return {
                style: dateConfig.style,
                disabled: dateConfig.disabled,
                "data-status": dateConfig.className,
              };
            }}
            onChange={handleRangeChange}
            onMonthChange={handleMonthChange}
            range
            numberOfMonths={1}
            format="DD/MM/YYYY"
            minDate={minDateTime?.minDate}
          />
        </Box>

        <Box
          sx={{
            // display: "flex",
            alignItems: "center",
            // gap: "1.5rem",
            // flexWrap: "wrap",
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
            },
            gap: "1rem",
            mt: 0,
            mb: 0,
            p: 2,
            width: "100%",
            boxSizing: "border-box",
            backgroundColor: "#faa61a0d",
            borderRadius: 2,
          }}
        >
          {/* Available */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "20px",
                width: "20px",
                overflow: "hidden",
                backgroundColor: "#2d2d2d",
                border: "1px solid gray",
                // borderRadius: "5px",
              }}
            />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "10px",
              }}
            >
              Available
            </Typography>
          </Box>

          {/* Pending */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "20px",
                width: "20px",
                overflow: "hidden",
                backgroundColor: "skyblue",
                // borderRadius: "5px",
              }}
            />
            <Typography
              sx={{
                color: "#fff",
                // fontSize: {
                //   xs: "12px",
                //   sm: "12px",
                //   md: "13px",
                //   lg: "14px",
                //   xl: "14px",
                // },
                fontSize: "10px",
              }}
            >
              Pending
            </Typography>
          </Box>

          {/* Booked */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "20px",
                width: "20px",
                overflow: "hidden",
                backgroundColor: "#faa61a",
                // borderRadius: "5px",
              }}
            />
            <Typography
              sx={{
                color: "#fff",
                // fontSize: {
                //   xs: "12px",
                //   sm: "12px",
                //   md: "13px",
                //   lg: "14px",
                //   xl: "14px",

                // },
                fontSize: "10px",
              }}
            >
              Booked
            </Typography>
          </Box>

          {/* Partial */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Box
              sx={{
                position: "relative",
                height: "20px",
                width: "20px",
                overflow: "hidden",
                backgroundColor: "#2d2d2d",
                // borderRadius: "5px",
                "&:before": {
                  content: `''`,
                  position: "absolute",
                  right: "0",
                  top: "0",
                  width: "0",
                  height: "0",
                  borderBottom: "50px solid #faa61a",
                  borderLeft: "50px solid transparent",
                  zIndex: "1000",
                  background: "none",
                },
              }}
            />
            <Typography
              sx={{
                color: "#fff",
                // fontSize: {
                //   xs: "12px",
                //   sm: "12px",
                //   md: "13px",
                //   lg: "14px",
                //   xl: "14px",
                // },
                fontSize: "10px",
              }}
            >
              Partial
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomCalendar;
